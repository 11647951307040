const listingsByCourseTypes = {

  "corsi-executive": {
    "listingPage": "CorsiExecutive",
    "in": {
      "courseTypes": ["CorsiExecutive"],
      "filters": {}
    },
    "hero": {
      "type": "Corsi Executive",
      "title": "Individua il tema più in linea con le tue necessità di aggiornamento. Perfeziona, integra e sviluppa le tue competenze per svolgere al meglio il tuo ruolo.",
      "content": "I Corsi Executive permettono di consolidare e aggiornare <strong>competenze specifiche</strong> necessarie per <strong>crescere professionalmente</strong> nell'ambito desiderato. Si caratterizzano per formazione in aula e/o in live streaming nel weekend o in giornate infrasettimanali, con docenti qualificati ed esperti, esercitazioni pratiche con professionisti e manager di aziende, casi di studio e rilascio finale di un attestato.​"
    }
  },

  "corsi-online": {
    "listingPage": "CorsiOnline",
    "in": {
      "courseTypes": [
        "CorsiOnline", 
        "MasterOnline"
      ],
      "filters": {
        "lessionMode": [
          "On Demand"
        ]
      }
    },
    "hero": {
      "type": "Corsi Online",
      "title": "Videolezioni ed esercitazioni per studiare quando vuoi. Podcast e materiali didattici da tenere sempre con te.",
      "content": "24ORE Business School propone una vasta gamma di <strong>Corsi Online</strong>, pratici e veloci per studiare quando, dove e ovunque ci si trovi. I Corsi Online sono rivolti a Manager, Professionisti e Studenti che vogliono approfondire aree di business specifiche. Prevedono video lezioni, podcast, test di valutazione finale e il rilascio di un <strong>attestato</strong>."
    }
  },

  "executive-master": {
    "listingPage": "ExecutiveMaster",
    "in": {
      "courseTypes": ["ExecutiveMaster"],
      "filters": {}
    },
    "hero": {
      "type": "Executive Master",
      "title": "Per sviluppare e consolidare skills tecniche e competenze manageriali.",
      "content": "Gli Executive Master sono percorsi <strong>innovativi</strong> e <strong>altamente professionalizzanti</strong> che garantiscono acquisizione e aggiornamento di <strong>skills tecniche</strong> e <strong>competenze manageriali</strong> necessarie a chi ricopre posizioni apicali nelle imprese e nel mondo professionale o a chi si prepara a farlo. Progettati in collaborazione con le più importanti e note aziende e società di consulenza, si caratterizzano per l'aggiornamento continuo e una <strong>didattica interattiva</strong> arricchita da <strong>esercitazioni</strong>, <strong>case studies</strong> e <strong>giornate di life skills</strong>.​ Le docenze si svolgono in aula e/o in Live Streaming, con accesso da qualsiasi dispositivo e con la possibilità di rivedere le lezioni registrate.​"
    }
  },

  "master-full-time": {
    "listingPage": "MasterPostLaureaConStage",
    "in": {
      "courseTypes": ["MasterPostLaureaConStage"],
      "filters": {}
    },
    "hero": {
      "type": "Master Post Laurea con Stage garantito",
      "title": "Per giovani neolaureati che vogliono accelerare l’ingresso nel mondo del lavoro",
      "content": "I <strong>Master Post Laurea con Stage garantito</strong> ti permettono di <strong>accrescere le tue conoscenze</strong> e <strong>consolidare le tue basi</strong> per entrare nel mondo del lavoro. Puoi frequentare <strong>in aula</strong> oppure <strong>in live streaming</strong>, per seguire le lezioni da dove vuoi e dal device che preferisci (computer, smartphone, tablet e smart TV), mantenendo sempre un <strong>altissimo livello di interazione</strong> con docenti e colleghi, anche durante le simulazioni, i project work e le esercitazioni su casi reali. È previsto uno stage formativo presso le oltre 1.300 aziende partner. I Master, a tempo pieno, numero chiuso e frequenza obbligatoria, hanno una durata complessiva tra i 10 e i 12 mesi, di cui 6 di stage."
    }
  },

  "masterclass": {
    "listingPage": "Masterclass",
    "in": {
      "courseTypes": ["Masterclass"],
      "filters": {}
    },
    "hero": {
      "type": "Masterclass",
      "title": "Formazione smart per aziende e professionisti",
      "content": "Con le <strong>Masterclass</strong>, 24ORE Business School vuole creare un’occasione unica per aziende, liberi professionisti o per chi vuole avvicinarsi alle diverse tematiche proposte, di incontrare i migliori esperti in materia attraverso <strong>sessioni formative brevi, smart, flessibili e orientate al business.</strong> Inoltre, saranno integrate da presentazioni di casi di successo e best practices da cui trarre spunto."
    }
  },

  "professional-master": {
    "listingPage": "ProfessionalMaster",
    "in": {
      "courseTypes": ["ProfessionalMaster"],
      "filters": {}
    },
    "hero": {
      "type": "Professional Master",
      "title": "Un’occasione unica di crescita professionale. Scegli uno dei percorsi più affini alla tua carriera e arricchisci la tua formazione grazie a un network di professionisti.",
      "content": "I Professional Master sono percorsi di specializzazione in determinati ambiti disciplinari, settori professionali o funzioni aziendali.​ Sono pensati appositamente per <strong>conciliare formazione e lavoro</strong> grazie alla <strong>formula weekend</strong>, con lezioni il venerdì e il sabato, oppure in <strong>formula serale</strong>.​ Lezioni ed esercitazioni, project work e testimonianze di manager e professionisti possono essere seguiti <strong>in presenza o in live streaming</strong>, attraverso un'avanzata piattaforma di e-learning (fruibile da qualsiasi device) che permette di vivere un altissimo livello di interazione con docenti e colleghi."
    }
  },

  "master-part-time": {
    "listingPage": "MasterPartTime",
    "in": {
      "courseTypes": ["ExecutiveMaster", "ProfessionalMaster", "CorsiExecutive"],
      "filters": {}
    },
    "hero": {
      "type": "Master Executive Part Time",
      "title": "12 aree tematiche, più di 200 master e un network di eccellenza per soddisfare tutte le esigenze di reskilling e upskillig e accelerare il proprio percorso di carriera.",
      "content": "I <strong>Master Executive Part Time</strong> sono progettati per consentire a manager e professionisti di aggiornarsi. La metodologia «<strong>business oriented</strong>» consente di acquisire strumenti avanzati subito spendibili nel proprio contesto lavorativo. <strong>Le diverse formule di fruizione</strong>, part time e online, in aula o in live streaming, garantiscono la <strong>massima flessibilità e conciliabilità</strong> con i propri impegni personali e professionali."
    }
  },

  "mba-emba": {
    "listingPage": "MBA e EMBA",
    "in": {
      "courseTypes": ["MBAFullTime", "MBAPartTime", "ExecutiveMBA"],
      "filters": {}
    },
    "hero": {
      "type": "MBA e EMBA",
      "title": "Un’esperienza unica e irripetibile di trasformazione personale e professionale. Investi su te stesso e dai un boost alla tua carriera.",
      "content": "I <strong>Master in Business Administration</strong> sono <strong>percorsi innovativi</strong> e di alto profilo che garantiscono le competenze manageriali necessarie a chi ha l'ambizione di <strong>dirigere organizzazioni aziendali</strong>. Progettati in collaborazione con le aziende e società di consulenza partner si caratterizzano per l'aggiornamento continuo e l'aderenza ai cambiamenti globali. Il Network con manager e colleghi di master si integra con una didattica interattiva arricchita da Project Work, Case Studies, CEO Talks, Team Building e Company Visit. Le docenze si svolgono in aula e in Live Streaming, da qualsiasi dispositivo con la possibilità di rivedere le lezioni registrate."
    }
  },

  "master-universitari-con-cfu": {
    "listingPage": "​ProfessionalMasterCFU",
    "in": {
      "courseTypes": [
        "CorsiExecutive",
        "CorsiLaurea",
        "ExecutiveMaster",
        "ExecutiveMBA",
        "MasterPostLaureaConStage",
        "Masterclass",
        "MBAFullTime",
        "MBAPartTime",
        "ProfessionalMaster",
        "StudyTour"
      ],
      "filters": {
        "certificationReleaseEntity": [
          "UniMarconi - prodotto BS24",
          "UniMarconi - prodotto UniMarconi",
          "Unitelma"
        ]
      }
    },
    "hero": {
      "type": "Professional Master con CFU",
      "title": "Opportunità post-laurea per l'acquisizione di competenze e il consolidamento della propria carriera",
      "content": "I <strong>Professional Master Universitari</strong>, organizzati in partnership con l'<strong>Università Guglielmo Marconi</strong> e l'<strong>Università Unitelma Sapienza</strong>, sono percorsi di specializzazione, dedicati a coloro che hanno già conseguito una laurea triennale o magistrale. Costituiscono un'<strong>opportunità di crescita importante</strong> per associare a una formazione pratica e operativa il contributo scientifico universitario, con il conseguimento del Diploma universitario di 1° o 2° livello e 60 CFU. I Master universitari aiutano i neolaureati ad avvicinarsi <strong>al mondo del lavoro</strong> e a migliorare le proprie prospettive occupazionali e <strong>favoriscono i giovani professionisti</strong> che vogliono rilanciare il proprio percorso di carriera. Le formule Part Time sono disponibili in live-streaming, fruibili con la massima flessibilità nella scelta di come, dove e quando seguire le lezioni."
    }
  },

  "master-euroconference": {
    "listingPage": "CorsiEuroconference",
    "in": {
      "courseTypes": [
        "CorsiExecutive",
        "CorsiLaurea",
        "CorsiOnline",
        "ExecutiveMaster",
        "ExecutiveMBA",
        "MasterPostLaureaConStage",
        "Masterclass",
        "MBAFullTime",
        "MBAPartTime",
        "ProfessionalMaster",
        "StudyTour"
      ],
      "filters": {
        "certificationReleaseEntity": [
          "Euroconference"
        ]
      }
    },
    "hero": {
      "type": "Corsi Euroconference",
      "title": "L'accesso privilegiato all'expertise dei migliori professionisti su Lavoro, Fisco e Legal, per il tuo successo professionale",
      "content": "Il catalogo prodotti <strong>Euroconference</strong> ti offre percorsi formativi di alta qualità suddivisi in tre aree tematiche: <strong>Legale, Fiscale</strong> e <strong>Lavoro</strong>. La nostra proposta ti permette di accelerare la tua crescita professionale, <strong>acquisire competenze specializzate e rimanere sempre aggiornato sulle ultime novità</strong>, ottenendo anche crediti formativi. La possibilità di interagire direttamente con i docenti e la flessibilità dei formati di fruizione rendono i prodotti Euroconference la scelta ideale per il tuo sviluppo professionale. Siamo il punto di riferimento per la tua formazione legale, fiscale e lavoro, e ti offriamo soluzioni formative all'avanguardia per restare sempre competente nel tuo settore."
    }
  }
}

export const listingPageData = {
  ...listingsByCourseTypes
}