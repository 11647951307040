<script lang="ts">
	import { Button } from '$lib/components/atoms/Button';
	import { TextField } from '$lib/components/atoms/Field';
	import { AuthError, AuthErrorStatus, useOktaStore } from '$lib/utils/auth-store';
	import { createEventDispatcher, onMount } from 'svelte';
	import { get } from 'svelte/store';
	import { GtmStore } from '$lib/utils/gtm-store';
	import { AuthFieldsWrapper } from '$lib/components/molecules/Wrapper';
	import { useSessionStore } from '$lib/utils/session-store';
	import { AuthOptions } from '$lib/components/organisms/Auth';
	import { page } from '$app/stores';
	import { goto } from '$app/navigation';
	import { useCTAFlow } from '$lib/utils/cta-flow';
	import { browser } from '$app/environment';

	export let showSocialButtons: boolean = false;
	export let embedInCard = false;
	export let action: string | undefined = undefined;
	export let ctaLoading: boolean = false;
	export let isSigningIn = false;

	const dispatch = createEventDispatcher();
  const sessionStore = useSessionStore();
  const loginOptions = sessionStore.loginOptions;
  const ctaFlow = useCTAFlow();

	let username: string = $loginOptions?.channel === 'email' ? $loginOptions.email : '';
  let password: string = '';
  let error: string | null = null;

	const authStore = useOktaStore();

	const pushLoginGtm = () => {
		/******************** GTM EVENT PUSH *************************/
		GtmStore.pushEvent(
			{
				event: 'BBevent',
				category: 'Login',
				action: 'OK',
				label: 'email',
				event_id: '10'
			},
			false
		);
		/*************************************************************/
	};

	const completeSignIn = () => {
		const callback: string = browser ? ($page.url.searchParams.get('callback') as string) : '';
		const cta: string = browser ? ($page.url.searchParams.get('cta') as string) : '';

		if (callback) {
			goto(callback, { replaceState: true });
		} else if (cta && ctaFlow.actionInPlace) {
			ctaLoading = true;
			ctaFlow.continueCTAFlow().catch(() => (ctaLoading = false));
		} else {
			goto('/');
		}
	};

	const onSubmit = async (e: Event) => {
		e.preventDefault();
		if (!action) {
			isSigningIn = true;
			error = null;

			try {
				await authStore.signIn(username, password);
				pushLoginGtm();
				dispatch('sign-in', get(authStore.userInfo));
				completeSignIn();
			} catch (e: AuthError) {
				if (e.status === AuthErrorStatus.LOCKED_OUT) {
					error = "Il tuo account è bloccato. Contatta 'assistenza.";
				} else if (e.status === AuthErrorStatus.PASSWORD_EXPIRED) {
					error = "La tua password è scaduta. Reimpostala per continuare.";
				} else {
					error = "Email o password non validi. Riprova."
				}
			} finally {
				isSigningIn = false;
			}
		}
	};

	const onForgotPasswordClick = () => {
		dispatch('password-reset');
	};

	onMount(() => {
		/******************** GTM EVENT PUSH *************************/
		GtmStore.pushEvent({
			pageCategory: 'Login'
		});
		/*************************************************************/
	});
</script>

<form class="login-form" on:submit={onSubmit}>
	<AuthFieldsWrapper useWrapper={embedInCard}>
		<TextField
			class="col-span-full"
			name="email"
			id="email"
			type="email"
			label="Email"
			disabled={isSigningIn || ctaLoading}
			bind:value={username}
			autocomplete="username"
		/>
		<TextField
			class="col-span-full"
			name="password"
			id="password"
			type="password"
			label="Password"
			disabled={isSigningIn || ctaLoading}
			bind:value={password}
			autocomplete="current-password"
		/>

		{#if error}
			<p class="col-span-full text-red-500 text-sm mt-2">{error}</p>
		{/if}

		<a class="col-span-full" href="#/" on:click|preventDefault={onForgotPasswordClick}>
			Password dimenticata?
		</a>

		<Button
			class="col-span-full {!embedInCard && 'mt-4'}"
			type="submit"
			isLoading={isSigningIn || ctaLoading}
			disabled={isSigningIn || ctaLoading}
			fullWidth
		>
			Accedi
		</Button>
	</AuthFieldsWrapper>

	{#if showSocialButtons}
		<div class="mt-8">
			<AuthOptions
				bind:isRegistering={isSigningIn}
				on:social-sign-in
				useDecoratedTitle={false}
				noRedirect
				options={{
					socialSection: 'Altrimenti'
				}}
			/>
		</div>
	{/if}
</form>

<style type="text/css" lang="scss" scoped>
	.login-form {
		@apply pb-4;
	}
</style>
