import { derived, type Readable, writable, type Writable } from 'svelte/store';
import type { Cookies, CookiesStore } from './types';
import { browser } from '$app/environment';
import { PUBLIC_ALTERNATE_STATIC_ASSETS_TRAFFIC_PERCENT, PUBLIC_ALTERNATE_STATIC_ASSETS_BASE_URL } from '$env/static/public';

const isBrowser = () => browser;

function weightedRandomItem<Item>(options: { item: Item; weight: number }[]) {
  let i;
  var weights = [];
  for (i = 0; i < options.length; i++) weights[i] = options[i].weight + (weights[i - 1] || 0);
  var random = Math.random() * weights[weights.length - 1];
  for (i = 0; i < weights.length; i++) if (weights[i] > random) break;
  return options[i].item;
}

export class CookiesStoreImpl implements CookiesStore {
  private static _instance: CookiesStoreImpl;
  private _cookies: Writable<Cookies>;

  get cookies(): Readable<Cookies> {
    return derived(this._cookies, (value) => value);
  }

  get sessionAB(): Readable<string> {
    return derived(this._cookies, (value) => value.cookie_ab_session);
  }

  get companyAB(): Readable<string> {
    return derived(this._cookies, (value) => value.cookie_ab_company);
  }

  constructor() {
    this._cookies = writable({
      cookie_notice_accepted: false,
      cookie_ab_monitor: null,
      cookie_ab_session: 'A',
      cookie_ab_company: 'A',
      cookie_static_assets: null,
      cookie_payment_gateway: null,
    });

    if (isBrowser()) {
      let cookie_ab_monitor = this.getCookie('ab_monitor');
      let cookie_static_assets = this.getCookie('static_assets');
      let cookie_notice_accepted = this.getCookie('cookie_notice_accepted');
      let cookie_credit_filter = this.getCookie('credit_filter');
      let cookie_payment_gateway = this.getCookie('payment_gateway');

      // Use local storage for this value
      // TODO: Change place for handling this
      const params = new URLSearchParams(window.location.search);

      // let forcedAbSession: null | string = null;
      let forcedAbSession: null | string = 'A';
      if (params.has('ab_session')) {
        forcedAbSession = params.get('ab_session');
      }

      let forcedAbCompany: null | string = null;
      if (params.has('ab_company')) {
        forcedAbCompany = params.get('ab_company');
      }
      let ab_company = this.initializeCompanyAB(forcedAbCompany);
      this.setLocalStorageItem('ab_company', ab_company);

      let ab_session = this.initializeSessionAB(forcedAbSession);
      this.setLocalStorageItem('ab_session', ab_session);

      let forcedAbCtaOrientamento: null | string = null;
      if (params.has('ab_cta_orientamento')) {
        forcedAbCtaOrientamento = params.get('ab_cta_orientamento');
      }
      let ab_cta_orientamento = this.initializeCtaOrientamentoAB(forcedAbCtaOrientamento);
      this.setLocalStorageItem('ab_cta_orientamento', ab_cta_orientamento);

      /*FORCE USE STRIPE*/
      if (params.has('payment_gateway')) {
        cookie_payment_gateway = params.get('payment_gateway') || null;
      }
      /*FORCE USE STRIPE*/

      if (!cookie_ab_monitor) {
        cookie_ab_monitor = 'A'; //randomItem(['A', 'B']);
      }

      if (params.has('credit_filter')) {
        cookie_credit_filter = params.get('credit_filter');
      } else {
        cookie_credit_filter = 'A';
      }

      const forceAlternateAssets = params.get('forcealternateasset');
      if (forceAlternateAssets === 'true' || forceAlternateAssets === '') {
        cookie_static_assets = 'alternate';
      } else if (!cookie_static_assets) {
        const trafficPercent = PUBLIC_ALTERNATE_STATIC_ASSETS_TRAFFIC_PERCENT;
        const alternateStaticAssets = PUBLIC_ALTERNATE_STATIC_ASSETS_BASE_URL;

        if (trafficPercent && alternateStaticAssets) {
          const originalAssetsWeight = 100 - parseInt(trafficPercent);
          const alternateAssetsWeight = parseInt(trafficPercent);
          cookie_static_assets = weightedRandomItem([
            { item: 'original', weight: originalAssetsWeight },
            { item: 'alternate', weight: alternateAssetsWeight },
          ]);
        } else {
          cookie_static_assets = 'notset';
        }
      }

      this._cookies.set({
        cookie_notice_accepted: cookie_notice_accepted === 'true',
        cookie_ab_monitor: cookie_ab_monitor,
        cookie_ab_session: ab_session,
        cookie_ab_company: ab_company,
        cookie_static_assets: cookie_static_assets,
        cookie_credit_filter: cookie_credit_filter,
        cookie_payment_gateway: cookie_payment_gateway
      });

      this._cookies.subscribe((currentCookies) => {
        const cookie_notice_accepted = currentCookies.cookie_notice_accepted;
        const cookie_ab_monitor = currentCookies.cookie_ab_monitor;
        const cookie_static_assets = currentCookies.cookie_static_assets;
        const cookie_ab_session = currentCookies.cookie_ab_session;
        const cookie_ab_company = currentCookies.cookie_ab_company;
        const cookie_credit_filter = currentCookies.cookie_credit_filter;
        const cookie_payment_gateway = currentCookies.cookie_payment_gateway;

        this.setCookie('cookie_notice_accepted', cookie_notice_accepted, 30);
        this.setCookie('ab_session', cookie_ab_session, 30);
        this.setCookie('ab_company', cookie_ab_company, 30);
        this.setCookie('ab_monitor', cookie_ab_monitor, 30);
        this.setCookie('static_assets', cookie_static_assets, 1);
        this.setCookie('credit_filter', cookie_credit_filter, 10);
        this.setCookie('payment_gateway', cookie_payment_gateway, 30);
      });
    }
  }

  setCookies(cookies: Cookies) {
    this._cookies.update((currentCookies) => {
      return {
        ...currentCookies,
        ...cookies,
      };
    });
  }

  private setCookie<T>(key: string, value: T, daysToExpire: number) {
    const d = new Date();
    d.setTime(d.getTime() + daysToExpire * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = key + '=' + value.toString() + ';' + expires + ';path=/';
  }

  getCookie(cname) {
    const name = cname + '=';
    try {
      const decodedCookie = decodeURIComponent(document.cookie);
      const ca = decodedCookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return '';
    } catch (e) {
      return '';
    }
  }

  private setLocalStorageItem(key, item) {
    localStorage.setItem(key, item);
  }

  private getLocalStorageItem(key) {
    return localStorage.getItem(key);
  }

  private initializeSessionAB(forceABSession: null | string = null) {
    const ab_session = this.getLocalStorageItem('ab_session');

    if (forceABSession !== null && (forceABSession === 'A' || forceABSession === 'B')) {
      return forceABSession;
    }

    if (ab_session) {
      return ab_session;
    }

    return weightedRandomItem([
      { item: 'A', weight: 100 },
      { item: 'B', weight: 0 },
    ]);
  }

  private initializeCompanyAB(forceABCompany: null | string = null) {
    const ab_company = this.getLocalStorageItem('ab_company');

    if (forceABCompany !== null && (forceABCompany === 'A' || forceABCompany === 'B')) {
      return forceABCompany;
    }

    if (ab_company) {
      return ab_company;
    }

    return weightedRandomItem([
      { item: 'A', weight: 80 },
      { item: 'B', weight: 20 },
    ]);
  }

  private initializeCtaOrientamentoAB(forcedAbCtaOrientamento: null | string = null) {
    //if forced in url as param, use it
    if (forcedAbCtaOrientamento !== null && (forcedAbCtaOrientamento === 'A' || forcedAbCtaOrientamento === 'B' || forcedAbCtaOrientamento === 'C' || forcedAbCtaOrientamento === 'D')) {
      return forcedAbCtaOrientamento;
    }

    //if the value is in the localsotrage, use it
    const ab_cta_orientamento = this.getLocalStorageItem('ab_cta_orientamento');
    if (ab_cta_orientamento !== null) {
      return ab_cta_orientamento;
    }

    //if user has already visited the site
    if (this.getLocalStorageItem('HISTORY_CAMPAIGN_DATA') !== null) {
      //set random value for old user (C: invisible / D: visible)
      /*return weightedRandomItem([
        { item: 'C', weight: 50 },
        { item: 'D', weight: 50 },
      ]);*/
      return 'D';
    }

    //if user has not visited the site, set random value for new user (A: invisible / B: visible)
    /*return weightedRandomItem([
      { item: 'A', weight: 50 },
      { item: 'B', weight: 50 },
    ]);*/
    return 'B';
  }

  public static getInstance() {
    if (!CookiesStoreImpl._instance) {
      CookiesStoreImpl._instance = new CookiesStoreImpl();
    }
    return CookiesStoreImpl._instance;
  }
}
