<script lang="ts">
  import { onMount } from 'svelte';
  import { page } from '$app/stores';
  // Utils
  import { stringToSlug } from '@24hbs/utils';
  import { isMobileBrowser } from '$lib/utils/browser-utils';
  import { ContactUtils } from '$lib/utils/contact-utils';
  import { GtmEventCategory, GtmStore, MenuGtmEventId } from '$lib/utils/gtm-store';
  import { openModal } from "$lib/utils/modals";
  // Components
  import { Icon } from '$lib/components/atoms/Icon'
  import { PromoLabel } from '$lib/components/atoms/Countdown'
  import { NavbarLogo } from '$lib/components/atoms/NavbarLogo';
  import { Navbar, NavbarMobile, SideNavbar, SideNavbarMobile } from '$lib/components/organisms/Layout'
  import { 
    MegaMenu, MegaMenuLinks, MegaMenuLinksMobile, MegaMenuMobile, MegaMenuPanel, MegaMenuPanelGroup,
    NavbarAccount, NavbarAccountMobile, NavbarSearch, NavbarToggle, NavigationBackdrop
  } from '$lib/components/organisms/Navigation'
  import { CallBookingModal } from '$lib/components/organisms/Modal/';
  import { SearchForm } from '$lib/components/organisms/Form';
	import { 
    SearchAutocompletion, 
    SearchQuickLinksFrequent, 
    SearchQuickLinksHistory, 
    SearchQuickLinksPopular 
  } from '$lib/components/molecules/Search';

  export let segment: string | undefined;
  export let fixed = true;

  let openMegaMenu: 'programmi' | 'percorsi' | 'tematiche' | 'per le imprese' | 'cerca' | null = null;
  let sideNavbarVisible = false;
  let mobileNavbarVisible = false;
  let hasNavOpen = false;

  onMount(() => {
    closeAllPanels();
    checkNavigationOpened();
  });

  const checkNavigationOpened = () => {
    if (typeof window !== 'undefined') {
      if (hasNavOpen) {
        document?.body.classList.add('is-navigation-open');
        //console.log('Adding navigation open');
        // hasNavOpen = true;
      } else {
        document?.body.classList.remove('is-navigation-open');
        //console.log('Adding navigation open');
        // hasNavOpen = false;
      }
    }
  };

  const onSideNavbarToggle = () => {
    openMegaMenu = null;
    mobileNavbarVisible = false;
    sideNavbarVisible = !sideNavbarVisible;
  };

  const onSideNavbarClose = () => {
    openMegaMenu = null;
    mobileNavbarVisible = false;
    sideNavbarVisible = false;
    closeAllPanels();
  };

  const onMobileNavigationToggle = () => {
    openMegaMenu = null;
    sideNavbarVisible = false;
    mobileNavbarVisible = !mobileNavbarVisible;
  };

  const closeAllPanels = () => {
    openMegaMenu = null;
    sideNavbarVisible = false;
    mobileNavbarVisible = false;
    hasNavOpen = false;
  };

  const triggerClickOnMenuItem = (eventId: MenuGtmEventId, firstCategory, secondCategory, destinationUrl) => {
    closeAllPanels();
    GtmStore.pushEvent(
      {
        event: 'BBevent',
        event_id: eventId.toString(),
        category: GtmEventCategory.MENU_BAR,
        action: firstCategory,
        label: secondCategory,
        destination_url: destinationUrl,
      },
      false
    );
  };

  const onMegaMenuClose = () => {
    openMegaMenu = null;
  };

  const onToggleMegaMenu = (mega: typeof openMegaMenu) => {
    openMegaMenu = mega === openMegaMenu ? null : mega;
    return false;
  };

  const handleContactsClick = () => {
    if (isMobileBrowser() && ContactUtils.canDirectCall()) {
      window.open('tel:02-37929700');
    } else {
      openModal(CallBookingModal)
    }
  };


  $: if ($page.url.pathname) {
    closeAllPanels();
  }

  $: {
    hasNavOpen =
      (openMegaMenu !== null && typeof openMegaMenu !== undefined) || sideNavbarVisible || mobileNavbarVisible;
    checkNavigationOpened();
  }

  hasNavOpen = (openMegaMenu !== null && typeof openMegaMenu !== undefined) || sideNavbarVisible || mobileNavbarVisible;
  checkNavigationOpened();
</script>


<!-- Set fluid to 'true' to extend it full width -->
<Navbar {fixed} fluid={false}>
  <NavbarLogo on:click={() => (openMegaMenu = null)} />

  <SideNavbar visible={sideNavbarVisible} on:togglevisibility={onSideNavbarClose} />

  <svelte:fragment slot="topnavbar">
    <div class="w-full hidden lg:flex justify-end gap-x-6">
      <a
        on:click={() =>
          triggerClickOnMenuItem(
            MenuGtmEventId.TOP_MENU_CHI_SIAMO,
            'Top Menu',
            'Chi siamo',
            '/about'
          )}
        href="/about"
      >
        Chi siamo
      </a>
      <a
        class="flex items-center"
        href="/contact-us"
        on:click|preventDefault={() => handleContactsClick()}
      >
        <Icon icon="phone" style="margin-right: 2px;" />
        <span style="overflow: hidden; white-space:nowrap"> 02-37929700</span>
      </a>
      <a
        on:click={() =>
          triggerClickOnMenuItem(
            MenuGtmEventId.TOP_MENU_CONTATTACI,
            'Top Menu',
            'Contattaci',
            '/contact-us'
          )}
        href="/contact-us"
      >
        Contattaci
      </a>
      <span>Sedi: 
      <a
        on:click={() =>
          triggerClickOnMenuItem(
            MenuGtmEventId.TOP_MENU_SEDI_MILANO,
            'Top Menu',
            'Sedi - Milano',
            '/sedi#milano'
          )}
        href="/sedi#milano"
      >
        Milano
      </a>
      |
      <a
        on:click={() =>
          triggerClickOnMenuItem(
            MenuGtmEventId.TOP_MENU_SEDI_ROMA,
            'Top Menu',
            'Sedi - Roma',
            '/sedi#roma'
          )}
        href="/sedi#roma"
      >
        Roma
      </a>
    </div>
  </svelte:fragment>

  <svelte:fragment slot="main">
    <MegaMenuLinks>
      <a
        href="#/"
        on:click|preventDefault={() => onToggleMegaMenu('programmi')}
        aria-expanded={openMegaMenu === 'programmi' ? true : undefined}
        aria-haspopup="true"
      >
        Programmi
      </a>
      <a
        href="#/"
        on:click|preventDefault={() => onToggleMegaMenu('percorsi')}
        aria-expanded={openMegaMenu === 'percorsi' ? true : undefined}
        aria-haspopup="true"
      >
        Percorsi
      </a>
      <a
        href="#/"
        on:click|preventDefault={() => onToggleMegaMenu('tematiche')}
        aria-expanded={openMegaMenu === 'tematiche' ? true : undefined}
        aria-haspopup="true"
      >
        Tematiche
      </a>
      <a
        href="#/"
        on:click|preventDefault={() => onToggleMegaMenu('per le imprese')}
        aria-expanded={openMegaMenu === 'per le imprese' ? true : undefined}
        aria-haspopup="true"
      >
        Per le imprese
      </a>
      <a
        on:click={() =>
          triggerClickOnMenuItem(
            MenuGtmEventId.INIZIATIVE,
            'Iniziative',
            '',
            '/eventi'
          )}
        href="/eventi"
        target="_self"
      >
        Iniziative
      </a>
    </MegaMenuLinks>
    {#if !mobileNavbarVisible}
      <NavbarSearch on:click={() => onToggleMegaMenu('cerca')} current={segment === 'search'} />
    {/if}
    <NavbarAccount />
    <NavbarToggle displayOn="mobile" isToggled={mobileNavbarVisible} on:click={onMobileNavigationToggle} />
    <NavbarToggle displayOn="desktop" on:click={onSideNavbarToggle} />
  </svelte:fragment>

  <svelte:fragment slot="drawers">
    <NavbarMobile visible={mobileNavbarVisible} on:close={onMobileNavigationToggle}>
      <!-- ProfilerCardMobile / -->
      <MegaMenuLinksMobile>
        <a
          href="#/"
          on:click|preventDefault={() => onToggleMegaMenu('programmi')}
          aria-expanded={openMegaMenu === 'programmi' ? true : undefined}
          aria-haspopup="true"
        >
          Programmi
        </a>
        <a
          href="#/"
          on:click|preventDefault={() => onToggleMegaMenu('percorsi')}
          aria-expanded={openMegaMenu === 'percorsi' ? true : undefined}
          aria-haspopup="true"
        >
          Percorsi
        </a>
        <a
          href="#/"
          on:click|preventDefault={() => onToggleMegaMenu('tematiche')}
          aria-expanded={openMegaMenu === 'tematiche' ? true : undefined}
          aria-haspopup="true"
        >
          Tematiche
        </a>
        <a
          href="#/"
          on:click|preventDefault={() => onToggleMegaMenu('per le imprese')}
          aria-expanded={openMegaMenu === 'per le imprese' ? true : undefined}
          aria-haspopup="true"
        >
          Per le imprese
        </a>
        <a
          on:click={() =>
            triggerClickOnMenuItem(
              MenuGtmEventId.INIZIATIVE,
              'Iniziative',
              '',
              '/eventi'
            )}
          href="/eventi"
          target="_self"
        >
          Iniziative
        </a>
        <a
          href="/contact-us"
          style="display: flex; align-items: center"
          on:click|preventDefault={() => handleContactsClick()}
        >
          <Icon icon="phone" style="margin-right: 5px;" />
          <span style="overflow: hidden; white-space:nowrap"> 02-37929700</span>
        </a>
      </MegaMenuLinksMobile>

      <SideNavbarMobile />
      <NavbarAccountMobile />

      <svelte:fragment slot="drawers">
        <MegaMenuMobile visible={openMegaMenu === 'programmi'} on:back={onMegaMenuClose}>
          <MegaMenuPanel title="" colsCount="four">
            <MegaMenuPanelGroup title="Programmi Full Time">
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_MASTER_FULL_TIME_CON_STAGE,
                    'Programmi',
                    'Programmi Full Time - Master Post Laurea con Stage garantito',
                    '/master-full-time'
                  )}
                href="/master-full-time"
                target="_self"
                data-sveltekit-reload
              >
                Master Post Laurea con Stage garantito
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PERCORSI_MBA_FULL_TIME,
                    'Percorsi',
                    'Neo Laureati - MBA Full Time ',
                    '/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita'
                  )}
                href="/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita"
                target="_self"
                data-sveltekit-reload
              >
                MBA Full Time 
              </a>
            </MegaMenuPanelGroup>
            <MegaMenuPanelGroup title="Executive Part Time ">
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_EXECUTIVE_MASTER,
                    'Programmi',
                    'Executive Part Time  - Executive Master',
                    '/executive-master'
                  )}
                href="/executive-master"
                target="_self"
                data-sveltekit-reload
              >
                Executive Master
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_MBA_PART_TIME,
                    'Programmi',
                    'Executive Part Time  - MBA Part Time',
                    '/formazione/mba-part-time/mba-part-time'
                  )}
                href="/formazione/mba-part-time/mba-part-time"
                target="_self"
                data-sveltekit-reload
              >
                MBA Part Time
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_PROFESSIONAL_MASTER,
                    'Programmi',
                    'Executive Part Time  - Professional Master',
                    '/professional-master'
                  )}
                href="/professional-master"
                target="_self"
                data-sveltekit-reload
              >
                Professional Master
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_CORSI_EXECUTIVE,
                    'Programmi',
                    'Executive Part Time  - Corsi Executive',
                    '/corsi-executive'
                  )}
                href="/corsi-executive"
                target="_self"
                data-sveltekit-reload
              >
                Corsi Executive
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_MASTER_PART_TIME,
                    'Programmi',
                    'Executive Part Time  - Master Part Time',
                    '/master-part-time'
                  )}
                href="/master-part-time"
                target="_self"
                data-sveltekit-reload
              >
                Vedi tutti i Master Part Time
              </a>
            </MegaMenuPanelGroup>
            <MegaMenuPanelGroup title="MBA & Executive MBA">
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_EXECUTIVE_MBA,
                    'Programmi',
                    'MBA & Executive MBA - Executive MBA',
                    '/mba'
                  )}
                href="/mba"
                target="_self"
                data-sveltekit-reload
              >
                Executive MBA
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_MBA_FULL_TIME,
                    'Programmi',
                    'MBA & Executive MBA - MBA Full Time',
                    '/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita'
                  )}
                href="/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita"
                target="_self"
                data-sveltekit-reload
              >
                MBA Full Time
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_MBA_PART_TIME,
                    'Programmi',
                    'MBA & Executive MBA - MBA Part Time',
                    '/formazione/mba-part-time/mba-part-time'
                  )}
                href="/formazione/mba-part-time/mba-part-time"
                target="_self"
                data-sveltekit-reload
              >
                MBA Part Time
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_MBA_EMBA,
                    'Programmi',
                    'MBA & Executive MBA - MBA EMBA',
                    '/mba-emba'
                  )}
                href="/mba-emba"
                target="_self"
                data-sveltekit-reload
              >
                Vedi tutti gli MBA
              </a>
            </MegaMenuPanelGroup>
            <MegaMenuPanelGroup title="Altri Corsi">
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_CORSI_ON_DEMAND,
                    'Programmi',
                    'Altri Corsi - Corsi Online',
                    '/corsi-online'
                  )}
                href="/corsi-online"
                target="_self"
                data-sveltekit-reload
              > 
                Corsi Online
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_MASTER_EUROCONFERENCE,
                    'Programmi',
                    'Altri Corsi - Corsi Euroconference',
                    '/master-euroconference'
                  )}
                href="/master-euroconference"
                target="_self"
                data-sveltekit-reload
              >   
                Corsi Euroconference         
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROGRAMMI_MASTERCLASS,
                    'Programmi',
                    'Altri Corsi - Masterclass',
                    '/masterclass'
                  )}
                href="/masterclass"
                target="_self"
                data-sveltekit-reload
              >
                Masterclass
              </a>
            </MegaMenuPanelGroup>
          </MegaMenuPanel>

          <NavbarAccountMobile slot="footer" />
        </MegaMenuMobile>
        <MegaMenuMobile visible={openMegaMenu === 'percorsi'} on:back={onMegaMenuClose}>
          <MegaMenuPanel title="Scegli per tipologia" colsCount="three">
            <MegaMenuPanelGroup title="Professionisti e Executive ">
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PERCORSI_EXECUTIVE_MASTER,
                    'Percorsi',
                    'Professionisti e Executive  - Executive Master ',
                    '/executive-master'
                  )}
                href="/executive-master"
                target="_self"
                data-sveltekit-reload
              >
                Executive Master 
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PERCORSI_EXECUTIVE_MBA,
                    'Percorsi',
                    'Professionisti e Executive  - Executive MBA ',
                    '/mba'
                  )}
                href="/mba"
                target="_self"
                data-sveltekit-reload
              >
                Executive MBA 
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PERCORSI_MBA_PART_TIME,
                    'Percorsi',
                    'Professionisti e Executive  - MBA Part Time ',
                    '/formazione/mba-part-time/mba-part-time'
                  )}
                href="/formazione/mba-part-time/mba-part-time"
                target="_self"
                data-sveltekit-reload
              >
                MBA Part Time 
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PERCORSI_CORSI_EXECUTIVE,
                    'Percorsi',
                    'Professionisti e Executive  - Corsi Executive',
                    '/corsi-executive'
                  )}
                href="/corsi-executive"
                target="_self"
                data-sveltekit-reload
              >
                Corsi Executive
              </a>
            </MegaMenuPanelGroup>
            <MegaMenuPanelGroup title="Neo Laureati">
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PERCORSI_MASTER_POST_LAUREA_CON_STAGE,
                    'Percorsi',
                    'Neo Laureati - Master Post Laurea con Stage garantito ',
                    '/master-full-time'
                  )}
                href="/master-full-time"
                target="_self"
                data-sveltekit-reload
              >
                Master Post Laurea con Stage  garantito
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PERCORSI_MBA_FULL_TIME,
                    'Percorsi',
                    'Neo Laureati - MBA Full Time ',
                    '/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita'
                  )}
                href="/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita"
                target="_self"
                data-sveltekit-reload
              >
                MBA Full Time 
              </a>
            </MegaMenuPanelGroup>
            <MegaMenuPanelGroup title="Open Programme">
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PERCORSI_PROFESSIONAL_MASTER,
                    'Percorsi',
                    'Open Programme - Professional Master ',
                    '/professional-master'
                  )}
                href="/professional-master"
                target="_self"
                data-sveltekit-reload
              >
                Professional Master 
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PERCORSI_CORSI_ONLINE,
                    'Percorsi',
                    'Open Programme - Corsi Online ',
                    '/corsi-online'
                  )}
                href="/corsi-online"
                target="_self"
                data-sveltekit-reload
              >
                Corsi Online 
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PERCORSI_CORSI_EUROCONFERENCE,
                    'Percorsi',
                    'Open Programme - Corsi Euroconference ',
                    '/master-euroconference'
                  )}
                href="/master-euroconference"
                target="_self"
                data-sveltekit-reload
              >
                Corsi Euroconference 
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PERCORSI_MASTERCLASS,
                    'Percorsi',
                    'Open Programme - Masterclass',
                    '/masterclass'
                  )}
                href="/masterclass"
                target="_self"
                data-sveltekit-reload
              >
                Masterclass
              </a>
            </MegaMenuPanelGroup>
          </MegaMenuPanel>

          <NavbarAccountMobile slot="footer" />
        </MegaMenuMobile>

        <MegaMenuMobile visible={openMegaMenu === 'tematiche'} on:back={onMegaMenuClose}>
          <MegaMenuPanel title="Scegli per interesse" colsCount="three">
            <MegaMenuPanelGroup title="Le più cercate">
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.HR_LAVORO_E_SVILUPPO_MANAGERIALE,
                    'Tematiche',
                    'HR, DE&I e Diritto del Lavoro',
                    '/hr-lavoro-e-sviluppo-manageriale'
                  )}
                href="/hr-lavoro-e-sviluppo-manageriale"
                target="_self"
                data-sveltekit-reload
              >
                HR, DE&I e Diritto del Lavoro
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.MARKETING_COMUNICAZIONE_DIGITAL_E_SOCIAL_MEDIA,
                    'Tematiche',
                    'Marketing e Comunicazione',
                    '/marketing-comunicazione-digital-e-social-media'
                  )}
                href="/marketing-comunicazione-digital-e-social-media"
                target="_self"
                data-sveltekit-reload
              >
                Marketing e Comunicazione
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.AMMINISTRAZIONE_FINANZA_E_CONTROLLO,
                    'Tematiche',
                    'Amministrazione, Finanza e Controllo',
                    '/amministrazione-finanza-e-controllo'
                  )}
                href="/amministrazione-finanza-e-controllo"
                target="_self"
                data-sveltekit-reload
              >
                Amministrazione, Finanza e Controllo
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.DIRITTO_E_FISCO,
                    'Tematiche',
                    'Diritto e Fisco',
                    '/diritto-e-fisco'
                  )}
                href="/diritto-e-fisco"
                target="_self"
                data-sveltekit-reload
              >
                Diritto e Fisco
              </a>
            </MegaMenuPanelGroup>
            <MegaMenuPanelGroup title="Le più attuali">
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.ESG_SOSTENIBILITA_ENERGIA_E_AMBIENTE,
                    'Tematiche',
                    'ESG, Sostenibilità, Energia e Ambiente',
                    '/ambiente-energia'
                  )}
                href="/ambiente-energia"
                target="_self"
                data-sveltekit-reload
              >
                ESG, Sostenibilità, Energia e Ambiente
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.DIGITAL_TRANSFORMATION_TECNOLOGIA_E_INTELLIGENZA_ARTIFICIALE,
                    'Tematiche',
                    'Digital Transformation, Tecnologia e Intelligenza Artificiale',
                    '/digital-transformation'
                  )}
                href="/digital-transformation"
                target="_self"
                data-sveltekit-reload
              >
                Digital Transformation, Tecnologia e Intelligenza Artificiale
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.SOFT_SKILLS,
                    'Tematiche',
                    'Soft Skills ',
                    '/soft-skills'
                  )}
                href="/soft-skills"
                target="_self"
                data-sveltekit-reload
              >
                Soft Skills
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PROJECT_MANAGEMENT,
                    'Tematiche',
                    'Project Management',
                    '/project-management'
                  )}
                href="/project-management"
                target="_self"
                data-sveltekit-reload
              >
                Project Management
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.GESTIONE_DI_IMPRESA_EXPORT_E_INTERNAZIONALIZZAZIONE,
                    'Tematiche',
                    'General Management e Gestione di Impresa',
                    '/gestione-di-impresa-export-e-internazionalizzazione'
                  )}
                href="/gestione-di-impresa-export-e-internazionalizzazione"
                target="_self"
                data-sveltekit-reload
              >
                General Management e Gestione di Impresa
              </a>
            </MegaMenuPanelGroup>
            <MegaMenuPanelGroup title="Industries">
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.VENDITE_E_RETAIL,
                    'Tematiche',
                    'Retail, Sales ed Export',
                    '/vendite-e-retail'
                  )}
                href="/vendite-e-retail"
                target="_self"
                data-sveltekit-reload
              >
                Retail, Sales ed Export
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.SANITA_PHARMA_E_BIOMED,
                    'Tematiche',
                    'Sanità e Pharma',
                    '/sanita-pharma-e-biomed'
                  )}
                href="/sanita-pharma-e-biomed"
                target="_self"
                data-sveltekit-reload
              >
                Sanità e Pharma
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.LUSSO_E_MODA,
                    'Tematiche',
                    'Fashion e Luxury',
                    '/lusso-e-moda'
                  )}
                href="/lusso-e-moda"
                target="_self"
                data-sveltekit-reload
              >
                Fashion e Luxury
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.FOOD_BEVERAGE_E_TURISMO,
                    'Tematiche',
                    'Food, Beverage e Turismo',
                    '/food-beverage-e-turismo'
                  )}
                href="/food-beverage-e-turismo"
                target="_self"
                data-sveltekit-reload
              >
                Food, Beverage e Turismo
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.PUBBLICA_AMMINISTRAZIONE,
                    'Tematiche',
                    'Pubblica Amministrazione',
                    '/pubblica-amministrazione'
                  )}
                href="/pubblica-amministrazione"
                target="_self"
                data-sveltekit-reload
              >
                Pubblica Amministrazione
              </a>
              <a
                on:click={() =>
                  triggerClickOnMenuItem(
                    MenuGtmEventId.ARTE_CULTURA_E_SPORT,
                    'Tematiche',
                    'Arte, Cultura, Sport',
                    '/arte-cultura-sport'
                  )}
                href="/arte-cultura-sport"
                target="_self"
                data-sveltekit-reload
              >
                Arte, Cultura, Sport
              </a>
            </MegaMenuPanelGroup>
          </MegaMenuPanel>

          <NavbarAccountMobile slot="footer" />
        </MegaMenuMobile>

        <MegaMenuMobile visible={openMegaMenu === 'per le imprese'} on:back={onMegaMenuClose}>
          <MegaMenuPanel title="Entra nel mondo B2B" colsCount="three">
            <MegaMenuPanelGroup title="" linkStyle="heading">
              <a
                on:click={() => triggerClickOnMenuItem(MenuGtmEventId.PER_LE_IMPRESE, 'B2B', 'Servizi per le imprese', '/b2b')}
                href="/b2b"
                target="_self"
                data-sveltekit-reload
              >
                Servizi per le imprese
              </a>
              <ul>
                <a
                  on:click={() => triggerClickOnMenuItem(MenuGtmEventId.JOB_FINDING, 'B2B', 'Job finding', '/b2b/job-finding')}
                  href="/b2b/job-finding"
                  target="_self"
                  data-sveltekit-reload
                >
                  <li class="ml-4">Job Finding</li>
                </a>
                <a
                  on:click={() => triggerClickOnMenuItem(MenuGtmEventId.SKILLING, 'B2B', 'Skilling', '/b2b/skilling')}
                  href="/b2b/skilling"
                  target="_self"
                  data-sveltekit-reload
                >
                  <li class="ml-4">Skilling</li>
                </a>
                <a
                  on:click={() => triggerClickOnMenuItem(MenuGtmEventId.BRANDING, 'B2B', 'Branding', '/b2b/branding')}
                  href="/b2b/branding"
                  target="_self"
                  data-sveltekit-reload
                >
                  <li class="ml-4">Branding</li>
                </a>

                <a
                  on:click={() => triggerClickOnMenuItem(MenuGtmEventId.BRANDING, 'B2B', 'Finance', '/b2b/finance')}
                  href="/b2b/finance"
                  target="_self"
                  data-sveltekit-reload
                >
                  <li class="ml-4">Finance</li>
                </a>
              </ul>
            </MegaMenuPanelGroup>
            <MegaMenuPanelGroup title="" linkStyle="heading">
              <p>
                Offerta
              </p>
              <ul>
                <a
                  on:click={() => triggerClickOnMenuItem(MenuGtmEventId.B2B_OFFERTA, 'B2B', 'offerB2b', '/b2b/offerta/b2b-offerta-formativa-a-catalogo-per-le-aziende')}
                  href="/b2b/offerta/b2b-offerta-formativa-a-catalogo-per-le-aziende"
                  target="_self"
                  data-sveltekit-reload
                >
                  <li class="ml-4">Formazione Interaziendale a catalogo</li>
                </a>
                <a
                  on:click={() => triggerClickOnMenuItem(MenuGtmEventId.B2B_OFFERTA, 'B2B', 'offerB2b', '/b2b/offerta/b2b-formazione-su-misura')}
                  href="/b2b/offerta/b2b-formazione-su-misura"
                  target="_self"
                  data-sveltekit-reload
                >
                  <li class="ml-4">Formazione su Misura</li>
                </a>
                <a
                  on:click={() => triggerClickOnMenuItem(MenuGtmEventId.B2B_OFFERTA, 'B2B', 'offerB2b', '/b2b/offerta/b2b-hr-for-breakfast')}
                  href="/b2b/offerta/b2b-hr-for-breakfast"
                  target="_self"
                  data-sveltekit-reload
                >
                  <li class="ml-4">HR for Breakfast</li>
                </a>
                <a
                  on:click={() => triggerClickOnMenuItem(MenuGtmEventId.B2B_OFFERTA, 'B2B', 'offerB2b', '/b2b/offerta/b2b-le-masterclass')}
                  href="/b2b/offerta/b2b-le-masterclass"
                  target="_self"
                  data-sveltekit-reload
                >
                  <li class="ml-4">Le Masterclass</li>
                </a>
              </ul>
            </MegaMenuPanelGroup>
            <MegaMenuPanelGroup title="" linkStyle="heading">
              <p>
                Opportunità
              </p>
              <ul>
                <a
                  on:click={() => triggerClickOnMenuItem(MenuGtmEventId.B2B_OPPORTUNITA, 'B2B', 'opportunityB2b', '/b2b/opportunita/b2b-fondo-nuove-competenze')}
                  href="/b2b/opportunita/b2b-fondo-nuove-competenze"
                  target="_self"
                  data-sveltekit-reload
                >
                  <li class="ml-4">Fondo Nuove Competenze</li>
                </a>
              </ul>
            </MegaMenuPanelGroup>
          </MegaMenuPanel>

          <NavbarAccountMobile slot="footer" />
        </MegaMenuMobile>
      </svelte:fragment>
    </NavbarMobile>

    <MegaMenu open={openMegaMenu === 'programmi'} on:close={onMegaMenuClose}>
      <MegaMenuPanel title="" colsCount="four">
        <MegaMenuPanelGroup title="Programmi Full Time">
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_MASTER_FULL_TIME_CON_STAGE,
                'Programmi',
                'Programmi Full Time - Master Post Laurea con Stage garantito',
                '/master-full-time'
              )}
            href="/master-full-time"
            target="_self"
            data-sveltekit-reload
          >
            Master Post Laurea con Stage garantito
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PERCORSI_MBA_FULL_TIME,
                'Percorsi',
                'Neo Laureati - MBA Full Time ',
                '/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita'
              )}
            href="/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita"
            target="_self"
            data-sveltekit-reload
          >
            MBA Full Time 
          </a>
        </MegaMenuPanelGroup>
        <MegaMenuPanelGroup title="Executive Part Time ">
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_EXECUTIVE_MASTER,
                'Programmi',
                'Executive Part Time  - Executive Master',
                '/executive-master'
              )}
            href="/executive-master"
            target="_self"
            data-sveltekit-reload
          >
            Executive Master
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_MBA_PART_TIME,
                'Programmi',
                'Executive Part Time  - MBA Part Time',
                '/formazione/mba-part-time/mba-part-time'
              )}
            href="/formazione/mba-part-time/mba-part-time"
            target="_self"
            data-sveltekit-reload
          >
            MBA Part Time
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_PROFESSIONAL_MASTER,
                'Programmi',
                'Executive Part Time  - Professional Master',
                '/professional-master'
              )}
            href="/professional-master"
            target="_self"
            data-sveltekit-reload
          >
            Professional Master
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_CORSI_EXECUTIVE,
                'Programmi',
                'Executive Part Time  - Corsi Executive',
                '/corsi-executive'
              )}
            href="/corsi-executive"
            target="_self"
            data-sveltekit-reload
          >
            Corsi Executive
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_MASTER_PART_TIME,
                'Programmi',
                'Executive Part Time  - Master Part Time',
                '/master-part-time'
              )}
            href="/master-part-time"
            target="_self"
            data-sveltekit-reload
          >
            Vedi tutti i Master Part Time
          </a>
        </MegaMenuPanelGroup>
        <MegaMenuPanelGroup title="MBA & Executive MBA">
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_EXECUTIVE_MBA,
                'Programmi',
                'MBA & Executive MBA - Executive MBA',
                '/mba'
              )}
            href="/mba"
            target="_self"
            data-sveltekit-reload
          >
            Executive MBA
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_MBA_FULL_TIME,
                'Programmi',
                'MBA & Executive MBA - MBA Full Time',
                '/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita'
              )}
            href="/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita"
            target="_self"
            data-sveltekit-reload
          >
            MBA Full Time
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_MBA_PART_TIME,
                'Programmi',
                'MBA & Executive MBA - MBA Part Time',
                '/formazione/mba-part-time/mba-part-time'
              )}
            href="/formazione/mba-part-time/mba-part-time"
            target="_self"
            data-sveltekit-reload
          >
            MBA Part Time
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_MBA_EMBA,
                'Programmi',
                'MBA & Executive MBA - MBA EMBA',
                '/mba-emba'
              )}
            href="/mba-emba"
            target="_self"
            data-sveltekit-reload
          >
            Vedi tutti gli MBA
          </a>
        </MegaMenuPanelGroup>
        <MegaMenuPanelGroup title="Altri Corsi">
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_CORSI_ON_DEMAND,
                'Programmi',
                'Altri Corsi - Corsi Online',
                '/corsi-online'
              )}
            href="/corsi-online"
            target="_self"
            data-sveltekit-reload
          > 
            Corsi Online
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_MASTER_EUROCONFERENCE,
                'Programmi',
                'Altri Corsi - Corsi Euroconference',
                '/master-euroconference'
              )}
            href="/master-euroconference"
            target="_self"
            data-sveltekit-reload
          >   
            Corsi Euroconference         
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROGRAMMI_MASTERCLASS,
                'Programmi',
                'Altri Corsi - Masterclass',
                '/masterclass'
              )}
            href="/masterclass"
            target="_self"
            data-sveltekit-reload
          >
            Masterclass
          </a>
        </MegaMenuPanelGroup>
      </MegaMenuPanel>
    </MegaMenu>

    <MegaMenu open={openMegaMenu === 'percorsi'} on:close={onMegaMenuClose}>
      <MegaMenuPanel title="Scegli per tipologia" colsCount="three">
        <MegaMenuPanelGroup title="Professionisti e Executive ">
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PERCORSI_EXECUTIVE_MASTER,
                'Percorsi',
                'Professionisti e Executive  - Executive Master ',
                '/executive-master'
              )}
            href="/executive-master"
            target="_self"
            data-sveltekit-reload
          >
            Executive Master 
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PERCORSI_EXECUTIVE_MBA,
                'Percorsi',
                'Professionisti e Executive  - Executive MBA ',
                '/mba'
              )}
            href="/mba"
            target="_self"
            data-sveltekit-reload
          >
            Executive MBA 
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PERCORSI_MBA_PART_TIME,
                'Percorsi',
                'Professionisti e Executive  - MBA Part Time ',
                '/formazione/mba-part-time/mba-part-time'
              )}
            href="/formazione/mba-part-time/mba-part-time"
            target="_self"
          >
            MBA Part Time 
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PERCORSI_CORSI_EXECUTIVE,
                'Percorsi',
                'Professionisti e Executive  - Corsi Executive',
                '/corsi-executive'
              )}
            href="/corsi-executive"
            target="_self"
            data-sveltekit-reload
          >
            Corsi Executive
          </a>
        </MegaMenuPanelGroup>
        <MegaMenuPanelGroup title="Neo Laureati">
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PERCORSI_MASTER_POST_LAUREA_CON_STAGE,
                'Percorsi',
                'Neo Laureati - Master Post Laurea con Stage garantito ',
                '/master-full-time'
              )}
            href="/master-full-time"
            target="_self"
            data-sveltekit-reload
          >
            Master Post Laurea con Stage  garantito
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PERCORSI_MBA_FULL_TIME,
                'Percorsi',
                'Neo Laureati - MBA Full Time ',
                '/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita'
              )}
            href="/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita"
            target="_self"
            data-sveltekit-reload
          >
            MBA Full Time 
          </a>
        </MegaMenuPanelGroup>
        <MegaMenuPanelGroup title="Open Programme">
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PERCORSI_PROFESSIONAL_MASTER,
                'Percorsi',
                'Open Programme - Professional Master ',
                '/professional-master'
              )}
            href="/professional-master"
            target="_self"
            data-sveltekit-reload
          >
            Professional Master 
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PERCORSI_CORSI_ONLINE,
                'Percorsi',
                'Open Programme - Corsi Online ',
                '/corsi-online'
              )}
            href="/corsi-online"
            target="_self"
            data-sveltekit-reload
          >
            Corsi Online 
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PERCORSI_CORSI_EUROCONFERENCE,
                'Percorsi',
                'Open Programme - Corsi Euroconference ',
                '/master-euroconference'
              )}
            href="/master-euroconference"
            target="_self"
            data-sveltekit-reload
          >
            Corsi Euroconference 
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PERCORSI_MASTERCLASS,
                'Percorsi',
                'Open Programme - Masterclass',
                '/masterclass'
              )}
            href="/masterclass"
            target="_self"
            data-sveltekit-reload
          >
            Masterclass
          </a>
        </MegaMenuPanelGroup>
      </MegaMenuPanel>
    </MegaMenu>

    <MegaMenu open={openMegaMenu === 'tematiche'} on:close={onMegaMenuClose}>
      <MegaMenuPanel title="Scegli per interesse" colsCount="three">
        <MegaMenuPanelGroup title="Le più cercate">
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.HR_LAVORO_E_SVILUPPO_MANAGERIALE,
                'Tematiche',
                'HR, DE&I e Diritto del Lavoro',
                '/hr-lavoro-e-sviluppo-manageriale'
              )}
            href="/hr-lavoro-e-sviluppo-manageriale"
            target="_self"
            data-sveltekit-reload
          >
            HR, DE&I e Diritto del Lavoro
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.MARKETING_COMUNICAZIONE_DIGITAL_E_SOCIAL_MEDIA,
                'Tematiche',
                'Marketing e Comunicazione',
                '/marketing-comunicazione-digital-e-social-media'
              )}
            href="/marketing-comunicazione-digital-e-social-media"
            target="_self"
            data-sveltekit-reload
          >
            Marketing e Comunicazione
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.AMMINISTRAZIONE_FINANZA_E_CONTROLLO,
                'Tematiche',
                'Amministrazione, Finanza e Controllo',
                '/amministrazione-finanza-e-controllo'
              )}
            href="/amministrazione-finanza-e-controllo"
            target="_self"
            data-sveltekit-reload
          >
            Amministrazione, Finanza e Controllo
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.DIRITTO_E_FISCO,
                'Tematiche',
                'Diritto e Fisco',
                '/diritto-e-fisco'
              )}
            href="/diritto-e-fisco"
            target="_self"
            data-sveltekit-reload
          >
            Diritto e Fisco
          </a>
        </MegaMenuPanelGroup>
        <MegaMenuPanelGroup title="Le più attuali">
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.ESG_SOSTENIBILITA_ENERGIA_E_AMBIENTE,
                'Tematiche',
                'ESG, Sostenibilità, Energia e Ambiente',
                '/ambiente-energia'
              )}
            href="/ambiente-energia"
            target="_self"
            data-sveltekit-reload
          >
            ESG, Sostenibilità, Energia e Ambiente
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.DIGITAL_TRANSFORMATION_TECNOLOGIA_E_INTELLIGENZA_ARTIFICIALE,
                'Tematiche',
                'Digital Transformation, Tecnologia e Intelligenza Artificiale',
                '/digital-transformation'
              )}
            href="/digital-transformation"
            target="_self"
            data-sveltekit-reload
          >
            Digital Transformation, Tecnologia e Intelligenza Artificiale
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.SOFT_SKILLS,
                'Tematiche',
                'Soft Skills ',
                '/soft-skills'
              )}
            href="/soft-skills"
            target="_self"
            data-sveltekit-reload
          >
            Soft Skills
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PROJECT_MANAGEMENT,
                'Tematiche',
                'Project Management',
                '/project-management'
              )}
            href="/project-management"
            target="_self"
            data-sveltekit-reload
          >
            Project Management
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.GESTIONE_DI_IMPRESA_EXPORT_E_INTERNAZIONALIZZAZIONE,
                'Tematiche',
                'General Management e Gestione di Impresa',
                '/gestione-di-impresa-export-e-internazionalizzazione'
              )}
            href="/gestione-di-impresa-export-e-internazionalizzazione"
            target="_self"
            data-sveltekit-reload
          >
            General Management e Gestione di Impresa
          </a>
        </MegaMenuPanelGroup>
        <MegaMenuPanelGroup title="Industries">
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.VENDITE_E_RETAIL,
                'Tematiche',
                'Retail, Sales ed Export',
                '/vendite-e-retail'
              )}
            href="/vendite-e-retail"
            target="_self"
            data-sveltekit-reload
          >
            Retail, Sales ed Export
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.SANITA_PHARMA_E_BIOMED,
                'Tematiche',
                'Sanità e Pharma',
                '/sanita-pharma-e-biomed'
              )}
            href="/sanita-pharma-e-biomed"
            target="_self"
            data-sveltekit-reload
          >
            Sanità e Pharma
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.LUSSO_E_MODA,
                'Tematiche',
                'Fashion e Luxury',
                '/lusso-e-moda'
              )}
            href="/lusso-e-moda"
            target="_self"
            data-sveltekit-reload
          >
            Fashion e Luxury
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.FOOD_BEVERAGE_E_TURISMO,
                'Tematiche',
                'Food, Beverage e Turismo',
                '/food-beverage-e-turismo'
              )}
            href="/food-beverage-e-turismo"
            target="_self"
            data-sveltekit-reload
          >
            Food, Beverage e Turismo
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.PUBBLICA_AMMINISTRAZIONE,
                'Tematiche',
                'Pubblica Amministrazione',
                '/pubblica-amministrazione'
              )}
            href="/pubblica-amministrazione"
            target="_self"
            data-sveltekit-reload
          >
            Pubblica Amministrazione
          </a>
          <a
            on:click={() =>
              triggerClickOnMenuItem(
                MenuGtmEventId.ARTE_CULTURA_E_SPORT,
                'Tematiche',
                'Arte, Cultura, Sport',
                '/arte-cultura-sport'
              )}
            href="/arte-cultura-sport"
            target="_self"
            data-sveltekit-reload
          >
            Arte, Cultura, Sport
          </a>
        </MegaMenuPanelGroup>
      </MegaMenuPanel>
    </MegaMenu>

    <MegaMenu open={openMegaMenu === 'per le imprese'} on:close={onMegaMenuClose}>
      <MegaMenuPanel title="Entra nel mondo B2B" colsCount="three">
        <MegaMenuPanelGroup title="" linkStyle="heading">
          <a
            on:click={() => triggerClickOnMenuItem(MenuGtmEventId.PER_LE_IMPRESE, 'B2B', 'Servizi per le imprese', '/b2b')}
            href="/b2b"
            target="_self"
            data-sveltekit-reload
          >
            Servizi per le imprese
          </a>
          <ul>
            <a
              on:click={() => triggerClickOnMenuItem(MenuGtmEventId.JOB_FINDING, 'B2B', 'Job finding', '/b2b/job-finding')}
              href="/b2b/job-finding"
              target="_self"
              data-sveltekit-reload
            >
              <li class="ml-4">Job Finding</li>
            </a>
            <a
              on:click={() => triggerClickOnMenuItem(MenuGtmEventId.SKILLING, 'B2B', 'Skilling', '/b2b/skilling')}
              href="/b2b/skilling"
              target="_self"
              data-sveltekit-reload
            >
              <li class="ml-4">Skilling</li>
            </a>
            <a
              on:click={() => triggerClickOnMenuItem(MenuGtmEventId.BRANDING, 'B2B', 'Branding', '/b2b/branding')}
              href="/b2b/branding"
              target="_self"
              data-sveltekit-reload
            >
              <li class="ml-4">Branding</li>
            </a>

            <a
            on:click={() => triggerClickOnMenuItem(MenuGtmEventId.BRANDING, 'B2B', 'Finance', '/b2b/finance')}
            href="/b2b/finance"
            target="_self"
            data-sveltekit-reload
          >
            <li class="ml-4">Finance</li>
          </a>
          </ul>
        </MegaMenuPanelGroup>
        <MegaMenuPanelGroup title="" linkStyle="heading">
          <p>
            Offerta
          </p>
          <ul>
            <a
              on:click={() => triggerClickOnMenuItem(MenuGtmEventId.B2B_OFFERTA, 'B2B', 'offerB2b', '/b2b/offerta/b2b-offerta-formativa-a-catalogo-per-le-aziende')}
              href="/b2b/offerta/b2b-offerta-formativa-a-catalogo-per-le-aziende"
              target="_self"
              data-sveltekit-reload
            >
              <li class="ml-4">Formazione Interaziendale a catalogo</li>
            </a>
            <a
              on:click={() => triggerClickOnMenuItem(MenuGtmEventId.B2B_OFFERTA, 'B2B', 'offerB2b', '/b2b/offerta/b2b-formazione-su-misura')}
              href="/b2b/offerta/b2b-formazione-su-misura"
              target="_self"
              data-sveltekit-reload
            >
              <li class="ml-4">Formazione su Misura</li>
            </a>
            <a
              on:click={() => triggerClickOnMenuItem(MenuGtmEventId.B2B_OFFERTA, 'B2B', 'offerB2b', '/b2b/offerta/b2b-hr-for-breakfast')}
              href="/b2b/offerta/b2b-hr-for-breakfast"
              target="_self"
              data-sveltekit-reload
            >
              <li class="ml-4">HR for Breakfast</li>
            </a>
            <a
              on:click={() => triggerClickOnMenuItem(MenuGtmEventId.B2B_OFFERTA, 'B2B', 'offerB2b', '/b2b/offerta/b2b-le-masterclass')}
              href="/b2b/offerta/b2b-le-masterclass"
              target="_self"
              data-sveltekit-reload
            >
              <li class="ml-4">Le Masterclass</li>
            </a>
          </ul>
        </MegaMenuPanelGroup>
        <MegaMenuPanelGroup title="" linkStyle="heading">
          <p>
            Opportunità
          </p>
          <ul>
            <a
              on:click={() => triggerClickOnMenuItem(MenuGtmEventId.B2B_OPPORTUNITA, 'B2B', 'opportunityB2b', '/b2b/opportunita/b2b-fondo-nuove-competenze')}
              href="/b2b/opportunita/b2b-fondo-nuove-competenze"
              target="_self"
              data-sveltekit-reload
            >
              <li class="ml-4">Fondo Nuove Competenze</li>
            </a>
          </ul>
        </MegaMenuPanelGroup>
      </MegaMenuPanel>
    </MegaMenu>

    <MegaMenu open={openMegaMenu === 'cerca'} on:close={onMegaMenuClose} noVerticalPadding showOnMobile>
      <SearchForm slot="header" />
      
      <svelte:fragment slot="footer">
        <SearchAutocompletion />
        <SearchQuickLinksHistory />
        <SearchQuickLinksFrequent />
        <SearchQuickLinksPopular />
      </svelte:fragment>
    </MegaMenu>
    
    <NavigationBackdrop visible={!!openMegaMenu || mobileNavbarVisible} on:click={closeAllPanels} />
  </svelte:fragment>
</Navbar>

<PromoLabel />
