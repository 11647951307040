<script lang="ts">
	import { page } from '$app/stores';
	import { PUBLIC_ENABLE_SEO, PUBLIC_BASE_URL } from '$env/static/public';
	import { appendCompanyName, cleanCourseHtml } from '$lib/utils/formatter';

	export let appendCompany: boolean = false;

	interface HeadData {
		title: string;
		description?: string;
		metaTagSocialTitle?: any;
		metaTagSocialDescription?: any;
		canonical?: string;
    url?: string;
		image?: string;
		metaTagSocialImage?: any;
		ldScriptTag?: string;
		noindex?: boolean;
		nofollow?: boolean;
	}

	export let seoDetails: HeadData = {
		title: ''
	};

	const removeTrailingSlash = (str: string) => {
		if (str) {
			return str.replace(/\/$/, '');
		}
	};

	const removePathId = (str: string) => {
		if (str) {
			const pathData = str.split('-');
			const pathId = pathData[pathData.length - 1];
			if (parseInt(pathId) && parseInt(pathId) > 0) {
				return str.substring(0, str.length - pathId.length - 1);
			} else return str;
		}
	};

	let title = appendCompany ? `${seoDetails.title} ${appendCompanyName()}` : seoDetails.title;

	let description =
    seoDetails?.description && seoDetails.description.length > 0 ? cleanCourseHtml(seoDetails.description).slice(0, 160) : '';

	let url = seoDetails.url
		? `${removeTrailingSlash(PUBLIC_BASE_URL)}${removeTrailingSlash(seoDetails.url)}`
		: `${removeTrailingSlash(PUBLIC_BASE_URL)}${removeTrailingSlash($page.url.pathname)}`;

	let canonical = seoDetails.canonical ? `${removeTrailingSlash(PUBLIC_BASE_URL)}${seoDetails.canonical}` : `${removeTrailingSlash(PUBLIC_BASE_URL)}${removeTrailingSlash($page.url.pathname) ?? ''}`;
</script>

<svelte:head>
	<title>{title}</title>
	<meta name="description" content={description} />
	<link
		rel="canonical"
		href={canonical}
	/>
	<meta property="og:url" content={url} />
	{#if seoDetails?.metaTagSocialTitle && seoDetails.metaTagSocialTitle.length > 0}
		<meta property="og:title" content={seoDetails.metaTagSocialTitle} />
		<meta name="twitter:title" content={seoDetails.metaTagSocialTitle} />
	{:else}
		<meta property="og:title" content={title} />
		<meta name="twitter:title" content={title} />
	{/if}
	{#if seoDetails?.metaTagSocialDescription && seoDetails.metaTagSocialDescription.length > 0}
		<meta property="og:description" content={seoDetails.metaTagSocialDescription} />
		<meta name="twitter:description" content={seoDetails.metaTagSocialDescription} />
	{:else}
		<meta property="og:description" content={description} />
		<meta name="twitter:description" content={description} />
	{/if}
	<meta name="og:url" content={url} />
	<meta name="twitter:url" content={url} />
	{#if seoDetails?.metaTagSocialImage && seoDetails.metaTagSocialImage.length > 0}
		<meta property="image" content={seoDetails.metaTagSocialImage} />
		<meta property="og:image" content={seoDetails.metaTagSocialImage} />
		<meta property="twitter:image" content={seoDetails.metaTagSocialImage} />
	{:else if seoDetails?.image && seoDetails.image.length > 0}
		<meta property="image" content={seoDetails.image} />
		<meta property="og:image" content={seoDetails.image} />
		<meta property="twitter:image" content={seoDetails.image} />
	{/if}
	{#if PUBLIC_ENABLE_SEO === 'true'}
		<meta
			name="robots"
			content={`${typeof seoDetails.noindex === 'undefined' ? 'index' : seoDetails.noindex ? 'noindex' : 'index'}, ${typeof seoDetails.nofollow === 'undefined' ? 'follow' : seoDetails.nofollow ? 'nofollow' : 'follow'}, max-snippet:-1, max-image-preview:large, max-video-preview:-1`}
		/>
	{:else}
		<meta name="robots" content="noindex,nofollow" />
	{/if}
	{#if seoDetails.ldScriptTag}
		{@html seoDetails.ldScriptTag}
	{/if}
</svelte:head>
