import { getDeviceInfo } from '$lib/utils/browser-utils';
import { derived, get, type Readable, writable, type Writable } from 'svelte/store';
import type {
  CampaignData,
  CampaignDataHistoryEntry,
  CampaignType,
  ICampaignStore,
  QueryCampaignData,
} from './types';
import { useCtaServicesApiClient } from '$lib/api/CtaServicesApiClientFactory';
import { browser } from '$app/environment';

interface LocalStorageWritable<T> extends Writable<T> { }

function localStorageWritable<T>(key: string, initialValue: T): LocalStorageWritable<T> {
  const _writable = writable<T>(initialValue);
  const jsonStr = localStorage.getItem(key);
  if (jsonStr) {
    try {
      _writable.set(JSON.parse(jsonStr));
    } catch (e) {
      //FAILING WHEN PARSING A STRING
      //@ts-ignore
      _writable.set(jsonStr);
    }
  }
  _writable.subscribe((current) => {
    localStorage.setItem(key, JSON.stringify(current));
  });

  return _writable;
}

class QueueArray<T> extends Array<T> {
  private _maxLength: number;

  constructor(maxLength: number) {
    super();
    this._maxLength = maxLength;
  }

  push(...items: T[]): number {
    if (this.length === this.maxLength) {
      this.shift();
    }
    return Array.prototype.push.apply(this, items);
  }

  get maxLength() {
    return this._maxLength;
  }
}

export class CampaignStore implements ICampaignStore {
  private static DEFAULT_KEY = 'CAMPAIGN_DATA';
  private static FIRST_CLICK_KEY = 'FIRSTCLICK_CAMPAIGN_DATA';
  private static HISTORY_KEY = 'HISTORY_CAMPAIGN_DATA';
  private static WEBID_KEY = 'webid';

  private static _instance: CampaignStore;

  private _campaignData: LocalStorageWritable<CampaignData | null>;
  private _firstClickCampaignData: LocalStorageWritable<CampaignData | null>;
  private _historyCampaignData: LocalStorageWritable<QueueArray<CampaignDataHistoryEntry>>;
  private _webid: LocalStorageWritable<string>;

  private constructor() {
    if (browser) {
      this._campaignData = localStorageWritable(CampaignStore.DEFAULT_KEY, null);
      this._firstClickCampaignData = localStorageWritable(CampaignStore.FIRST_CLICK_KEY, null);
      this._historyCampaignData = localStorageWritable(
        CampaignStore.HISTORY_KEY,
        new QueueArray(20)
      );
      this._webid = localStorageWritable(CampaignStore.WEBID_KEY, '');
    } else {
      this._campaignData = writable(null);
      this._firstClickCampaignData = writable(null);
      this._historyCampaignData = writable(new QueueArray(20));
      this._webid = writable('');
    }
  }

  public static getInstance() {
    if (CampaignStore._instance == null) {
      CampaignStore._instance = new CampaignStore();
    }
    return CampaignStore._instance;
  }

  public clearCampaignData() {
    this._campaignData.set(null);
  }

  public parseAndStoreFirstClickCampaignData(params: Record<string, string>) {
    const queryData: QueryCampaignData = this.parseCampaignData(params);
    const deviceInfo = getDeviceInfo();

    this._firstClickCampaignData.update((currentData) => {
      if (!currentData) {
        return {
          fbclid: queryData.fbclid,
          gclid: queryData.gclid,
          gclsrc: queryData.gclsrc,
          referrer: document.URL,
          utm_campaign: queryData.utm_campaign,
          utm_medium: queryData.utm_medium,
          utm_source: queryData.utm_source,
          type: this.getCampaignType(queryData),
          device: deviceInfo ? {
            type: deviceInfo.device,
            version: deviceInfo.deviceVersion,
            browser: deviceInfo.browser,
            browserVersion: deviceInfo.browserVersion,
          } : undefined,
        };
      } else if (this.isDeviceEmpty(currentData)) {
        // If device object was empty or string (as in previouse version) update it
        return {
          fbclid: currentData.fbclid,
          gclid: currentData.gclid,
          gclsrc: currentData.gclsrc,
          referrer: currentData.referrer,
          utm_campaign: currentData.utm_campaign,
          utm_medium: currentData.utm_medium,
          utm_source: currentData.utm_source,
          type: currentData.type,
          device: deviceInfo ? {
            type: deviceInfo.device,
            version: deviceInfo.deviceVersion,
            browser: deviceInfo.browser,
            browserVersion: deviceInfo.browserVersion,
          } : undefined,
        };
      }

      return {
        fbclid: currentData.fbclid,
        gclid: currentData.gclid,
        gclsrc: currentData.gclsrc,
        referrer: currentData.referrer,
        utm_campaign: currentData.utm_campaign,
        utm_medium: currentData.utm_medium,
        utm_source: currentData.utm_source,
        type: currentData.type,
        device: currentData.device,
      };
    });
  }

  public parseCampaignData(params: Record<string, string>): QueryCampaignData {
    return {
      utm_campaign: params['utm_campaign'] || '',
      utm_source: params['utm_source'] || '',
      utm_medium: params['utm_medium'] || '',
      fbclid: params['fbclid'] || '',
      gclid: params['gclid'] || '',
      gclsrc: params['glsrc'] || '',
    };
  }

  public async parseAndUpdateCampaignData(params: Record<string, string>) {
    const now = new Date();
    const queryData: QueryCampaignData = this.parseCampaignData(params);
    const deviceInfo = getDeviceInfo();

    this._campaignData.update((currentData: CampaignData | null) => {
      return {
        referrer: document.URL, // tracked referrer is the current document URL with all parameters
        utm_campaign: queryData.utm_campaign || currentData?.utm_campaign || '',
        utm_source: queryData.utm_source || currentData?.utm_source || '',
        utm_medium: queryData.utm_medium || currentData?.utm_medium || '',
        fbclid: queryData.fbclid || currentData?.fbclid || '',
        gclid: queryData.gclid || currentData?.gclid || '',
        gclsrc: queryData.gclsrc || currentData?.gclsrc || '',
      };
    });

    const history = get(this._historyCampaignData);
    const previousEntry = history[history.length - 1];
    const newEntry: CampaignDataHistoryEntry = {
      time: now.toISOString(),
      referrer: document.URL,
      utm_campaign: queryData.utm_campaign,
      utm_source: queryData.utm_source,
      utm_medium: queryData.utm_medium,
      fbclid: queryData.fbclid,
      gclid: queryData.gclid,
      gclsrc: queryData.gclsrc,
      type: this.getCampaignType(queryData),
      device: deviceInfo ? {
        type: deviceInfo.device,
        version: deviceInfo.deviceVersion,
        browser: deviceInfo.browser,
        browserVersion: deviceInfo.browserVersion,
      } : undefined,
    };

    if (this.shouldStore(newEntry, previousEntry)) {
      const client = useCtaServicesApiClient();
      const webid = get(this._webid);
      const result = await client.getSessionHistory({
        webid: webid ? webid : undefined,
        referrer: newEntry.referrer,
        utmCampaignName: newEntry.utm_campaign,
        utmCampaignSource: newEntry.utm_source,
        utmCampaignMedium: newEntry.utm_medium,
        utmCampaignTerm: '',
        utmCampaignContent: '',
        fbclid: newEntry.fbclid,
        gclid: newEntry.gclid,
        gclsrc: newEntry.gclsrc,
        type: newEntry.type,
        device: newEntry.device ? newEntry.device.type : undefined,
        deviceVersion: newEntry.device ? newEntry.device.version : undefined,
        browser: newEntry.device ? newEntry.device.browser : undefined,
        browserVersion: newEntry.device ? newEntry.device.browserVersion : undefined,
      });

      history.push(newEntry);
      this._historyCampaignData.set(history);
      this._webid.set(result.id);
    }
  }

  get webid(): Readable<string | null> {
    return derived(this._webid, (value) => value);
  }

  get campaignData(): Readable<CampaignData | null> {
    return derived(this._campaignData, (value) => value);
  }

  get firstClickCampaignData(): Readable<CampaignData | null> {
    return derived(this._firstClickCampaignData, (value) => value);
  }

  /****************** PRIVATE METHODS HELPERS ***********************/

  private isDeviceEmpty(campaignData: CampaignData) {
    return typeof campaignData.device !== 'object' || Object.keys(campaignData.device).length === 0;
  }

  private shouldStore(newEntry: CampaignDataHistoryEntry, oldEntry: CampaignDataHistoryEntry) {
    if (newEntry && oldEntry) {
      // Compare objects
      if (newEntry.type === 'direct' && oldEntry.type === 'direct') {
        return false;
      }

      const equal = JSON.stringify({ ...newEntry, time: undefined }) === JSON.stringify({ ...oldEntry, time: undefined });

      const hours = 8;
      const milliseconds = hours * 60 * 60 * 1000;

      const newTime = new Date(newEntry.time).getTime();
      const oldTime = new Date(oldEntry.time).getTime();

      return !equal || newTime - oldTime > milliseconds;
    }
    return true;
  }

  /**
   * Get the campaign type based on the query campaign data
   * @param queryData The campaign data of the url query
   * @returns The campaign type
   */
  private getCampaignType(queryData: QueryCampaignData): CampaignType | undefined {
    if (
      document.referrer &&
      !queryData.fbclid &&
      !queryData.gclid &&
      !queryData.gclsrc &&
      !queryData.utm_campaign &&
      !queryData.utm_medium &&
      !queryData.utm_medium &&
      !queryData.utm_source
    ) {
      return 'organic';
    } else if (
      document.referrer &&
      (queryData.fbclid ||
        queryData.gclid ||
        queryData.gclsrc ||
        queryData.utm_campaign ||
        queryData.utm_medium ||
        queryData.utm_medium ||
        queryData.utm_source)
    ) {
      return 'adv';
    } else if (
      !document.referrer &&
      !queryData.fbclid &&
      !queryData.gclid &&
      !queryData.gclsrc &&
      !queryData.utm_campaign &&
      !queryData.utm_medium &&
      !queryData.utm_medium &&
      !queryData.utm_source
    ) {
      return 'direct';
    }

    return undefined;
  }
}
