<script lang="ts">
	import Button from '$lib/components/atoms/Button/Button.svelte';
	import { createEventDispatcher } from 'svelte';
	import { fade, fly } from 'svelte/transition';

	export let open = false;
	export let compact = false;
	export let centerContent = false;
	export let showCloseButton = false;
	export let closeOnBackdropClick = false;
	export let full = false;

	const dispatch = createEventDispatcher();

	const backdropClick = () => {
		if (closeOnBackdropClick) {
			closeModal();
		}
	};

	const closeModal = () => {
		open = false;

		dispatch('close');
	};
</script>

{#if open}
	<div class="modal-container">
		<div class="modal-overlay" on:click={backdropClick} in:fade out:fade></div>

		<div class="modal">
			<div
				class="modal-dialog"
				class:v-compact={compact}
				class:v-content-center={centerContent}
				in:fly={{ y: -100 }}
				out:fly={{ y: -100 }}
			>
				<div class="modal-header">
					{#if showCloseButton}
						<div class="modal-close">
							<Button variant="icon" color="grey" icon="close" on:click={closeModal} />
						</div>
					{/if}

					{#if $$slots['title']}
						<h3 class="modal-title">
							<slot name="title" />
						</h3>
					{/if}
				</div>

				<div class="modal-body" class:w-full={full}>
					<slot />
				</div>

				{#if $$slots['actions']}
					<div class="modal-footer space-y-4">
						<slot name="actions" />
					</div>
				{/if}
			</div>
		</div>
	</div>
{/if}

<style type="text/scss" lang="scss" scoped>
	.modal-container {
		@apply fixed inset-0;
		@apply overflow-y-auto;
		@apply isolate z-modal;
	}

	.modal {
		@apply flex items-center justify-center;
		@apply min-h-screen py-4 px-4;
		@apply text-center;
	}

	.modal-dialog {
		@apply relative;
		@apply inline-block align-bottom text-left overflow-hidden;
		@apply rounded md:rounded-lg;
		@apply w-full;
		@apply bg-white;
		@apply shadow-xl;
		@apply p-6;
		@apply transform transition-all;
		@apply sm:my-8 sm:align-middle sm:max-w-2xl;
		@apply z-10;

		&.v-compact {
			@apply sm:max-w-md;
		}

		&.v-content-center {
			@apply inline-flex flex-col items-center;

			> * {
				@apply mx-auto;
			}

			.modal-title {
				@apply text-center;
				@apply max-w-sm mx-auto;
			}
		}
	}

	.modal-header {
		@apply flex flex-col;
		@apply w-full;
		@apply mb-2;
	}

	.modal-title {
		@apply text-2xl md:text-5xl;
		@apply leading-tight font-medium;
		@apply text-gray-900;
		@apply mt-2;
	}

	.modal-close {
		@apply ml-auto;
		@apply -my-3.5 -mr-3.5;
	}

	.modal-footer {
		@apply w-full;
		@apply flex flex-col;
		@apply mt-6;

		> * {
			@apply w-full;
			@apply sm:w-auto;
		}

		> *:not(:last-child) {
			@apply sm:ml-3;
		}
	}

	.modal-overlay {
		@apply fixed inset-0;
		@apply bg-gray-900 bg-opacity-80;
		@apply transition-opacity;
		@apply -z-1;
	}
</style>
