<script lang="ts">
	import { onMount } from 'svelte';
	import { PUBLIC_GTAG_ID } from '$env/static/public';
	import { detectRobot } from '$lib/utils/browser-utils';
	// Stores
	import { GtmStore } from '$lib/utils/gtm-store';

	// Pass or harcode your google analytics tracking id
	export let trackingId = PUBLIC_GTAG_ID;
	export let scriptId = 'google-analytics-script';
	export let domain = 'https://www.googletagmanager.com';

	let mounted = false;

	// Adding google tag manager will fail for example if the user is running
	// and ad blocker. This Promise can handle that case.
	async function addGoogleAnalyticsScript(dataLayerName = 'dataLayer') {
		return new Promise((resolve, reject) => {
			const head = document.head || document.getElementsByTagName('head')[0];

			const link = document.createElement('link');
			link.href = domain;
			link.rel = 'preconnect';

			head.appendChild(link);

			const script = document.createElement('script');
			script.async = true;
			script.src = `${domain}/gtm.js?id=${trackingId}`;
			script.setAttribute('id', scriptId);
			script.setAttribute('nonce', '0ddabbfa-1626-11ec-b30f-5254001bd753'); // FIXME: hardcoded from server.js, make it dynamic!

			head.appendChild(script);

			script.addEventListener('load', () => {
				//console.log("GTM Loaded");
				resolve(true);
			});
			script.addEventListener('error', () => {
				reject;
			});
		});
	}

	onMount(async () => {
		// We add the script only once even when the component rendered twice.
		if (typeof window !== 'undefined') {
			if (
				window.document.getElementById(scriptId) ||
				(typeof navigator !== 'undefined' &&
					navigator.userAgent &&
					detectRobot(navigator.userAgent, false))
			) {
				return;
			}

			window.dataLayer = window.dataLayer || [];

			window.gtag = function () {
				window.dataLayer.push(arguments);
			};

			try {
				await addGoogleAnalyticsScript();
				if (
					typeof window.dataLayer !== 'undefined' &&
					typeof window.dataLayer.push !== 'undefined'
				) {
					window.dataLayer.push({
						'gtm.start': new Date().getTime(),
						event: 'gtm.js'
					});
					window.dataLayer.push('js', new Date());
					window.dataLayer.push('config', trackingId);
					GtmStore.gtmIsReady();
				}
			} catch (err) {
				console.error('gtag failure', err);

				const s = window.document.getElementById(scriptId);

				if (s) {
					s.remove();
				}

				return;
			}

			mounted = true;
		}
	});
</script>
