import { CtaServicesApiClient } from './generated/cta-services';
import { useOktaStore } from '$lib/utils/auth-store';
import { PUBLIC_CTA_SERVICES_API_KEY, PUBLIC_CTA_SERVICES_URL } from '$env/static/public';
import createProxiedService, { type ExtendedDefaultService } from './ExtendedCtaServiceProxy';

/**
 * Get the CTA Services API client instance.
 * @returns The CTA Services API client instance.
 */
export const useCtaServicesApiClient = (): ExtendedDefaultService => {
    const apiKey = PUBLIC_CTA_SERVICES_API_KEY ?? '';
    const apiUrl = PUBLIC_CTA_SERVICES_URL ?? '';

    if (!apiKey) {
        throw new Error('No API key found');
    }

    if (!apiUrl) {
        throw new Error('No API URL found');
    }

    const headers: any = {
        'x-api-key': apiKey
    };

    const authStore = useOktaStore();
    const accessToken = authStore.accessToken;

    if (accessToken) {
        headers['Authorization'] = 'Bearer ' + accessToken;
    } else {
        console.warn('No access token found');
    }

    const defaultService = new CtaServicesApiClient({
        HEADERS: headers,
        BASE: apiUrl
    }).default;

    return createProxiedService(defaultService);
}

export default useCtaServicesApiClient;