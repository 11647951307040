import { goto } from '$app/navigation';
import { useCampaignStore } from '$lib/utils/campaign-store';
import { derived, get, type Readable, writable, type Writable } from 'svelte/store';
import { GtmStore } from '$lib/utils/gtm-store';
import {
  type DataStep1,
  type DataStep2,
  type DataStep3,
  type DataStep4,
  type DataStep5,
  type DataStep6,
  type ScholarshipWizard,
  Steps,
} from './types';
import { browser } from '$app/environment';
import { useCtaServicesApiClient } from "$lib/api/CtaServicesApiClientFactory";
import { useOktaStore } from '../auth-store';
import { type SubmitAdmissionRequestRequest, type SubmitScholarshipRequestRequest } from '$lib/api/generated/cta-services';
import { fromCampaignDataTypesToWebsiteSessionDataTypes } from '../cta-services-mapper-utils';
import { createCtaEvent } from '../cta-flow/helpers';

export class ScholarshipWizardImpl implements ScholarshipWizard {
  private static _instance: ScholarshipWizardImpl;

  private _currentStep: Writable<Steps>;
  private _nextButtonDisabled: Writable<boolean>;
  private _previousButtonDisabled: Writable<boolean>;

  private _dataStep1: DataStep1 | null;
  private _dataStep2: DataStep2 | null;
  private _dataStep3: DataStep3 | null;
  private _dataStep4: DataStep4 | null;
  private _dataStep5: DataStep5 | null;
  private _dataStep6: DataStep6 | null;

  constructor() {
    // Initialize the fields here (Maybe get stored value from local/session storage?)

    if (browser) {
      this._dataStep1 = this.getStepDataLocalStorage(1);
      this._dataStep2 = this.getStepDataLocalStorage(2);
      this._dataStep3 = this.getStepDataLocalStorage(3);
      this._dataStep4 = this.getStepDataLocalStorage(4);
      this._dataStep5 = this.getStepDataLocalStorage(5);
      this._dataStep6 = this.getStepDataLocalStorage(6);
    } else {
      this._dataStep1 = null;
      this._dataStep2 = null;
      this._dataStep3 = null;
      this._dataStep4 = null;
      this._dataStep5 = null;
      this._dataStep6 = null;
    }

    const startFromStep1 = this._dataStep1 !== null;
    this._currentStep = writable<Steps>(startFromStep1 ? Steps.STEP_1 : Steps.STEP_0);
    this._nextButtonDisabled = writable(false);
    this._previousButtonDisabled = writable(false);
  }

  public get hasStarted(): Readable<boolean> {
    return derived(this._currentStep, (value) => value > Steps.STEP_0);
  }

  public get currentStep(): Readable<Steps> {
    return derived(this._currentStep, (value) => value);
  }

  public get hasNextStep(): Readable<boolean> {
    return derived(this._currentStep, (value) => value <= Steps.STEP_6);
  }

  public get hasPreviousStep(): Readable<boolean> {
    return derived(this._currentStep, (value) => value > Steps.STEP_1);
  }

  public get nextButtonDisabled(): Readable<boolean> {
    return derived(this._nextButtonDisabled, (value) => value);
  }

  public get previousButtonDisabled(): Readable<boolean> {
    return derived(this._previousButtonDisabled, (value) => value);
  }

  public get dataStep1() {
    return this._dataStep1;
  }

  public get dataStep2() {
    return this._dataStep2;
  }

  public get dataStep3() {
    return this._dataStep3;
  }

  public get dataStep4() {
    return this._dataStep4;
  }

  public get dataStep5() {
    return this._dataStep5;
  }
  public get dataStep6() {
    return this._dataStep6;
  }

  setNextButtonStatus(active: boolean) {
    this._nextButtonDisabled.set(!active);
  }

  setPreviousButtonStatus(active: boolean) {
    this._previousButtonDisabled.set(!active);
  }

  start() {
    this._currentStep.set(Steps.STEP_1);
    /******************** GTM EVENT PUSH *************************/
    GtmStore.pushEvent({
      pageCategory: '_AdesioneBorse_STEP[1]',
    });
    GtmStore.pushPageView();
    /*************************************************************/
  }

  goToNextStep() {
    const hasNextStep = get(this.hasNextStep);
    if (hasNextStep) {
      this._currentStep.update((value) => value + 1);
      const el = document.getElementById('scholarshipWizardContainer');
      if (el) {
        window.scroll(0, el.offsetTop - 90);
      }
      /******************** GTM EVENT PUSH *************************/
      GtmStore.pushEvent({
        pageCategory: '_AdesioneBorse_STEP[' + get(this.currentStep) + ']',
      });
      GtmStore.pushPageView();
      /*************************************************************/
    }
  }

  goToPreviousStep() {
    const hasPreviousStep = get(this.hasPreviousStep);
    if (hasPreviousStep) {
      this._nextButtonDisabled.set(false);
      this._currentStep.update((value) => value - 1);
      const el = document.getElementById('scholarshipWizardContainer');
      if (el) {
        window.scroll(0, el.offsetTop - 90);
      }
      /******************** GTM EVENT PUSH *************************/
      GtmStore.pushEvent({
        pageCategory: '_AdesioneBorse_STEP[' + get(this.currentStep) + ']',
      });
      GtmStore.pushPageView();
      /*************************************************************/
    }
  }

  setDataStep1(data: DataStep1 | null) {
    this._dataStep1 = data;
    this.setStepDataLocalStorage('step1', data);
  }

  setDataStep2(data: DataStep2 | null) {
    this._dataStep2 = data;
    this.setStepDataLocalStorage('step2', data);
  }
  setDataStep3(data: DataStep3 | null) {
    this._dataStep3 = data;
    this.setStepDataLocalStorage('step3', data);
  }
  setDataStep4(data: DataStep4 | null) {
    this._dataStep4 = data;
    this.setStepDataLocalStorage('step4', data);
  }
  setDataStep5(data: DataStep5 | null) {
    this._dataStep5 = data;
    this.setStepDataLocalStorage('step5', data);
  }
  setDataStep6(data: DataStep6 | null) {
    this._dataStep6 = data;
    this.setStepDataLocalStorage('step6', data);
  }

  setStepDataLocalStorage(name: string, data: any) {
    const scholarshipStr = localStorage.getItem('scholarship');
    let newData;
    if (scholarshipStr) {
      const scholarship = JSON.parse(decodeURI(scholarshipStr));
      if (scholarship) {
        newData = {
          ...scholarship,
          [name]: data,
        };
      }
    } else {
      newData = {
        [name]: data,
      };
    }
    const encodedData = encodeURI(JSON.stringify(newData));
    localStorage.setItem('scholarship', encodedData);
  }

  getStepDataLocalStorage(step: number) {
    const name = 'step' + step;
    const scholarshipStr = localStorage.getItem('scholarship');
    if (scholarshipStr) {
      const scholarship = JSON.parse(decodeURI(scholarshipStr));
      if (scholarship) {
        return scholarship[name];
      }
    }
    return null;
  }

  cleanLocalStorage() {
    this.setDataStep1(null);
    this.setDataStep2(null);
    this.setDataStep3(null);
    this.setDataStep4(null);
    this.setDataStep5(null);
    this.setDataStep6(null);
  }

  private createInformationSubject(): string {
    return (
      'Borsa di studio ' +
      (this._dataStep2?.primarySession && this._dataStep2.primarySession.productCode
        ? 'Prima Opzione ' + this._dataStep2.primarySession.productCode
        : 'CORSO NON SELEZIONATO ')
    );
  }

  private hasMasterFields() {
    return (
      this._dataStep3?.masterAtInstitute &&
      this._dataStep3?.masterGraduation &&
      this._dataStep3?.masterThesisTitle &&
      this._dataStep3?.masterVote &&
      this._dataStep3?.masterStartYear &&
      this._dataStep3?.masterEndYear
    );
  }

  private formatBirthday(birthday: string) {
    const formatted = birthday.split('-').map((p) => parseInt(p));

    const formatter = new Intl.NumberFormat('it', {
      minimumIntegerDigits: 2,
    });

    const year = formatted[0];
    const month = formatter.format(formatted[1]);
    const day = formatter.format(formatted[2]);

    return `${year.toString()}${month}${day}`;
  }

  private formatDate(date: string, toIso: boolean) {
    if (date) {
      const birthday = date + '';

      const year = birthday.substr(0, 4);
      const month = birthday.substr(4, 2);
      const day = birthday.substr(6, 2);

      const formattedDate = new Date();
      formattedDate.setFullYear(parseInt(year));
      formattedDate.setMonth(parseInt(month) - 1); // 0 based...
      formattedDate.setDate(parseInt(day));

      return toIso ? formattedDate.toISOString().substring(0, 10) : formattedDate.toLocaleDateString('it-IT');
    }

    return null;
  }

  private createMessage(): string {
    return `
      Luogo di nascita: ${this._dataStep1?.birthplace} |
      Provincia di residenza: ${this._dataStep1?.province} |
      Profilo Linkedin: ${this._dataStep1?.linkedinProfile} |
      ${this._dataStep2?.secondarySession
        ? `Seconda Opzione: ${this.formatDate(this._dataStep2?.secondarySession.courseStartDate, true)} {}Codice Iniziativa: ${this._dataStep2.secondarySession.productCode}|`
        : 'Seconda Opzione: Non Selezionata {}Codice Iniziativa: |'
      }
      Diploma di Maturità: ${this._dataStep3?.graduation} {}Presso ${this._dataStep3?.graduationAtInstitute} {}Voto ${this._dataStep3?.graduationVote} {}Anno di fine ${this._dataStep3?.graduationYear}|
      Laurea Specialistica: ${this._dataStep3?.specialistGraduation} {}Presso ${this._dataStep3?.specialistAtInstitute} {}Voto ${this._dataStep3?.specialistVote} {}Anno di inizio ${this._dataStep3?.specialistStartYear} {}Anno di fine ${this._dataStep3?.specialistEndYear}|
      ${this.hasMasterFields()
        ? `Laurea Magistrale: ${this._dataStep3?.masterGraduation} {}Presso ${this._dataStep3?.masterAtInstitute} {}Voto ${this._dataStep3?.masterVote} {}Anno di inizio ${this._dataStep3?.masterStartYear} {}Anno di fine ${this._dataStep3?.masterEndYear}|`
        : 'Laurea Magistrale: Nessuna {}Presso {}Voto {}Anno di inizio {}Anno di fine |'
      }
      Esperienza Lavorativa 1: da ${this._dataStep4?.lastExperienceStartDate} {}a ${this._dataStep4?.lastExperienceEndDate} {}Ruolo ${this._dataStep4?.lastExperienceRole} {}Presso ${this._dataStep4?.lastExperienceCompany}|
      Esperienza Lavorativa 2: da ${this._dataStep4?.secondExperienceStartDate} {}a ${this._dataStep4?.secondExperienceEndDate} {}Ruolo ${this._dataStep4?.secondExperienceRole} {}Presso ${this._dataStep4?.secondExperienceCompany}|
      Attività Extra Curricolari: ${this._dataStep5?.extraCurricularActivity}|
      Hobby e Interessi: ${this._dataStep5?.hobbyAndInterests}|
      ${this._dataStep6?.knowIsee === 'true'
        ? `Fascia ISEE: ${this._dataStep6?.iseeRange} {}Posizione economica: Non Selezionata |`
        : `Fascia ISEE: Non Selezionata {}Posizione economica: ${this._dataStep6?.economicPosition} |`
      }
    `.replace(/  +/g, ' ');
  }

  private createScholarSchipRequest(): SubmitScholarshipRequestRequest {
    const campaignStore = useCampaignStore();
    const campaignData = get(campaignStore.campaignData);
    const webid = get(campaignStore.webid);

    const request: SubmitScholarshipRequestRequest = {
      // User related data
      bsid: this._dataStep1?.bsid,
      id: this._dataStep1?.id,
      name: this._dataStep1?.name,
      surname: this._dataStep1?.surname,
      email: this._dataStep1?.email,
      phone: this._dataStep1?.phone,
      subscribe: this._dataStep1?.subscribe,
      profilingConsent: this._dataStep1?.profilingConsent,
      businessUseConsent: this._dataStep1?.businessUseConsent,
      birthday: this._dataStep1?.birthday ? this.formatBirthday(this._dataStep1?.birthday) : undefined,
      // Website and campaign related data
      referrer: campaignData?.referrer,
      utmCampaignSource: campaignData?.utm_source,
      utmCampaignMedium: campaignData?.utm_medium,
      utmCampaignName: campaignData?.utm_campaign,
      utmCampaignTerm: '',
      utmCampaignContent: '',
      fbclid: campaignData?.fbclid,
      gclid: campaignData?.gclid,
      gclsrc: campaignData?.gclsrc,
      websiteSessionData: {
        referrer: campaignData?.referrer,
        utmCampaignSource: campaignData?.utm_source,
        utmCampaignMedium: campaignData?.utm_medium,
        utmCampaignName: campaignData?.utm_campaign,
        utmCampaignTerm: '',
        utmCampaignContent: '',
        fbclid: campaignData?.fbclid,
        gclid: campaignData?.gclid,
        gclsrc: campaignData?.gclsrc,
        type: fromCampaignDataTypesToWebsiteSessionDataTypes(campaignData?.type),
        webid: webid || undefined,
      },
      device: campaignData?.device,
      // Degree related data
      degreeLevel: this.hasMasterFields() ? this._dataStep3?.masterGraduation : this._dataStep3?.specialistGraduation,
      degreeTitle: `Diploma in ${this.hasMasterFields() ? this._dataStep3?.masterThesisTitle : this._dataStep3?.specialistThesisTitle
        } Anno di fine ${this.hasMasterFields() ? this._dataStep3?.masterEndYear : this._dataStep3?.specialistEndYear}`,
      degreeVote: this.hasMasterFields() ? this._dataStep3?.masterVote : this._dataStep3?.specialistVote,
      // Rest of the data
      informationSubject: this.createInformationSubject(),
      message: this.createMessage(),
    };
    // Product related data
    if (this._dataStep2 && this._dataStep2.primarySession) {
      request.sessionId = this._dataStep2.primarySession ? this._dataStep2.primarySession.sessionId || undefined : undefined;
      request.productCode = this._dataStep2.primarySession?.productCode || '';
      request.category = this._dataStep2.primarySession?.category || '';
      request.courseTypeFull = this._dataStep2.primarySession?.courseTypeFull || '';
      request.courseTitle = this._dataStep2.primarySession?.courseTitle || '';
      request.price = this._dataStep2.primarySession?.price || 0;
      request.discountPrice = this._dataStep2.primarySession?.discountPrice || 0;
      request.lessionMode = this._dataStep2.primarySession?.lessonMode || '';
      request.brochureAvailable = this._dataStep2.primarySession?.brochureAvailable || false;
      request.brochureBooking = this._dataStep2.primarySession?.brochureBooking || false;
      request.brochureUrl = this._dataStep2.primarySession?.brochureUrl || undefined;
      request.courseUrl = this._dataStep2.primarySession?.courseUrl || undefined;
      request.courseStartDate = this._dataStep2.primarySession?.courseStartDate || undefined;
      request.courseReferrerName = this._dataStep2.primarySession?.courseReferrerName || undefined;
      request.courseReferrerEmail = this._dataStep2.primarySession?.courseReferrerEmail || undefined;
    }
    return request;
  }

  private createAutoGeneratedAdmissionRequest(): SubmitAdmissionRequestRequest {
    const campaignStore = useCampaignStore();
    const campaignData = get(campaignStore.campaignData);
    const firstClickCampaignData = get(campaignStore.firstClickCampaignData);
    const webid = get(campaignStore.webid);

    let productSessionId = 0;
    let productCategory = '';
    let productCourseTypeFull = '';
    let productPrice = 0;
    let productDiscountPrice = 0;
    let productLessonMode = '';
    let productCourseUrl = '';
    let productBrochureOrDownloadUrl = '';
    let productCourseReferrerEmail = '';
    let productCourseReferrerName = '';
    let productCourseTitle = '';
    let productProductCode = '';

    // Product related data
    if (this._dataStep2 && this._dataStep2.primarySession) {
      productSessionId = this._dataStep2.primarySession ? this._dataStep2?.primarySession?.sessionId : 0;
      productCategory = this._dataStep2.primarySession?.category || '';
      productCourseTypeFull = this._dataStep2.primarySession?.courseTypeFull || '';
      productPrice = this._dataStep2.primarySession?.price || 0;
      productDiscountPrice = this._dataStep2.primarySession?.discountPrice || 0;
      productLessonMode = this._dataStep2.primarySession?.lessonMode || '';
      productCourseUrl = this._dataStep2.primarySession?.courseUrl || '';
      productBrochureOrDownloadUrl = this._dataStep2.primarySession?.brochureUrl || '';
      productCourseReferrerEmail = this._dataStep2.primarySession?.courseReferrerEmail || '';
      productCourseReferrerName = this._dataStep2.primarySession?.courseReferrerName || '';
      productCourseTitle = this._dataStep2.primarySession?.courseTitle || '';
      productProductCode = this._dataStep2.primarySession?.productCode || '';
    }

    const request: SubmitAdmissionRequestRequest = {
      bsid: this._dataStep1?.bsid,
      id: this._dataStep1?.id,
      name: this._dataStep1?.name,
      surname: this._dataStep1?.surname,
      email: this._dataStep1?.email,
      birthdate: this._dataStep1?.birthday ? this.formatBirthday(this._dataStep1?.birthday) : undefined,
      phone: this._dataStep1?.phone,
      consent1: false,
      consent2: false,
      consent3: false,
      //product related data
      sessionId: productSessionId,
      category: productCategory,
      courseTypeFull: productCourseTypeFull,
      price: productPrice,
      discountPrice: productDiscountPrice,
      lessionMode: productLessonMode,
      courseUrl: productCourseUrl,
      brochureOrDownloadUrl: productBrochureOrDownloadUrl,
      courseReferrerEmail: productCourseReferrerEmail,
      courseReferrerName: productCourseReferrerName,
      courseTitle: productCourseTitle,
      productCode: productProductCode,
      degreeTitle: `Diploma in ${this.hasMasterFields() ? this._dataStep3?.masterThesisTitle : this._dataStep3?.specialistThesisTitle
        } Anno di fine ${this.hasMasterFields() ? this._dataStep3?.masterEndYear : this._dataStep3?.specialistEndYear}`,
      degreeLevel: this.hasMasterFields() ? "2" : "1",
      degreeVote: this.hasMasterFields() ? this._dataStep3?.masterVote : this._dataStep3?.specialistVote,
      scholarshipType: undefined,
      scholarshipMandatory: false,
      discountCode: '',
      // optional, not functional to email sending
      password: '',
      referrer: campaignData?.referrer,
      // Old campaign data
      utmCampaignSource: campaignData?.utm_source,
      utmCampaignMedium: campaignData?.utm_medium,
      utmCampaignName: campaignData?.utm_campaign,
      utmCampaignTerm: '',
      utmCampaignContent: '',
      fbclid: campaignData?.fbclid,
      gclid: campaignData?.gclid,
      gclsrc: campaignData?.gclsrc,
      // First click campaign data
      websiteSessionData: {
        referrer: campaignData?.referrer,
        utmCampaignSource: campaignData?.utm_source,
        utmCampaignMedium: campaignData?.utm_medium,
        utmCampaignName: campaignData?.utm_campaign,
        utmCampaignTerm: '',
        utmCampaignContent: '',
        fbclid: campaignData?.fbclid,
        gclid: campaignData?.gclid,
        gclsrc: campaignData?.gclsrc,
        type: fromCampaignDataTypesToWebsiteSessionDataTypes(campaignData?.type),
        webid: webid || undefined,
      },
      device: campaignData?.device,
      autoGenerated: true,
    }

    return request;
  }

  async sendRequest() {
    const request = this.createScholarSchipRequest();
    const admissionRequest = this.createAutoGeneratedAdmissionRequest();

    try {
      const client =  useCtaServicesApiClient();
      await client.submitScholarshipRequest(request, createCtaEvent('scholarship'));
      //call admissionRequestAutoGenerated only if preference is choosen 
      if (admissionRequest.productCode !== '') {
        await client.submitAdmissionRequest(admissionRequest, createCtaEvent('admission_request'));
      }
      const authStore = useOktaStore();
      const userInfo = get(authStore.userInfo);

      if (!userInfo) {
        throw new Error('User info not found');
      }

      if (!this._dataStep1?.name) {
        throw new Error('Name is required');
      }

      if (!this._dataStep1?.surname) {
        throw new Error('Surname is required');
      }

      await authStore.updateUserProfile({
        ...userInfo,
        name: this._dataStep1?.name,
        surname: this._dataStep1?.surname,
        phone: this._dataStep1?.phone,
        birthday: this._dataStep1?.birthday ? this.formatBirthday(this._dataStep1?.birthday) : undefined,
      });

      GtmStore.pushEvent(
        {
          event: 'BBevent',
          category: 'AdesioneBorseStudio',
          action: 'Conferma',
          label: 'NOT SET',
          event_id: '12',
        },
        false
      );

      await goto('/borse-di-studio/adesione/thankyou', { replaceState: true });
      /******************** GTM EVENT PUSH *************************/
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public static getInstance() {
    if (!ScholarshipWizardImpl._instance) {
      ScholarshipWizardImpl._instance = new ScholarshipWizardImpl();
    }
    return ScholarshipWizardImpl._instance;
  }
}
