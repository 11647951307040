<script lang="ts">
	import { page } from '$app/stores';
	import { onDestroy, onMount } from 'svelte';
	import { goto } from '$app/navigation';
	import { SessionModel, stringToSlug } from '@24hbs/utils';
	// Utils
	import { getURLFromContentfulAsset, handleUrlClientSide } from '$lib/utils/assets-utils';
	import {
		hasVatFreeSession,
		lessionModeText,
		lessonModeIcon,
		lessonModeTooltip
	} from '$lib/utils/course-utils';
	import { detectRobot } from '$lib/utils/browser-utils';
	import { useCTAFlow } from '$lib/utils/cta-flow';
	import { ctaRequestBuilders } from '$lib/utils/cta-flow/helpers';
	import { dateFullMonthFormat, isValidDate } from '$lib/utils/formatter';
	import type { HeroSlide, HomepageSlide } from '$lib/utils/homepage/slider/types';
	import { Monitor } from '$lib/utils/monitor';
	// Stores
	import { CourseType, CourseTypeStore } from '$lib/utils/courses/type-store';
	import { GtmStore } from '$lib/utils/gtm-store';
	// Components
	import { BadgeLatestSeats, BadgePromoDetails } from '$lib/components/atoms/Badge';
	import { Button } from '$lib/components/atoms/Button';
	import { Icon } from '$lib/components/atoms/Icon';
	import { LinkTo, LinkPlayVideo } from '$lib/components/atoms/Link';
	import { Price } from '$lib/components/atoms/Price';
	import { Tooltip, TooltipDesktop } from '$lib/components/atoms/Tooltip';
	import { Slider, SliderSlide } from '$lib/components/organisms/Slider';
	import Hero from './Hero.svelte';

	export let homepageSlides: HomepageSlide[];
	export let isBestSeller: boolean = false;
	export let hasCustomizedProductSlide = false;

	let monitor: Monitor;
	let sliderElement: any;
	let slideIndex = 0;
	let pushedSlides: boolean[] = new Array(homepageSlides.length).fill(false);

	let isGoogleBot = false;
	if (
		typeof navigator !== 'undefined' &&
		navigator.userAgent &&
		!detectRobot(navigator.userAgent, false)
	) {
		isGoogleBot = true;
	}
	let initialDelay = true;
	let loading = false;

	const ctaFlow = useCTAFlow();

	const onDownloadBrochure = (session: SessionModel) => {
		if (loading) {
			return;
		}
		loading = true;
		ctaFlow.startCTAFlow(
			$page.url.pathname,
			'downloadBrochure',
			ctaRequestBuilders.downloadBrochure(session, $page)
		);
	};

	const getVideoFile = (slide: HeroSlide) => {
		if (slide.media) {
			return {
				url: getURLFromContentfulAsset(slide.media),
				contentType: slide.media.fields.file.contentType
			};
		}
		return {
			url: null,
			contentType: null
		};
	};

	const pushGTMViewEvent = () => {
		if (!pushedSlides[slideIndex]) {
			GtmStore.pushHomepageSlideViewEvent(homepageSlides[slideIndex], slideIndex + 1);
			pushedSlides[slideIndex] = true;
		}
	};

	const pushGTMEvent = (link: string) => {
		GtmStore.pushHomepageSlideEvent(homepageSlides[slideIndex], slideIndex + 1);
		goto(link);
	};

	const onChangeSlide = (event: CustomEvent<number>) => {
		slideIndex = event.detail;

		if (monitor.isElementVisible()) {
			pushGTMViewEvent();
		}
	};

	onMount(async () => {
		/*TODO: Do this by using CSS only*/
		if (
			typeof navigator !== 'undefined' &&
			navigator.userAgent &&
			!detectRobot(navigator.userAgent, false)
		) {
			isGoogleBot = false;
		} else {
			isGoogleBot = true;
		}
		if (isGoogleBot || hasCustomizedProductSlide) {
			initialDelay = false;
		} else {
			setTimeout(() => {
				initialDelay = false;
			}, 400);
		}

		/*********TODO: Do this by using CSS only*********/
	});

	$: if (sliderElement && !monitor) {
		monitor = new Monitor(sliderElement, pushGTMViewEvent);
		monitor.startMonitoring();
	}

	onDestroy(() => {
		if (monitor) {
			monitor.stopMonitoring();
		}
	});
</script>

<section bind:this={sliderElement}>
	<Slider on:changeSlide={onChangeSlide}>
		{#each homepageSlides as slide}
			{#if 'course' in slide}
				<SliderSlide>
					<section
						class="hero-product {CourseTypeStore.getCourseTypeCssClassByCourseType(
							CourseTypeStore.getCourseTypeByCourseTypeName(slide.course.courseTypeFull)
						)}"
						class:defaultIntroAnimation={!initialDelay &&
							(isGoogleBot || !hasCustomizedProductSlide)}
						class:quickIntroAnimation={!initialDelay && !isGoogleBot && hasCustomizedProductSlide}
					>
						<div
							on:click={() =>
								pushGTMEvent(
									'/' +
										stringToSlug(slide.course.sessions[slide.course.idMainSession].category) +
										'/' +
										slide.course.sessions[slide.course.idMainSession].slug +
										'-' +
										slide.course.sessions[slide.course.idMainSession].idSession
								)}
						>
							<div class="hero-grid">
								<div class="hero-bg" class:hasMedia={false} />
								<div class="hero-content">
									<div class="hero-content-label">
										{#if slide.course.sessions[slide.course.idMainSession].lastSeats && !slide.course.sessions[slide.course.idMainSession].soldOut}
											<div class="course-slide-promo">
												<BadgeLatestSeats
													onBanner
													fullwidth
													session={slide.course.sessions[slide.course.idMainSession]}
												/>
											</div>
										{:else if slide.course.sessions[slide.course.idMainSession].promoDetailLabel}
											<div class="course-slide-promo">
												<BadgePromoDetails
													onBanner
													fullwidth
													session={slide.course.sessions[slide.course.idMainSession]}
													class="course-slide-promo"
												/>
											</div>
										{/if}
									</div>
									<div class="hero-content-block">
										<div class="hero-content-header">
											<p class="hero-course-type">
												{slide.course.courseTypeFull}
											</p>
											<h1 class="hero-course-title">
												{slide.course.title}
											</h1>
										</div>
										<div class="hero-content-body">
											<ul class="course-data">
												{#if isValidDate(slide.course.sessions[slide.course.idMainSession].startDate)}
													<li class="course-data-item">
														<span class="data-icon">
															<Icon icon="calendar" />
														</span>
														<span class="data-text">
															{dateFullMonthFormat(
																slide.course.sessions[slide.course.idMainSession].startDate
															)}
														</span>
													</li>
												{/if}
												{#if slide.course.sessions[slide.course.idMainSession].duration && slide.course.sessions[slide.course.idMainSession].durationType}
													<li class="course-data-item">
														<span class="data-icon">
															<Icon icon="duration" />
														</span>
														<span class="data-text">
															{#if slide.course.sessions[slide.course.idMainSession].stage && slide.course.sessions[slide.course.idMainSession].durationType == 'mesi' && (CourseTypeStore.getCourseTypeByCourseTypeName(slide.course.sessions[slide.course.idMainSession].courseTypeFull) == CourseType.CorsiIntensiviStage || CourseTypeStore.getCourseTypeByCourseTypeName(slide.course.sessions[slide.course.idMainSession].courseTypeFull) == CourseType.MasterFullTimeStage)}
																{slide.course.sessions[slide.course.idMainSession].duration - 6}
															{:else}
																{slide.course.sessions[slide.course.idMainSession].duration}
															{/if}
															{slide.course.sessions[slide.course.idMainSession].durationType}
															MESI DI STAGE
														</span>
														{#if slide.course.sessions[slide.course.idMainSession].stage && slide.course.sessions[slide.course.idMainSession].durationType == 'mesi' && (CourseTypeStore.getCourseTypeByCourseTypeName(slide.course.sessions[slide.course.idMainSession].courseTypeFull) == CourseType.CorsiIntensiviStage || CourseTypeStore.getCourseTypeByCourseTypeName(slide.course.sessions[slide.course.idMainSession].courseTypeFull) == CourseType.MasterFullTimeStage)}
															<span class="stage-plus"> + </span>
															<span class="data-text">6 MESI DI STAGE</span>
														{/if}
													</li>
												{/if}
												{#if slide.course.sessions[slide.course.idMainSession] && slide.course.sessions[slide.course.idMainSession].lessionMode}
													<li class="course-data-item full">
														<span class="data-icon">
															<Icon
																icon={lessonModeIcon(
																	slide.course.sessions[slide.course.idMainSession].lessionMode
																)}
															/>
														</span>
														<span class="data-text">
															{lessionModeText(
																slide.course.sessions[slide.course.idMainSession].lessionMode
															).toUpperCase()}
														</span>
														{#if lessonModeTooltip(slide.course.sessions[slide.course.idMainSession].lessionMode) !== ''}
															<span class="data-icon info">
																<div class="block md:hidden">
																	<Tooltip
																		icon={lessonModeIcon(
																			slide.course.sessions[slide.course.idMainSession].lessionMode
																		)}
																		title={lessionModeText(
																			slide.course.sessions[slide.course.idMainSession].lessionMode
																		)}
																		message={lessonModeTooltip(
																			slide.course.sessions[slide.course.idMainSession].lessionMode
																		)}
																	>
																		<Icon icon="info" size="middle" />
																	</Tooltip>
																</div>
																<div class="hidden md:block">
																	<TooltipDesktop
																		position="top-right"
																		icon={lessonModeIcon(
																			slide.course.sessions[slide.course.idMainSession].lessionMode
																		)}
																		title={lessionModeText(
																			slide.course.sessions[slide.course.idMainSession].lessionMode
																		)}
																		message={lessonModeTooltip(
																			slide.course.sessions[slide.course.idMainSession].lessionMode
																		)}
																	/>
																</div>
															</span>
														{/if}
													</li>
												{/if}
											</ul>
										</div>
										<div class="hero-content-footer">
											<!-- TODO: Replace  true with flag from contentful data  -->

											{#if !slide.buttonLink}
												<Button
													icon="download"
													color="pink"
													isLoading={loading}
													disabled={loading}
													on:click={() =>
														pushGTMClickEvent(
															'/' +
																stringToSlug(
																	slide.course.sessions[slide.course.idMainSession].category
																) +
																'/' +
																slide.course.sessions[slide.course.idMainSession].slug +
																'-' +
																slide.course.sessions[slide.course.idMainSession].idSession
														)}
												>
													<span>
														{slide.buttonText || 'go to course page'}
													</span>
												</Button>
											{:else if slide.course.sessions[slide.course.idMainSession].brochureAvailable && slide.course.sessions[slide.course.idMainSession].brochureUrl}
												<Button
													icon="download"
													color="pink"
													on:click={(event) => {
														event.preventDefault();
														event.stopPropagation();
														onDownloadBrochure(slide.course.sessions[slide.course.idMainSession]);
													}}
													isLoading={loading}
													disabled={loading}>Download Brochure</Button
												>
												<!-- TODO: Replace  true with flag from contentful data  -->
											{/if}

											{#if slide.priceView}
												<Price
													session={slide.course.sessions[slide.course.idMainSession]}
													hasVatFree={hasVatFreeSession(slide.course)}
												/>
											{/if}
										</div>
									</div>
								</div>

								{#if slide.course.sessions[slide.course.idMainSession].previewUrl}
									<div class="hero-media-wrapper">
										<img
											src={handleUrlClientSide(
												slide.course.sessions[slide.course.idMainSession].previewUrl
											)}
											alt={slide.course.sessions[slide.course.idMainSession].title}
											class="hero-media"
										/>
									</div>
								{/if}
							</div>
						</div>
					</section>
				</SliderSlide>
			{:else if slide}
				<SliderSlide type={slide.style === 'light' ? 'light' : 'dark'}>
					<Hero
						videoSrc={getVideoFile(slide).url}
						videoType={getVideoFile(slide).contentType}
						imageSrc={slide.image &&
							slide.image.fields &&
							slide.image.fields.file &&
							getURLFromContentfulAsset(slide.image)}
						imageAlt={slide.title}
						type={slide.style === 'light' ? 'light' : 'dark'}
						imageFullWidth={slide.imageFullWidth}
					>
						<h2 class="fs-3" slot="title">
							{slide.title}
							{#if slide.brushedTitle}
								<span class="text-brush">{slide.brushedTitle}</span>
							{/if}
						</h2>
						<p slot="text">
							{#if slide.caption && slide.caption.content[0] && slide.caption.content[0].content[0] && slide.caption.content[0].content[0].value}
								{#each slide.caption.content[0].content as slideContent}
									{slideContent.value}
								{/each}
							{/if}
						</p>
						<svelte:fragment slot="cta">
							{#if slide.ctaButton && slide.ctaButton.CTAActive}
								<div class="hidden md:block">
									<Button
										on:click={() => pushGTMEvent(slide.ctaButton.CTALink)}
										color={slide.style === 'light' ? 'pink' : 'white'}
									>
										{slide.ctaButton.CTAText}
									</Button>
								</div>
								<div class="md:hidden">
									<LinkTo
										on:click={() => pushGTMEvent(slide.ctaButton.CTALink)}
										href={slide.ctaButton.CTALink}
									>
										{slide.ctaButton.CTAText}
									</LinkTo>
								</div>
							{/if}
						</svelte:fragment>
						<svelte:fragment slot="cta-play">
							{#if slide.viewVideoButton && slide.media}
								<LinkPlayVideo
									videoSrc={getVideoFile(slide).url}
									videoType={getVideoFile(slide).contentType}
									color={slide.style === 'light' ? 'dark' : 'light'}
								>
									Guarda il video
								</LinkPlayVideo>
							{/if}
						</svelte:fragment>
					</Hero>
				</SliderSlide>
			{/if}
		{/each}
	</Slider>
</section>

<style type="text/scss" lang="scss" scoped>
	.hero-product {
		--hero-content-min-height: 14rem;
		--cta-play-space-around: 3.2rem;
		transition: opacity 0.1s ease-out;
		@apply relative;
		@apply md:h-[400px];
		opacity: 0.1;
		/*This is used as a delay to allow the FE to switch from the static slides to the user-customized ones without
    showing the static product slide while loading the customizations*/
		&.defaultIntroAnimation {
			opacity: 1 !important;
		}

		&.quickIntroAnimation {
			transition-duration: 0s;
			opacity: 1 !important;
		}

		.hero-content {
			@apply md:mb-4 md:mt-6;
		}

		.course-slide-promo {
			width: 100%;
			position: absolute;
			top: -10px;
		}
	}

	.hero-grid {
		@apply grid grid-cols-default;
		@apply gap-x-grid-default;
		@apply w-full;
		@apply md:page-margin md:page-max-width;
		@apply md:h-[400px];
	}

	.hero-bg {
		@apply hidden md:block;
		@apply absolute inset-0;
		@apply bg-tint;

		&.hasMedia {
		}
	}

	.hero-media-wrapper {
		@apply col-span-full;
		@apply relative;
		@apply md:col-start-4;
		@apply md:row-start-1;

		/** Fix aspect ratio plugin */
		--tw-aspect-w: 2;
		--tw-aspect-h: 1;
		padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);

		> * {
			@apply absolute w-full h-full;
			@apply inset-0;
		}

		@screen md {
			/* Remove aspect ratio */
			padding-bottom: 0;
			margin-right: calc(var(--page-margin) * -1);
		}
	}

	.hero-content-block .credits {
		position: absolute;
		right: 0;
		top: 15px;
		width: 90px;
		@apply flex h-6;
	}
	.hero-content-block .credits-text {
		@apply h-6 ml-2;
	}

	.hero-media {
		@apply object-cover object-center;
	}

	.hero-video {
		@apply absolute inset-0 object-cover w-full h-full;
	}

	.hero-action-wrapper {
		@apply flex items-end justify-start;
		@apply pointer-events-none;
	}

	.hero-action {
		@apply pointer-events-auto;
		@apply flex items-center;
		@apply pl-4 pb-4;
		@apply md:pl-6 md:pb-0;

		@screen md {
			height: var(--cta-play-space-around);
		}
	}

	.hero-course-type {
		@apply uppercase tracking-wide font-semibold;
		@apply text-gray-600 text-sm md:text-base;
	}

	.hero-course-title {
		@apply text-xl md:text-4xl leading-snug;
		@apply text-gray-900 font-medium font-serif;
	}

	.hero-course-subtitle {
		@apply text-black text-sm md:text-md font-medium text-opacity-60 tracking-wide mt-3 font-serif;
	}

	.hero-course-type + .hero-course-title {
		@apply mt-3;
	}

	.hero-course-title + .hero-course-subtitle {
		@apply mt-3;
	}

	.hero-content {
		@apply relative;
		@apply col-span-full z-1;
		@apply row-start-2;
		@apply md:mt-8;
		@apply md:row-start-1 md:col-start-1 md:col-span-7;
		lg: col-span-8;
		@apply md:self-center;

		@screen md {
			margin-bottom: var(--cta-play-space-around);
		}
	}

	.hero-content-badge {
		@apply md:absolute;
		@apply md:transform md:-translate-y-full;
	}

	.hero-content-block {
		@apply flex flex-col;
		@apply page-margin py-6;
		@apply bg-white;
		@apply md:px-6;
		@apply md:justify-between;

		@screen md {
			min-height: var(--hero-content-min-height);
		}
	}

	.hero-content-footer {
		display: flex;
		@apply mt-6;
		@apply mb-8;

		@screen lg {
			flex-direction: row;
		}
	}

	.hero-course-brush {
		@apply inline-block relative -mt-4;
	}

	.course-data {
		/* Space between list items */
		--space-x: theme('spacing.8'); /* 32px */
		--space-y: theme('spacing.4'); /* 20px */

		@apply flex flex-wrap;
		/* Trick to re-create flex-gap for browsers that don't support it */
		margin-left: calc(var(--space-x) * -1);
		margin-top: calc(var(--space-y) * -1);
	}

	.course-data-item {
		@apply flex items-center;
		@apply uppercase tracking-wide text-xs md:text-sm text-gray-900;
		margin-left: var(--space-x);
		margin-top: var(--space-y);
	}

	.data-icon {
		@apply inline-flex flex-shrink-0 mr-2;

		&.info {
			@apply flex;
			@apply ml-2;
			@apply text-courses-default;
			@apply cursor-pointer;
		}
	}

	.data-text {
		@apply flex-shrink-0;
	}
</style>
