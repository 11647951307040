<script lang="ts">
	import '$lib/styles/index.scss';
	import { page } from '$app/stores';
	import { useCampaignStore } from '$lib/utils/campaign-store';
	import { useCookiesStore } from '$lib/utils/cookie-store';
	import { useSessionStore } from '$lib/utils/session-store';
	import { browser } from '$app/environment';
	import { onDestroy, onMount } from 'svelte';
	import { get } from 'svelte/store';
	import { useCTAFlow } from '$lib/utils/cta-flow';
	import { handleFilters } from '$lib/utils/filters/helpers';
	import { FilterStore } from '$lib/utils/filters/store';
	import { pageOptionsStore } from '$lib/utils/layout-store';
	import { StructureUtils } from '$lib/utils/structured-utils';
	import { useOktaStore } from '$lib/utils/auth-store';
	import { GtmStore } from '$lib/utils/gtm-store';
	import { closeModal } from '$lib/utils/modals';
	import { PopupUtils } from '$lib/utils/popup-utils';
	// Components
	import { CookieBar, Gtm, Datadog } from '$lib/components/atoms/Tracking';
	import { Navbar } from '$lib/components/organisms/Layout';
	import { NavbarLogo } from '$lib/components/atoms/NavbarLogo';
	import { Header, HeaderLanding, HeaderModal } from '$lib/components/organisms/Header';
	import { Footer, FooterLanding } from '$lib/components/organisms/Footer';
	import { Modals } from '$lib/components/organisms/Modal';
	import { ApplyOreintationModal } from '$lib/components/organisms/Orientation';
	import { PUBLIC_DATADOG_TRACKING_ENABLED } from '$env/static/public';

	export let segment: string | undefined = ''; // TODO: check if we need to use this
	// const COOKIE_VERSION_NAME = "24orev";

	// let clientVersion = "";
	// let newVersionAvailable = false;
	let versionInterval = 0;
	let ctaError = false;
	let abCtaOrientamento: string = 'A';

	const authStore = useOktaStore();
	const sessionStore = useSessionStore();
	const cookiesStore = useCookiesStore();
	const filterStore = FilterStore.getInstance();
	const ctaFlow = useCTAFlow();
	ctaFlow.setErrorCallback(() => {
		ctaError = true;
	});

	GtmStore.initGtmStore(authStore, sessionStore, cookiesStore, page);

	$: cookies = cookiesStore?.cookies;

	const removePathId = (str: string) => {
		if (str) {
			if (str.includes('news') || str.includes('eventi')) {
				return str;
			}
			const pathData = str.split('-');
			const pathId = pathData[pathData.length - 1];
			if (parseInt(pathId) && parseInt(pathId) > 0) {
				return str.substring(0, str.length - pathId.length - 1);
			} else return str;
		}
	};

	onMount(async () => {
		authStore.start();

		if (typeof window !== 'undefined') {
			const searchParams = new URLSearchParams(window.location.search);
			const email = searchParams.get('email');
			const channel = searchParams.get('channel');

			if (email && channel) {
				sessionStore.setLoginOptions({ email, channel });
			}
		}

		/****************************************FORCING RELOAD EVERY 1day*********************************************
		 * This is a watchdog for end users. If we broke a deploy we can be almost sure the page is reloading after 1h
		 *
		 * ****************************************FORCING RELOAD EVERY 1day*******************************************/
		window.setTimeout(
			async () => {
				try {
					if (typeof window !== 'undefined' && window.location.hostname === 'localhost') {
						return;
					}
					try {
						window.location.reload();
					} catch (e) {}
					try {
						location.reload();
					} catch (e) {}
				} catch (e) {
					//Nothing to do
				}
			},
			60 * 60 * 24 * 1000
		);
		/****************************************END FORCING RELOAD EVERY 1day*********************************************/

		//Detect a new deploy

		// versionInterval = window.setInterval(async () => {
		//   checkVersionCookie();
		// }, 30 * 1000);

		// reset fixed header on route change
		// reset body style on route change

		//ab_cta_orientamento read value from local storage
		if (typeof window != 'undefined') {
			abCtaOrientamento = localStorage.getItem('ab_cta_orientamento') || 'A';
		}
	});

	const unsubscribePageTracker = page.subscribe((page) => {
		const previousState = get(pageOptionsStore);

		if (typeof window !== 'undefined') {
			const campaignStore = useCampaignStore();
			campaignStore.parseAndUpdateCampaignData(page.params);
			campaignStore.parseAndStoreFirstClickCampaignData(page.params);
		}

		// if (
		//   !page?.url?.pathname?.startsWith("/esplora") &&
		//   !page?.url?.pathname?.startsWith("/docenti") &&
		//   !page?.url?.pathname?.startsWith("/parters")
		// ) {
		//   const filters = handleFilters(page);
		//   console.log("filters", filters);
		//   filterStore.setFilters(filters);
		// }

		if (page.url.pathname !== previousState.path) {
			pageOptionsStore.set({
				modalCloseCallback: (): Promise<boolean> => {
					return new Promise<boolean>((resolve) => {
						resolve(true);
					});
				},
				headerFixed: true,
				headerModal: false,
				headerLanding: false,
				footerVisible: true,
				footerLanding: false,
				applyOrientationModalVisible: true,
				bodyStyle: undefined,
				path: page.url.pathname,
				referrer: previousState.path
			});
		}
	});

	onDestroy(() => {
		clearInterval(versionInterval);
		authStore.stop();
		// unsubscribe route tracker! imprtant to make it working!
		unsubscribePageTracker();
	});

	// update body bg style
	$: {
		if (typeof window !== 'undefined') {
			if ($pageOptionsStore.bodyStyle === 'gray') {
				document.body.classList.add('bg-gray-50');
			} else {
				document.body.classList.remove('bg-gray-50');
			}
		}
	}

	const searchBoxJson = StructureUtils.getSearchBoxStructure(true);
	const logoJson = StructureUtils.getLogoStucture(true);

	let ldScriptTag = '';

	if (!browser) {
		try {
			ldScriptTag = StructureUtils.createScriptTag(JSON.stringify([searchBoxJson, logoJson]));
		} catch (e) {
			ldScriptTag = '';
		}
	}

	const datadogTrackingEnabled = PUBLIC_DATADOG_TRACKING_ENABLED === 'true';
</script>

<svelte:head>
	<Gtm />

	{#if datadogTrackingEnabled}
		<Datadog />
	{/if}
</svelte:head>

{#if $page.url.pathname.startsWith('/app')}
	<Navbar fixed={true} fluid={false}>
		<NavbarLogo logoWithLink={false} />
	</Navbar>
{:else if $pageOptionsStore.headerModal}
	<HeaderModal fixed={true} />
{:else if $pageOptionsStore.headerLanding}
	<HeaderLanding fixed={$pageOptionsStore.headerFixed} />
{:else}
	<Header {segment} fixed={$pageOptionsStore.headerFixed} />
{/if}

<main>
	<slot />
</main>

{#if !$cookies.cookie_notice_accepted && !$page.url.pathname.startsWith('/app')}
	<CookieBar />
{/if}

{#if (abCtaOrientamento === 'B' || abCtaOrientamento === 'D') && $pageOptionsStore.applyOrientationModalVisible && PopupUtils.isPopupExpired() && $pageOptionsStore.footerVisible}
	<ApplyOreintationModal />
{/if}

{#if $pageOptionsStore.footerVisible}
	{#if $pageOptionsStore.footerLanding}
		<FooterLanding />
	{:else}
		<Footer />
	{/if}
{/if}

<Modals>
	<div
		slot="backdrop"
		class="backdrop"
		on:click={closeModal}
		on:keydown={closeModal}
		role="button"
		tabindex="0"
	/>
</Modals>
<section class="modals" id="modals" />

<style lang="scss" scoped>
	:global(body) {
		@apply bg-gray-50;
	}
</style>
