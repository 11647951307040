<script lang="ts">
  import { Button } from '$lib/components/atoms/Button';
  import { TextField, Checkbox, CustomSelect } from '$lib/components/atoms/Field';

  import { useOktaStore } from '$lib/utils/auth-store';
  import { useSessionStore } from '$lib/utils/session-store';
	import { GtmStore } from "$lib/utils/gtm-store";

  import { onMount, createEventDispatcher } from 'svelte';
	import { field, form } from "svelte-forms";
	import { get } from "svelte/store";
  import { slide } from 'svelte/transition';
  import {
    required as requiredValidation,
    email as emailValidation,
    pattern as patternValidation,
  } from 'svelte-forms/validators';

  import { ContactUtils } from '$lib/utils/contact-utils';
	import type { CallmebackData, CallmebackResults } from '$lib/utils/contact-utils/types';

  export let isFromBorsaDiStudio = false;

  const dispatch = createEventDispatcher();

  const sessionStore = useSessionStore();
  const authStore = useOktaStore();
  const userInfo = authStore.userInfo;
  const pendingUserInfo = sessionStore.pendingUserInfo;

  const name = field(
    'name',
    $userInfo?.name || $pendingUserInfo?.name || '',
    [requiredValidation()],
    { valid: false }
  );
  const surname = field(
    'surname',
    $userInfo?.surname || $pendingUserInfo?.lastname || '',
    [requiredValidation()],
    { valid: false }
  );
  const email = field(
    'email',
    $userInfo?.email || $pendingUserInfo?.email || '',
    [emailValidation()],
    { valid: false }
  );
  const phone = field(
    'phone',
    $userInfo?.phone || $pendingUserInfo?.phone || '',
    [requiredValidation(), patternValidation(/^[0-9]+$/)],
    { valid: false }
  );
  const day = field(
    'day',
    null,
    [requiredValidation()],
    { valid: false }
  );
  const hour = field(
    'hour',
    null,
    [requiredValidation()],
    { valid: false }
  );
  const subscribe = field(
    'subscribe',
    $userInfo?.subscribe || $pendingUserInfo?.subscribe || false,
    [],
    { valid: false }
  );

  const callBooking = form(name, surname, email, phone, day, hour, subscribe);

  let isSubmitting = false;
  let availableDays: CallmebackResults;

  onMount(async () => {
    availableDays = await ContactUtils.getAvailableDays(fetch);

    //Forcing only first available day:
    availableDays.nextDays = [availableDays.nextDays[0]];
  });

  const handleSubmit = async () => {
    if (isSubmitting) {
      return;
    }
    
    await callBooking.validate();

    if ($callBooking.valid) {
      isSubmitting = true;
      
      const callBookingReq: CallmebackData = {
        name: $name.value,
        surname: $surname.value,
        email: $email.value,
        phone: $phone.value,
        day: new Date(parseInt($day.value)).toISOString(),
        hour: $hour.value,
        subscribe: $subscribe.value
      };

      try {
        const response = await ContactUtils.sendData(callBookingReq);

        if (response.success) {
          callBooking.reset();
          const isLoggedIn = get(authStore.isLoggedIn);
          const userInfo = get(authStore.userInfo);
          // If user is logged in update with the values that he set
          if (isLoggedIn) {
            await authStore.updateUserProfile({
              ...userInfo,
              name: name,
              surname: surname,
              phone: phone,
              subscribe: subscribe,
            });
          }

          /******************** GTM EVENT PUSH *************************/
          GtmStore.pushEvent(
              {
                event: 'BBevent',
                category: 'Chiamata',
                action: 'Prenotata',
                label: '',
                event_id: '8',
              },
              false
            );
            if (isFromBorsaDiStudio) {
              GtmStore.pushEvent(
                {
                  event: 'pageview',
                  category: 'Prenota chiamata_conferma',
                },
                false
              );
            }
          /*************************************************************/
        } else {
          dispatch('feedbackCallBooking', { success: false });
        }
      } catch (error) {
        dispatch('feedbackCallBooking', { success: false });
      } finally {
        dispatch('feedbackCallBooking', { success: true });
        isSubmitting = false;
      }
    }
  };
</script>

<form class="call-booking-form" on:submit|preventDefault={handleSubmit}>
  <TextField
    class="col-span-full md:col-span-3"
    name="name"
    id="name"
    type="text"
    label="Nome"
    disabled={isSubmitting}
    autocomplete="given-name"
    bind:value={$name.value}
    required
    error={
      $callBooking.hasError('name.required')
        ? 'Inserisci il tuo nome'
        : null
    }
  />
  <TextField
    class="col-span-full md:col-span-3"
    name="last-name"
    id="last-name"
    type="text"
    label="Cognome"
    disabled={isSubmitting}
    autocomplete="family-name"
    bind:value={$surname.value}
    required
    error={
      $callBooking.hasError('surname.required')
        ? 'Inserisci il tuo cognome'
        : null
    }
  />
  <TextField
    class="col-span-full md:col-span-3"
    name="email"
    id="email"
    type="email"
    label="Email"
    disabled={isSubmitting}
    bind:value={$email.value}
    autocomplete="email"
    required
    error={
      $callBooking.hasError('email.not_an_email')
        ? 'L’indirizzo e-mail non risulta valido, controlla l’indirizzo inserito'
        : null
    }
  />
  <TextField
    class="col-span-full md:col-span-3"
    name="phone"
    id="phone"
    type="number"
    label="Telefono"
    disabled={isSubmitting}
    bind:value={$phone.value}
    autocomplete="tel"
    required
    error={
      $callBooking.hasError('phone.required')
        ? 'Inserisci il tuo numero di telefono'
        : null
    }
  />
  <CustomSelect
    class="col-span-full md:col-span-3"
    name="day"
    id="day"
    label="Data"
    disabled={isSubmitting}
    bind:value={$day.value}
    options={(availableDays?.nextDays || []).map((day) => ({
      value: String(day.day),
      label: ContactUtils.toFormattedDate(day.day),
    }))}
    required
    error={
      $callBooking.hasError('day.required')
        ? 'Selezione una data'
        : null
    }
  />
  {#if $day.value}
    <div in:slide>
      <CustomSelect
        class="col-span-full md:col-span-3"
        name="hour"
        id="hour"
        label="Orario"
        disabled={isSubmitting}
        bind:value={$hour.value}
        options={ContactUtils.getAvailableHoursInDay(availableDays, parseInt($day.value)).map((hour) => ({
          label: ContactUtils.toHour(hour),
          value: String(hour),
        }))}
        required
        error={
          $callBooking.hasError('hour.required')
            ? 'Selezione un orario'
            : null
        }
      />
    </div>
  {/if}
  <Checkbox
    class="col-span-full"
    disabled={isSubmitting}
    bind:checked={$subscribe.value}
  >
    Attivando questa opzione, do il consenso a Digit’Ed S.p.A. al trattamento dei miei dati di contatto per
    finalità di marketing e comunicazione pubblicitaria, su iniziative promozionali, come invio di brochure, coupon
    sconto e promozioni.
    <a href="/privacy-policy">Leggi l'informativa</a>
  </Checkbox>

  <div class="col-span-full mt-8 text-center">
    <Button 
      type="submit" 
      isLoading={isSubmitting}
      disabled={isSubmitting}
    >
      Prenota
    </Button>
  </div>
</form>
