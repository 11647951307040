/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CheckoutCartRequest = {
    redirectUrl?: string;
    uiMode?: CheckoutCartRequest.uiMode;
    /**
     * A user cart
     */
    cart?: {
        /**
         * A product
         */
        product: {
            /**
             * The product code
             */
            code: string;
            /**
             * The product name
             */
            name: string;
        };
        /**
         * The list of options eventually selected by the user
         */
        options?: Array<{
            /**
             * The Option code
             */
            code: string;
            /**
             * The Option name
             */
            name: string;
        }>;
        promoCode?: string;
    };
    /**
     * A customer
     */
    customer?: {
        address?: {
            country: string;
            province?: string;
            city: string;
            street: string;
            postalCode: string;
        };
        surname?: string;
        pecEmail?: string;
        taxId?: string;
        companyName?: string;
        name?: string;
        sdi?: string;
        email: string;
        vatNumber?: string;
    };
};
export namespace CheckoutCartRequest {
    export enum uiMode {
        HOSTED = 'hosted',
        EMBEDDED = 'embedded',
    }
}

