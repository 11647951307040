import type { SessionModel } from '@24hbs/utils';
import { useCampaignStore } from '$lib/utils/campaign-store';
import {
  B2B_FONDO_NUOVE_COMPETENZE,
  B2B_FORMAZIONE_INTERAZIENDALE_A_CATALOGO,
  B2B_FORMAZIONE_SU_MISURA,
  B2B_HR_FOR_BREAKFAST,
  B2B_LE_MASTERCLASS
} from '$lib/utils/custom-courses';
import { useSessionStore } from '$lib/utils/session-store';
import { get } from 'svelte/store';
import type {
  BuyCTAData,
  DownloadBrochureCTAData,
  RequestInfoCTAData,
  SendApplicationFormCTAData,
  UserWebsiteData,
} from './types';
import type { Page } from '@sveltejs/kit';
import { useOktaStore } from '../auth-store';
import { useCtaServicesApiClient } from "$lib/api/CtaServicesApiClientFactory";
import { fromCampaignDataTypesToWebsiteSessionDataTypes } from '../cta-services-mapper-utils';
import type { GtmEventData } from '../gtm-store/types';
import { GtmStore } from '../gtm-store';
import { sha256Hex } from '../formatter';
import type { SubmitAdmissionRequestResponse } from '$lib/api/generated/cta-services/models/SubmitAdmissionRequestResponse';
import type { SubmitDownloadBrochureResponse } from '$lib/api/generated/cta-services/models/SubmitDownloadBrochureResponse';
import type { BookEventResponse } from '$lib/api/generated/cta-services/models/BookEventResponse';
import type { SubmitOrientationRequestResponse } from '$lib/api/generated/cta-services/models/SubmitOrientationRequestResponse';
import type { SignupResponse } from '$lib/api/generated/cta-services/models/SignupResponse';
import type { SubmitScholarshipRequestResponse } from '$lib/api/generated/cta-services/models/SubmitScholarshipRequestResponse';
import type { SubmitInformationRequestResponse } from '$lib/api/generated/cta-services/models/SubmitInformationRequestResponse';
import type { IInitiatives } from '$lib/utils/contentful/types';

const getUserAndWebsiteData = (): UserWebsiteData => {
  const userInfo = get(useOktaStore().userInfo);
  const pendingUserInfo = get(useSessionStore().pendingUserInfo);
  const companyStatus = get(useSessionStore().companyStatus);


  const campaignStore = useCampaignStore();
  const campaignData = get(campaignStore.campaignData);
  const webid = get(campaignStore.webid);
  return {
    id: userInfo?.id || pendingUserInfo?.id || '',
    bsid: userInfo?.bsid || pendingUserInfo?.bsid || '',
    email: userInfo?.email || pendingUserInfo?.email || '',
    name: userInfo?.name || pendingUserInfo?.name || '',
    surname: userInfo?.surname || pendingUserInfo?.lastname || '',
    phone: userInfo?.phone || pendingUserInfo?.phone || '',
    consent1: userInfo?.subscribe || pendingUserInfo?.subscribe || false,
    consent2: userInfo?.businessUseConsent || pendingUserInfo?.businessUseConsent || false,
    consent3: userInfo?.profilingConsent || pendingUserInfo?.profilingConsent || false,
    referrer: campaignData?.referrer || '',
    utmCampaignSource: campaignData?.utm_source || '',
    utmCampaignMedium: campaignData?.utm_medium || '',
    utmCampaignName: campaignData?.utm_campaign || '',
    utmCampaignTerm: '',
    utmCampaignContent: '',
    fbclid: campaignData?.fbclid || '',
    gclid: campaignData?.gclid || '',
    gclsrc: campaignData?.gclsrc || '',
    websiteSessionData: {
      referrer: campaignData?.referrer || '',
      utmCampaignSource: campaignData?.utm_source || '',
      utmCampaignMedium: campaignData?.utm_medium || '',
      utmCampaignName: campaignData?.utm_campaign || '',
      utmCampaignTerm: '',
      utmCampaignContent: '',
      fbclid: campaignData?.fbclid || '',
      gclid: campaignData?.gclid || '',
      gclsrc: campaignData?.gclsrc || '',
      type: fromCampaignDataTypesToWebsiteSessionDataTypes(campaignData?.type),
      webid: webid || undefined,
    },
    b2b: companyStatus,
    device: campaignData?.device,
  };
};

const buildQueryString = (parameters: Record<string, string>) => {
  return Object.keys(parameters)
    .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(<string>parameters[k])}`)
    .join('&');
};

/**
 * Build the shopify checkout permalink from given details
 *
 * @param bsid The user unique id
 * @param email The user unique email
 * @param name The user name
 * @param surname The user surname
 * @param phone The user phone number
 * @param productCode The product code
 * @param options The list of options eventually selected by the user
 *
 * @returns The shopify permalink
 */
const buildShopifyLink = async (
  bsid: string,
  email: string,
  name: string,
  surname: string,
  phone: string,
  productCode: string,
  options?: string[]
): Promise<string> => {
  const client = useCtaServicesApiClient();
  const result = await client.checkoutShopify({
    email: email,
    name: name,
    surname: surname,
    bsid: bsid.startsWith('BS') ? bsid : `BS${bsid}`,
    productCode: productCode,
    options: options ? options : undefined,
    phone: phone,
  });

  if (!result || !result.multipassUrl) {
    throw new Error('Invalid response from the server');
  }

  return result.multipassUrl;
};

const buildBrochureConfirmationPath = (coursePath: string, type: 'booking' | 'download', sessionId: number, productCode?: string) => {
  //console.log({productCode});
  if (productCode === B2B_FONDO_NUOVE_COMPETENZE.model.sessions[B2B_FONDO_NUOVE_COMPETENZE.model.idMainSession].productCode) {
    return B2B_FONDO_NUOVE_COMPETENZE.downloadBrochureConfirmationPath;
  }
  if (productCode === B2B_FORMAZIONE_INTERAZIENDALE_A_CATALOGO.model.sessions[B2B_FORMAZIONE_INTERAZIENDALE_A_CATALOGO.model.idMainSession].productCode) {
    return B2B_FORMAZIONE_INTERAZIENDALE_A_CATALOGO.downloadBrochureConfirmationPath;
  }
  if (productCode === B2B_FORMAZIONE_SU_MISURA.model.sessions[B2B_FORMAZIONE_SU_MISURA.model.idMainSession].productCode) {
    return B2B_FORMAZIONE_SU_MISURA.downloadBrochureConfirmationPath;
  }
  if (productCode === B2B_HR_FOR_BREAKFAST.model.sessions[B2B_HR_FOR_BREAKFAST.model.idMainSession].productCode) {
    return B2B_HR_FOR_BREAKFAST.downloadBrochureConfirmationPath;
  }
  if (productCode === B2B_LE_MASTERCLASS.model.sessions[B2B_LE_MASTERCLASS.model.idMainSession].productCode) {
    return B2B_LE_MASTERCLASS.downloadBrochureConfirmationPath;
  }
  return `${coursePath}/${sessionId}/${type}_brochure`;
};

const requestHasUserRequiredFields = (request: any): boolean => {
  return request.name && request.phone && request.email && request.consent1;
};

const ctaRequestBuilders = {
  downloadBrochure: (session: SessionModel, page: Page, customValues: any = {}, customPath: string | null = null): DownloadBrochureCTAData => {
    return {
      productCode: session.productCode,
      category: session.category,
      courseTypeFull: <string>session.courseTypeFull,
      courseTitle: session.title,
      price: session.priceNoVat,
      discountPrice: session.discountPrice,
      lessionMode: session.lessionMode,
      sessionId: session.idSession,
      brochureAvailable: session.brochureAvailable,
      brochureBooking: session.brochureBooking,
      brochureUrl: session.brochureUrl,
      courseUrl: !customPath ? `${page.url.origin}/formazione/${session.slug}/${session.idSession}` : `${page.url.origin}/${customPath}`,
      courseHost: `${page.url.origin}`,
      coursePath: !customPath ? `/formazione/${session.slug}` : customPath,
      courseReferrerName:
        session.sessionContacts && session.sessionContacts.editorialContact ? session.sessionContacts.editorialContact : '',
      courseReferrerEmail:
        session.sessionContacts && session.sessionContacts.editorialEmail ? session.sessionContacts.editorialEmail : '',
      endDate: session.endDate,
      startDate: session.startDate,
      rank: session.ranking,
      ...customValues
    };
  },
  sendApplicationForm: (session: SessionModel, customPath: string | null = null): SendApplicationFormCTAData => {
    return {
      productCode: session.productCode,
      sessionId: session.idSession.toString(),
      coursePath: !customPath ? `/formazione/${session.slug}` : customPath,
    };
  },
  requestInfo: (session: SessionModel, customPath: string | null = null): RequestInfoCTAData => {
    return {
      productCode: session.productCode,
      sessionId: session.idSession.toString(),
      coursePath: !customPath ? `/formazione/${session.slug}` : customPath,
    };
  },
  buy: (session: SessionModel, customPath: string | null = null): BuyCTAData => {
    return {
      sessionId: session.idSession,
      upselling: session.packages && session.packages.length > 0 && session.options.length > 0,
      upsellingHasPackages: session.packages && session.packages.length > 1 && session.options.length > 0,
      productCode: session.productCode,
      coursePath: !customPath ? `/formazione/${session.slug}` : customPath,
      category: session.category,
      endDate: session.endDate,
      startDate: session.startDate,
      rank: session.ranking,
    };
  },
  bookInitiative: (initiative: IInitiatives, slug: string) => {

    if (!initiative) {
      throw new Error('Initiative is required');
    }

    if (!slug) {
      throw new Error('Slug is required');
    }

    return {
      initiativePath: `/eventi/${slug}`,
      bookingConfig: {
        useCta: initiative.fields.bookingConfig.useCta,
        url: initiative.fields.bookingConfig.url,
        title: initiative.fields.title,
        date: initiative.fields.date,
        type: initiative.fields.type,
        sessionId: initiative.fields.bookingConfig.sessionId,
        courseId: initiative.fields.bookingConfig.courseId,
      },
    };
  },
};

type AwinCtaResponse =
  | SubmitAdmissionRequestResponse
  | SubmitDownloadBrochureResponse
  | BookEventResponse
  | SubmitOrientationRequestResponse
  | SignupResponse
  | SubmitScholarshipRequestResponse
  | SubmitInformationRequestResponse;

const pushCtaEvent = (response: AwinCtaResponse, type: GtmEventData['type']) => {

  const body = response.event && response.event.body ? response.event.body : response;
  const gtmRequest = {
    event: 'AwinBBevent',
    cta_uid: response.uid,
    type: type,
    category: type,
    session_value: body.discountPrice ? body.discountPrice : body.price,
    course_title: body.courseTitle,
    course_type: body.courseTypeFull,
    course_thematic_area: body.category,
    session_code: body.sessionId,
    user_bsid: body.bsid,
    user_hashed_email: body.email ? sha256Hex(body.email) : undefined
  }

  GtmStore.pushEvent(
    gtmRequest,
    false
  );
}

export const createCtaEvent = (type: GtmEventData['type']) => {
  return (response: AwinCtaResponse) => {
      pushCtaEvent(response, type);
  };
}


export {
  getUserAndWebsiteData,
  buildQueryString,
  buildShopifyLink,
  buildBrochureConfirmationPath,
  requestHasUserRequiredFields,
  ctaRequestBuilders,
};
