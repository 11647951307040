import type { CourseLightList } from "$lib/utils/course-utils";

export enum ListingPage {
  // by course type
  CorsiExecutive,
  CorsiOnline,
  ExecutiveMaster,
  MasterPostLaureaConStage,
  Masterclass,
  ProfessionalMaster,
  MasterPartTime,
  MBA,
  ProfessionalMasterCFU,
  CorsiEuroconference,
  
  All
}
export interface IListingPageDetails {
  gtm: {
    pageCategory: string;
  };
  title: string;
  description: string;
}
export interface IListingPageListCourses {
  types: CourseLightList[];
}
export interface ITopCoursesByListingPage {
  bestSellerCourses: IListingPageListCourses;
}