import type { Readable } from 'svelte/store';
import type { Page } from '@sveltejs/kit';
import type { IdpState } from '../state';

/**
 * The configuration for the Okta authentication store.
 */
export interface IOktaAuthConfig {
  issuer: string;
  domain: string;
  clientId: string;
  redirectUri: string;
  loginUri: string;

  /**
   * Optional callback function to be called every time the user is updated.
   * @returns The promise resolved when callback is completed.
   */
  onAuthUpdatedCallbackFn?: OnAuthUpdatedCallbackFn;
  /**
   * Optional callback function to be called every time the user signs up.
   */
  onSignupCallbackFn?: OnSignupCallbackFn;

  /**
   * Callback function to be called every time the user is activated.
   */
  onActivateFn: OnActivateFn;

  /**
   * Callback function to be called when updating the user profile.
   */
  onUpdateUserProfileFn: OnUpdateUserProfileFn;

  /**
   * Callback function to be called every time the user signs out.
   */
  onSignoutCallbackFn?: OnSignoutCallbackFn;

};

/**
 * The callback function to be called every time the user is updated.
 */
export type OnAuthUpdatedCallbackFn = () => Promise<void> | void;

/**
 * The callback function to be called after the user signs up.
 */
export type OnSignupCallbackFn = (userInfo: IUserInfo) => Promise<void>;

/**
 * The function to be called before the activation.
 */
export type OnActivateFn = (token: string, password: string) => Promise<{ username: string, password: string }>

/**
 * The function to be called before the user profile is updated.
 */
export type OnUpdateUserProfileFn = (newUserInfo: IUserInfo) => Promise<IUserInfo>

/**
 * The function to be called after the user signs out.
 */
export type OnSignoutCallbackFn = () => Promise<void>;

/**
* Generic user info interface.
*/
export interface IUserInfo {
  /**
  * The unique identifier of the user
  */
  bsid: string,
  /**
  * The unique identifier of the user
  */
  id: string,
  /**
  * The name of the user
  */
  name: string,
  /**
  * The surname of the user
  */
  surname: string,
  /**
  * The full name of the user
  */
  fullname: string,
  /**
  * The phone number of the user
  */
  phone?: string,
  /**
  * The mail of the user
  */
  email: string,
  /**
  * True if the user allows to subscribe to ads and newsletters
  */
  subscribe: boolean,
  /**
  * True if the user allows to use personal infos for business uses
  */
  businessUseConsent: boolean,
  /**
  * True if user allows to use personal infos for profiling
  */
  profilingConsent: boolean,
  /**
  * The birthday date (YYYYMMDD)
  */
  birthday?: string,
  /**
  * The city associated to address
  */
  city?: string,
  /**
  * The address
  */
  address?: string,
  /**
  * The postal code associated to address
  */
  postalCode?: string,
  /**
  * The name of the organization
  */
  organization?: string,
  /**
  * The division/department in organization
  */
  sector?: string,
  /**
  * The role in organization
  */
  function?: string
  /**
  * Graduation informations
  */
  graduation?: IGraduation | null
}

/**
* The graduation
*/
export interface IGraduation {
  /**
  * Graduation name
  */
  name: string | null
  /**
  * Graduation level
  */
  type: GraduationType | null
  /**
  * Graduation vote
  */
  vote: string | null
}

/**
* The type of graduation (italian system)
*/
export enum GraduationType {
  /**
  * First level degree
  */
  FIRST_LEVEL = "1",
  /**
  * Second level degree
  */
  SECOND_LEVEL = "2",
  /**
  * Old system (< year 2001)
  */
  OLD_SYSTEM = "Vecchio ordinamento"
}

/**
* 
*/
export enum AuthErrorStatus {
  WRONG_PASSWORD,
  LOCKED_OUT,
  PASSWORD_WARN,
  PASSWORD_EXPIRED,
}

/**
* Authentication error reported by IAuthStore
*/
export class AuthError extends Error {
  private _status: AuthErrorStatus;
  private _inner: any | null;

  /**
  * Construct a new authentication error.
  * 
  * @param message The message.
  * @param status  The status.
  * @param inner The inner error object.
  */
  public constructor(message: string, status: AuthErrorStatus, inner?: any) {
    super(message)
    this._status = status;
    this._inner = inner;
  }

  public get status(): AuthErrorStatus {
    return this._status;
  }

  public get inner(): any {
    return this._inner;
  }
}

/**
* Generic authentication store interface.
*/
export interface IAuthStore {
  /**
  * Start the auth store
  */
  start(): void,
  /**
  * Stop the auth store
  */
  stop(): void,
  /**
  * Get the current user status
  */
  readonly isLoggedIn: Readable<boolean>,
  /**
  * Get the current user profile
  */
  readonly userInfo: Readable<IUserInfo | null>,

  /**
   * Complete the user activation with given token.
   * 
   * @param token The activation token.
   * @param password The password to be set.
   * 
   * @returns True on successful sign in.
   */
  signInWithToken(token: string, password: string): Promise<boolean>


  /**
  * Sign in user.
  * 
  * @param username The username.
  * @param password The password.
  * 
  * @throws AuthError
  */
  signIn(username: string, password: string): Promise<void>

  /**
  * Sing in the user using given idp
  * 
  * @param idp The idp unique identifier.
  * @param redirect True to redirect, false to use popup.
  * @param callback The optional callback URL to encode in state passed to IdP (so it gets propagated)
  * @param callback The optional cta action to encode in state passed to IdP (so it gets propagated)
  * 
  * 
  * @returns A promise resolved when user get logged in.
  *
  * @throws An error if a login error occurs.
  */
  signInWithIdp(idp: string, redirect: boolean, callback?: string, cta?: string, isSocialLogin?: boolean): Promise<void>

  /**
  * Trigger forgot password 
  * 
  * @param username The username (with or without domain) used to send reset password link.
  * 
  * @throws AuthError
  */
  forgotPassword(username: string): Promise<void>


  /**
  * Trigger account unlock
  * 
  * @param username The username (with or without domain) used to send the unlock link.
  * 
  * @throws AuthError
  */
  unlock(username: string): Promise<void>

  /**
  * Logout the user (if logged in).
  * 
  * @return A promise resolved whe operation is completed.
  */
  signOut(): Promise<void>


  /**
  * Handles the login redirect call
  * 
  * @param page The calling page
  * @param callback The callback function to be called after login
  */
  handleLoginRedirect(page: Readable<Page>): Promise<IdpState | undefined>


  /**
  * Update the user profile.
  * @param userInfo The updated user infos.
  */
  updateUserProfile(userInfo: IUserInfo): Promise<IUserInfo>;


  /**
   * Retrieve user details
   */
  retrieveUser(): Promise<void>


  /**
  * Get the current access token
  * 
  * @returns The access token to use to call resource servers 
  */
  readonly accessToken: string | null;

}
