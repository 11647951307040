<script lang="ts">
  import {SearchFilterStore} from "$lib/utils/searchfilter-store";
	import { SearchQuickLinks } from ".";

  const {frequent,currentTyped} = SearchFilterStore.getInstance(fetch);
</script>

{#if !$currentTyped && $frequent && $frequent && $frequent.length>0 }
  <SearchQuickLinks title="Ricerche frequenti">
    {#each $frequent as search}
      <a href="/esplora?q={search}">{search}</a>
    {/each}
  </SearchQuickLinks>
{/if}
