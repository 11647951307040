<script lang="ts">
	import { onMount } from 'svelte';
	import {
		PUBLIC_DATADOG_TRACKING_ENABLED,
		PUBLIC_DATADOG_CLIENT_TOKEN,
		PUBLIC_DATADOG_APPLICATION_ID,
		PUBLIC_DATADOG_ENVIRONMENT,
		PUBLIC_DATADOG_APPLICATION_NAME,
		PUBLIC_AWS_BRANCH,
		PUBLIC_AWS_COMMIT_ID,
		PUBLIC_DATADOG_SESSION_SAMPLE_RATE,
		PUBLIC_DATADOG_SESSION_REPLAY_SAMPLE_RATE
	} from '$env/static/public';
	import { detectRobot } from '$lib/utils/browser-utils';
	import { browser } from '$app/environment';
	import { datadogRum } from '@datadog/browser-rum';

	const scriptId = 'datadog-script';

	const datadogClientToken = PUBLIC_DATADOG_CLIENT_TOKEN;
	const datadogApplicationId = PUBLIC_DATADOG_APPLICATION_ID;
	const datadogEnvironment = PUBLIC_DATADOG_ENVIRONMENT;
	const datadogApplicationName = PUBLIC_DATADOG_APPLICATION_NAME;
	const datadogVersion = `${PUBLIC_AWS_BRANCH}-${PUBLIC_AWS_COMMIT_ID}`;
	const datadogSessionSampleRate = PUBLIC_DATADOG_SESSION_SAMPLE_RATE;
	const datadogSessionReplaySampleRate = PUBLIC_DATADOG_SESSION_REPLAY_SAMPLE_RATE;

	if (browser) {
		// skip for robots
		if (
			window.document.getElementById(scriptId) ||
			(typeof navigator !== 'undefined' &&
				navigator.userAgent &&
				detectRobot(navigator.userAgent, false))
		) {
			console.log('Datadog script skipped: robot detected');
		} else {
			datadogRum.init({
				clientToken: datadogClientToken,
				applicationId: datadogApplicationId,
				site: 'datadoghq.com',
				service: datadogApplicationName,
				env: datadogEnvironment,
				version: datadogVersion,
				sessionSampleRate: datadogSessionSampleRate.match(/[0-9]+/)
					? parseInt(datadogSessionSampleRate)
					: 0,
				sessionReplaySampleRate: datadogSessionReplaySampleRate.match(/[0-9]+/)
					? parseInt(datadogSessionReplaySampleRate)
					: 0,
				trackUserInteractions: true,
				trackResources: true,
				trackLongTasks: true,
				defaultPrivacyLevel: 'mask-user-input',
				enableExperimentalFeatures: ['feature_flags']
			});
		}
	}
</script>
