import { PUBLIC_OKTA_ISSUER, PUBLIC_OKTA_DOMAIN, PUBLIC_OKTA_CLIENT_ID, PUBLIC_OKTA_REDIRECT_URI, PUBLIC_OKTA_LOGIN_URI } from '$env/static/public';
import { useCtaServicesApiClient } from '$lib/api/CtaServicesApiClientFactory';
import { type LogErrorsRequest, type SocialSignupRequest } from '$lib/api/generated/cta-services';
import { OktaSvelteStore, type IUserInfo } from '$lib/utils/auth-store';
import { useCampaignStore } from '$lib/utils/campaign-store';
import { createCtaEvent } from '$lib/utils/cta-flow/helpers';
import { fromCampaignDataTypesToWebsiteSessionDataTypes } from '$lib/utils/cta-services-mapper-utils';
import { useScholarshipWizard } from '$lib/utils/scholarship-wizard';
import { useSessionStore } from '$lib/utils/session-store';
import { get } from 'svelte/store';
import type { HandleClientError } from '@sveltejs/kit';
import { getCookie } from '$lib/utils/browser-utils';
import { PUBLIC_AWS_BRANCH, PUBLIC_AWS_COMMIT_ID } from '$env/static/public'
import { datadogRum } from '@datadog/browser-rum';

// import { DbUtils } from '@24orebs/website-libs/dist/db-utils';
// import { CookiesStoreImpl } from '@24orebs/website-libs/dist/cookie-store/store';
// import { AssetsUtils } from '@24orebs/website-libs/dist/assets-utils';
// import { CustomCourses } from '@24orebs/website-libs/dist/courses/custom-courses';
// import { Search } from '@24orebs/website-libs/dist/search/search';
// import { CourseUtils } from '@24orebs/website-libs/dist/courses/course-utils';
// import { HistoryStoreImpl } from '@24orebs/website-libs/dist/history-store/store';
// import { CountdownStore } from '@24orebs/website-libs/dist/countdown-store';
// import { NewsUtils } from '@24orebs/website-libs/dist/news-utils';
// import { ContactUtils } from '@24orebs/website-libs/dist/contact-utils';
// import {InitiativesUtils} from "@24orebs/website-libs/dist/initiatives-utils";
// import {StructureUtils} from "@24orebs/website-libs/dist/structured-utils";

/*DbUtils.configure({
  coursesDbUrl: env.PUBLIC_COURSES_DB_URL,
  instructorsDbUrl: env.PUBLIC_INSTRUCTORS_DB_URL,
  partnersDbUrl: env.PUBLIC_PARTNERS_DB_URL,
  staticAssetsBaseUrl: env.PUBLIC_STATIC_ASSETS_BASE_URL,
  brochureBaseUrl: env.PUBLIC_BROCHURE_BASE_URL,
  forceToWebp: env.PUBLIC_FORCE_TO_WEBP
});*/

/* Search.configure({
  searchEngineUrl: env.PUBLIC_SEARCH_ENGINE_BASE_URL
}); */

/*
CookiesStoreImpl.configure({
  trafficPercent: env.PUBLIC_ALTERNATE_STATIC_ASSETS_TRAFFIC_PERCENT,
  alternateStaticAssets: env.PUBLIC_ALTERNATE_STATIC_ASSETS_BASE_URL
});

/* AssetsUtils.configure({
  alternateStaticAssetsForceToWebp: env.PUBLIC_ALTERNATE_STATIC_ASSETS_FORCE_TO_WEBP,
  alternateStaticAssetsBaseUrl: env.PUBLIC_ALTERNATE_STATIC_ASSETS_BASE_URL
}); 

CourseUtils.configure({
  baseUrl: env.PUBLIC_BASE_URL,
});

CustomCourses.configure({
  baseUrl: env.PUBLIC_BASE_URL,
  fondoNuoveCompetenze: env.PUBLIC_FONDO_NUOVE_COMPETENZE,
  offertaFormativaACatalogoPerLeAziende: env.PUBLIC_FORMAZIONE_INTERAZIENDALE_A_CATALOGO,
});

HistoryStoreImpl.configure({
  maxAgeHistoryItem: env.PUBLIC_MAX_AGE_HISTORY_ITEM
});

CountdownStore.configure({
  startDate: env.PUBLIC_PROMO_LABEL_START_DATE,
  endDate: env.PUBLIC_PROMO_LABEL_END_DATE
});

NewsUtils.configure({
  contentfulEnvId: env.PUBLIC_CONTENTFUL_ENVIRONMENT,
  newsVisibilities: 'Abilita su Mastery - Neodiplomati',
});

ContactUtils.configure({
  callmeback: env.PUBLIC_CALLMEBACK
});

InitiativesUtils.configure({
  contentfulEnvId: env.PUBLIC_CONTENTFUL_ENVIRONMENT,
  destinationVisibilities: 'Abilita su Mastery - Neodiplomati',
});

StructureUtils.configure({
  baseUrl: env.PUBLIC_BASE_URL
});
*/

/**
 * Configure the Okta Svelte Store with its callbacks
 */
OktaSvelteStore.configure({
  issuer: PUBLIC_OKTA_ISSUER,
  domain: PUBLIC_OKTA_DOMAIN,
  clientId: PUBLIC_OKTA_CLIENT_ID,
  redirectUri: PUBLIC_OKTA_REDIRECT_URI,
  loginUri: PUBLIC_OKTA_LOGIN_URI,
  onAuthUpdatedCallbackFn: async () => {
    const sessionStore = useSessionStore();
    sessionStore.cleanPendingUserInfo();
  },
  onSignupCallbackFn: async (userInfo) => {
    const campaignStore = useCampaignStore();
    const campaignData = get(campaignStore.campaignData);
    const webid = get(campaignStore.webid);

    const signupReq: SocialSignupRequest = {
      name: userInfo.name,
      lastname: userInfo.surname,
      email: userInfo.email,
      phone: userInfo.phone || '',
      subscribe: userInfo.subscribe,
      businessUseConsent: userInfo.businessUseConsent,
      profilingConsent: userInfo.profilingConsent,
      referrer: campaignData?.referrer,
      utmCampaignSource: campaignData?.utm_source,
      utmCampaignMedium: campaignData?.utm_medium,
      utmCampaignName: campaignData?.utm_campaign,
      utmCampaignTerm: '',
      utmCampaignContent: '',
      fbclid: campaignData?.fbclid,
      gclid: campaignData?.gclid,
      gclsrc: campaignData?.gclsrc,
      websiteSessionData: {
        referrer: campaignData?.referrer,
        utmCampaignSource: campaignData?.utm_source,
        utmCampaignMedium: campaignData?.utm_medium,
        utmCampaignName: campaignData?.utm_campaign,
        utmCampaignTerm: '',
        utmCampaignContent: '',
        fbclid: campaignData?.fbclid,
        gclid: campaignData?.gclid,
        gclsrc: campaignData?.gclsrc,
        type: fromCampaignDataTypesToWebsiteSessionDataTypes(campaignData?.type),
        webid: webid ? webid : undefined,
      },
      device: campaignData?.device,
    };

    const client = useCtaServicesApiClient();
    await client.socialSignup(signupReq, createCtaEvent('social_signup'));
  },
  onActivateFn: async (token: string, password: string): Promise<{ username: string, password: string }> => {
    const client = useCtaServicesApiClient();

    const response = await client.activateUser({ token, password });

    if (!response || !response.username || !response.password) {
      throw new Error('Invalid response from the server');
    }

    return { username: response.username, password: response.password };
  },
  onUpdateUserProfileFn: async (newUserInfo): Promise<IUserInfo> => {
    const client = useCtaServicesApiClient();

    const updatedUser = await client.updateUserProfile({
      name: newUserInfo.name,
      lastname: newUserInfo.surname,
      phone: newUserInfo.phone || "",
      subscribe: newUserInfo.subscribe,
      businessUseConsent: newUserInfo.businessUseConsent,
      profilingConsent: newUserInfo.profilingConsent,
      birthday: newUserInfo.birthday || "",
      city: newUserInfo.city || "",
      address: newUserInfo.address || "",
      postalCode: newUserInfo.postalCode || "",
      organization: newUserInfo.organization || "",
      sector: newUserInfo.sector || "",
      function: newUserInfo.function || ""
    });

    return {
      id: newUserInfo.id,
      bsid: newUserInfo.bsid,
      fullname: newUserInfo.fullname,
      name: updatedUser.name || '',
      surname: updatedUser.lastname || '',
      phone: updatedUser.phone || '',
      email: newUserInfo.email || '',
      subscribe: updatedUser.subscribe ? updatedUser.subscribe : false,
      businessUseConsent: updatedUser.businessUseConsent ? updatedUser.businessUseConsent : false,
      profilingConsent: updatedUser.profilingConsent ? updatedUser.profilingConsent : false,
      birthday: updatedUser.birthday || '',
      city: updatedUser.city || '',
      address: updatedUser.address || '',
      postalCode: updatedUser.postalCode || '',
      organization: updatedUser.organization || '',
      sector: updatedUser.sector || '',
      function: updatedUser.function
    };
  },

  onSignoutCallbackFn: async () => {
    const scholarshipWizard = useScholarshipWizard();
    scholarshipWizard.cleanLocalStorage();
  }
});

/**
 * Unexpected client error handler
 * 
 * @param The error object
 * @returns The error message and an error id
 */
export const handleError: HandleClientError = async ({ error, event, status, message }) => {
  const COOKIE_VERSION_NAME = '24orev';

  const errorId = crypto.randomUUID();

  const cookieVersion = getCookie(COOKIE_VERSION_NAME);
  const version = `${PUBLIC_AWS_BRANCH} - ${PUBLIC_AWS_COMMIT_ID}`;

  // send error to an error tracking service
  console.log(event.url, error);

  const msg = {
    request: {
      url: event?.url
    },
    message: message,
    response: {
      status
    },
    errorId
  };

  try {

    const msg = JSON.stringify({
      request: {
        url: event?.url
      },
      message: message,
      response: {
        status
      },
      errorId
    });

    const client = useCtaServicesApiClient();
    const logErrorsRequest: LogErrorsRequest = {
      date: new Date().toISOString(),
      page: event.url.pathname,
      version: 'SvelteKit StoredV:' + cookieVersion + ' - ServerV:' + version,
      message: msg,
      stackTrace: error ? ' Trace: ' + JSON.stringify(error) : ''
    };
    // TODO: remove aftter testing of datadog error logging
    await client.logErrors(logErrorsRequest);
  } catch (e) {
    console.error('Error while logging error', e);
  }

  // send error to datadog
  try {
    datadogRum.addError(error, msg);
  } catch (e) {
    console.error('Error while logging error to datadog', e);
  }

  return {
    message,
    status,
    errorId
  };
}

