import { CourseLightModel } from "@24hbs/utils";
import { CourseType, CourseTypeStore } from "$lib/utils/courses/type-store"
import { type IListingPageDetails, type IListingPageListCourses, type ITopCoursesByListingPage, ListingPage } from "./types";
import { listingPageData } from "./mocks/listing-page";
import { writable, type Writable } from "svelte/store";
import type { IFilters } from "$lib/utils/filters/types";

const listingPageSlugByCourseTypeMap = {
  "corsi-executive": ListingPage.CorsiExecutive,
  "corsi-online": ListingPage.CorsiOnline, 
  "executive-master": ListingPage.ExecutiveMaster,
  "master-full-time": ListingPage.MasterPostLaureaConStage,
  "masterclass": ListingPage.Masterclass,
  "professional-master": ListingPage.ProfessionalMaster,
  "master-part-time": ListingPage.MasterPartTime,
  "mba-emba": ListingPage.MBA,
  "master-universitari-con-cfu": ListingPage.ProfessionalMasterCFU,
  "master-euroconference": ListingPage.CorsiEuroconference,
}

/**
 * Map listing page page with ListingPage
 */
const listingPageSlugMap = {
  ...listingPageSlugByCourseTypeMap,
  // ...listingPageSlugByCourseCategoryMap
};

const listingPageMap = [
  // by course type
  ListingPage.CorsiExecutive,
  ListingPage.CorsiOnline,
  ListingPage.ExecutiveMaster,
  ListingPage.MasterPostLaureaConStage,
  ListingPage.Masterclass,
  ListingPage.ProfessionalMaster,
  ListingPage.MasterPartTime,
  ListingPage.MBA,
  ListingPage.ProfessionalMasterCFU,
  ListingPage.CorsiEuroconference,
];

/**
 * Utility class for listing page
 */
export class ListingPageUtils {
  private static instance: ListingPageUtils | null;

  public listingPageBestSellerCourses: Writable<IListingPageListCourses> = <Writable<IListingPageListCourses>>writable(ListingPageUtils.initListingPageListCourses());
  
  private constructor() {}

  public static getInstance(): ListingPageUtils {
    if (!this.instance) {
      this.instance = new ListingPageUtils();
    }

    return this.instance;
  }

  /**
   * Load preloaded listing page best seller courses
   * @param topCoursesByListingPage top courses by listing page
   */
  public loadPreloadedListingPageBestSellerCourses (topCoursesByListingPage: ITopCoursesByListingPage) {
    this.listingPageBestSellerCourses.update( current => {
      current = topCoursesByListingPage.bestSellerCourses;
      return current;
    });
  }

  /**
   * Initialize top courses by listing page
   * @returns top courses by listing page
   */
  public static initTopCoursesByListingPage(): ITopCoursesByListingPage {
    return <ITopCoursesByListingPage>{
      bestSellerCourses: this.initListingPageListCourses(),
    };
  };

  /**
   * Initialize listing page list courses
   * @returns listing page list courses
   */
  public static initListingPageListCourses(): IListingPageListCourses {
    const listingPageListCourses: IListingPageListCourses = {
      types: [],
    };

    for (const listingPageKey of listingPageMap) {
      listingPageListCourses.types[listingPageKey] = {
        courses: [],
      };
    }

    return listingPageListCourses;
  };

  /**
   * fromJSON method for ITopCoursesByListingPage
   * @param topCoursesObj top courses by listing page object
   * @returns ITopCoursesByListingPage
   */
  public static fromJSON (topCoursesObj: ITopCoursesByListingPage): ITopCoursesByListingPage {
    const topCoursesByListingPage: ITopCoursesByListingPage = {
      bestSellerCourses: this.initListingPageListCourses(),
    };

    for (let i = 0; i < topCoursesObj.bestSellerCourses.types.length; i++) {
      if (topCoursesObj.bestSellerCourses.types[i]) {
        for (let k = 0; k < topCoursesObj.bestSellerCourses.types[i].courses.length; k++) {
          const course = CourseLightModel.fromJSON(topCoursesObj.bestSellerCourses.types[i].courses[k]);
          topCoursesByListingPage.bestSellerCourses.types[i].courses.push(course);
        }
      }
    }

    return topCoursesByListingPage;
  }

  /**
   * Get listing page full name by listing page slug
   * @param listingPageSlug listing page slug
   * @returns listing page full name
   */
  public static getListingPageFullNameByListingPageSlug (listingPageSlug: string): string {
    switch (listingPageSlugMap[listingPageSlug]) {
      case ListingPage.CorsiExecutive:
        return "Corsi Executive";

      case ListingPage.CorsiOnline:
        return "Corsi Online";

      case ListingPage.ExecutiveMaster:
        return "Executive Master";

      case ListingPage.MasterPostLaureaConStage:
        return "Master Post Laurea con Stage garantito";

      case ListingPage.Masterclass:
        return "Masterclass";

      case ListingPage.ProfessionalMaster:
        return "Professional Master";

      case ListingPage.MasterPartTime:
        return "Master Part Time";

      case ListingPage.MBA:
        return "MBA e EMBA";
      
      case ListingPage.ProfessionalMasterCFU:
        return "Professional Master con CFU";
      
      case ListingPage.CorsiEuroconference:
        return "Corsi Euroconference";
    }
  }

  /**
   * Get course types by listing page slug
   * @param listingPageSlug listing page slug
   * @returns course types
   */
  public static getCourseTypesByListingPageSlug(listingPageSlug: string): { courseTypes: CourseType[], filters: IFilters } {
    return listingPageData ? listingPageData[listingPageSlug].in : { courseTypes: [], filters: {} };
  }

  /**
   * Get string array of course types slug by listing page
   * @param listingPage listing page
   * @returns string[] course types slug
   */
  public static getCourseTypesSlugByListingPageSlug(listingPageSlug: string): { courseTypeSlug: string[], filters: IFilters } {
    const { courseTypes, filters} = this.getCourseTypesByListingPageSlug(listingPageSlug);

    return {
      courseTypeSlug: courseTypes.map(courseType => CourseTypeStore.getCourseTypeSlugByCourseType(CourseType[courseType as keyof typeof CourseType])),
      filters: filters,
    };
  }

  /**
   * Get listing page details by listing page slug
   * @param listingPageSlug listing page slug
   * @returns listing page details
   */
  public static getListingPageDetailsByListingPageSlug(listingPageSlug: string): IListingPageDetails {
    // by course type
    switch (listingPageSlugMap[listingPageSlug]) {
      case ListingPage.CorsiExecutive:
        return {
          gtm: {
            pageCategory: "Corsi - Executive",
          },
          title: "Corsi Executive",
          description: "Individua il tema più in linea con le tue necessità di aggiornamento. Perfeziona, integra e sviluppa le tue competenze per svolgere al meglio il tuo ruolo.",
        }

      case ListingPage.CorsiOnline:
        return {
          gtm: {
            pageCategory: "Corsi Online",
          },
          title: "Corsi Online",
          description: "Corsi Online description", // TODO: TO DEFINE
        }

      case ListingPage.ExecutiveMaster:
        return {
          gtm: {
            pageCategory: "Executive Master",
          },
          title: "Executive Master",
          description: "Per sviluppare e consolidare skills tecniche e competenze manageriali.",
        }

      case ListingPage.MasterPostLaureaConStage:
        return {
          gtm: {
            pageCategory: "Master Post Laurea con Stage garantito",
          },
          title: "Master Post Laurea con Stage garantito",
          description: "24ORE Business School propone una vasta scelta di Master Full Time con stage per giovani neolaureati che vogliono approfondire le loro conoscenze e consolidare le proprie basi per entrare nel mondo del lavoro. I Master prevedono la frequenza in aula e lezioni in live streaming di sei mesi e uno stage formativo equivalente presso aziende partner nazionali e multinazionali selezionate.",
        }

      case ListingPage.Masterclass:
        return {
          gtm: {
            pageCategory: "Masterclass",
          },
          title: "Masterclass",
          description: "Con le Masterclass, 24ORE Business School vuole creare un’occasione unica per aziende, liberi professionisti o per chi vuole avvicinarsi alle diverse tematiche proposte, di incontrare i migliori esperti in materia attraverso sessioni formative brevi, smart, flessibili e orientate al business. Inoltre, saranno integrate da presentazioni di casi di successo e best practices da cui trarre spunto.",
        }

      case ListingPage.ProfessionalMaster:
        return {
          gtm: {
            pageCategory: "Professional Master",
          },
          title: "Professional Master",
          description: "Un’occasione unica di crescita professionale. Scegli uno dei percorsi più affini alla tua carriera e arricchisci la tua formazione grazie a un network di professionisti.",
        }

      case ListingPage.MasterPartTime:
        return {
          gtm: {
            pageCategory: "Master Part Time",
          },
          title: "Master Part Time",
          description: "Master Part Time description", // TODO: TO DEFINE
        }

      case ListingPage.MBA:
        return {
          gtm: {
            pageCategory: "MBA e EMBA",
          },
          title: "MBA e EMBA",
          description: "MBA description", // TODO: TO DEFINE
        }
      
      case ListingPage.ProfessionalMasterCFU:
        return {
          gtm: {
            pageCategory: "Professional Master con CFU",
          },
          title: "Professional Master con CFU",
          description: "Professional Master con CFU description", // TODO: TO DEFINE
        }
      
      case ListingPage.CorsiEuroconference:
        return {
          gtm: {
            pageCategory: "Corsi Euroconference",
          },
          title: "Corsi Euroconference",
          description: "Il catalogo prodotti Euroconference ti offre percorsi formativi di alta qualità suddivisi in tre aree tematiche: Legale, Fiscale e Lavoro. La nostra proposta ti permette di accelerare la tua crescita professionale, acquisire competenze specializzate e rimanere sempre aggiornato sulle ultime novità, ottenendo anche crediti formativi",
        }

      default:
        return {
          gtm: {
            pageCategory: "",
          },
          title: "",
          description: "",
        };
    }
  }

  /**
   * Get listing page by listing page slug
   * @param listingPageSlug listing page slug
   * @returns listing page
   */
  public static getListingPageByListingPageSlug(listingPageSlug: string): ListingPage {
    return listingPageSlugMap[listingPageSlug];
  }

  /**
   * Get listing page slug by listing page
   * @param listingPage listing page
   * @returns listing page slug
   */
  public static getListingPageSlugByListingPage(listingPage: ListingPage): string | null {
    // search in listingPageSlugMap by value
    const listingPageSlug = Object.keys(listingPageSlugMap).find(key => listingPageSlugMap[key] === listingPage);
    return listingPageSlug ? listingPageSlug : null;
  }

  /**
   * Get all listing page types slug
   * @returns string[] listing page slug
   */
  public static getAllListingPageSlug(): string[] {
    return Object.keys(listingPageSlugMap);
  }

  /**
   * Get all listing page types slug by course type
   * @returns ListingPage[] listing page
   */
  public static getAllListingPageByCourseType(): string[] {
    return Object.keys(listingPageSlugByCourseTypeMap);
  }

  /**
   * Get all listing page types slug by course category
   * @returns ListingPage[] listing page
   */
  public static getAllListingPageByCourseCategory(): string[] {
    return Object.keys(listingPageSlugByCourseCategoryMap);
  }

  /**
   * Get listing page css class by listing page slug
   * @param listingPageSlug listing page slug
   * @return string
   */
  public static getListingPageCssClassByListingPageSlug (listingPageSlug: string): string {
    switch (listingPageSlugMap[listingPageSlug]) {
      case ListingPage.CorsiExecutive:
        return "corsiExecutiveListingColor";

      case ListingPage.CorsiOnline:
        return "corsiOnlineListingColor";

      case ListingPage.ExecutiveMaster:
        return "executiveMasterListingColor";

      case ListingPage.MasterPostLaureaConStage:
        return "masterPostLaureaListingColor";

      case ListingPage.Masterclass:
        return "masterclassListingColor";

      case ListingPage.ProfessionalMaster:
        return "professionalMasterListingColor";

      case ListingPage.MasterPartTime:
        return "masterPartTimeListingColor";

      case ListingPage.MBA:
        return "mbaListingColor";

      case ListingPage.ProfessionalMasterCFU:
        return "professionalMasterCFUListingColor";

      case ListingPage.CorsiEuroconference:
        return "corsiEuroconferenceListingColor";

      default:
        return "defaultListingColor";
    }
  }

  public static getListingPageByFieldName (remoteSettingsFieldName: string): number {
    switch (remoteSettingsFieldName) {
      case "listingBestSellerCorsiExecutive":
        return ListingPage.CorsiExecutive;

      case "listingBestSellerCorsiOnline":
        return ListingPage.CorsiOnline;

      case "listingBestSellerExecutiveMaster":
        return ListingPage.ExecutiveMaster;

      case "listingBestSellerMasterPostLaureaConStage":
        return ListingPage.MasterPostLaureaConStage;

      case "listingBestSellerMasterclass":
        return ListingPage.Masterclass;

      case "listingBestSellerProfessionalMaster":
        return ListingPage.ProfessionalMaster;

      case "listingBestSellerMasterPartTime":
        return ListingPage.MasterPartTime;

      case "listingBestSellerMBAEMBA":
        return ListingPage.MBA;

      case "listingBestSellerMasterUniversitariConCfu":
        return ListingPage.ProfessionalMasterCFU;

      case "listingBestSellerMasterEuroconference":
        return ListingPage.CorsiEuroconference;

      default:
        return -1;
    }
  }

}