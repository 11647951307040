import { get } from 'svelte/store';

export const containsAtLeastOneUppercaseLetterValidator = () => {
  return (value: string) => ({ valid: value && value.match(/[A-Z]+/), name: 'containsAtLeastOneUppercaseLetter' });
};

export const containsAtLeastOneNumberValidator = () => {
  return (value: string) => ({ valid: value && value.match(/[0-9]+/), name: 'containsAtLeastOneNumber' });
};

export function arrayRequired() {
  return (val) => {
      let valid = true;
      if (val === undefined || val === null)
          valid = false;
      if (Array.isArray(val)) {
          valid = val.length >= 1;
      }
      return { valid, name: 'arrayRequired' };
  };
}

// Check if the value is true
export const isTrueValidation = () => {
  return (value: any) => ({ valid: value === true, name : 'isTrue' })
}

//valid if holderHasPIVA (passed filed) is false || if it is true and the value is not empty
//holderHasPIVA is a boolean (true: giuridic, false: physical)
export const giuridicValidation = (field) => {
  return (value) => {
    return { valid: get(field).value === false || (get(field).value === true && value), name: 'giuridic_not_valid' };
  };
}

//valid if holderHasPIVA (passed filed) is false || if it is true and the value is a valid piva
//holderHasPIVA is a boolean (true: giuridic, false: physical)
export const VATNumberValidation = (field) => {
  return (value: string) => {
    return { valid: get(field).value === false || (get(field).value === true && !!value.match(/^(IT)?[0-9]{11}$/)), name: 'vat_number_not_valid' };
  }
}

//valid if holderHasPIVA (passed filed) is true || if it is false and the value is not empty
//holderHasPIVA is a boolean (true: giuridic, false: physical)
export const physicalValidation = (field) => {
  return (value) => {
    return { valid: get(field).value === true || (get(field).value === false && value), name: 'physical_not_valid' };
  };
}

//valid if country (passed filed) is != IT || if it is IT and the value is not empty
export const provinceValidation = (field) => {
  return (value) => {
      return { valid: get(field).value !== "IT" || (get(field).value === "IT" && value), name: 'province_not_valid' };
  };
}

//validation error
export const getErrorByCode = (code: string) => {
  return {
    'cart_not_found': 'Carrello non trovato',
    'empty_cart': 'Carrello vuoto',
    'missing_credentials': 'Credenziali mancanti',
    'name_required': 'Nome obbligatorio',
    'surname_required': 'Cognome obbligatorio',
    'tax_id_required': 'Codice fiscale obbligatorio',
    'invalid_tax_id': 'Codice fiscale non valido',
    'vat_number_required': 'Partita IVA obbligatoria',
    'invalid_vat_number': 'Partita IVA non valida',
    'invalid_province': 'Provincia non valida',
    'product_not_found': 'Prodotto non trovato',
    'price_not_found': 'Prezzo non trovato',
    'invalid_promo_code': 'Codice promozionale non valido',
  }[code] || 'Riprova più tardi';
}