/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type GetCheckoutSessionResponse = {
    coupon?: string;
    customerEmail: string;
    tax?: number;
    currency?: GetCheckoutSessionResponse.currency;
    value?: number;
    items?: Array<{
        quantity: number;
        coupon?: string;
        price: number;
        name: string;
        description: string;
        tax: number;
        currency: 'EUR';
        id: string;
    }>;
    paymentStatus?: GetCheckoutSessionResponse.paymentStatus;
    transactionId?: string;
    status: GetCheckoutSessionResponse.status;
    paymentType?: GetCheckoutSessionResponse.paymentType;
};
export namespace GetCheckoutSessionResponse {
    export enum currency {
        EUR = 'EUR',
    }
    export enum paymentStatus {
        PAID = 'paid',
        UNPAID = 'unpaid',
        NO_PAYMENT_REQUIRED = 'no_payment_required',
    }
    export enum status {
        OPEN = 'open',
        COMPLETE = 'complete',
        EXPIRED = 'expired',
    }
    export enum paymentType {
        CARD = 'card',
        PAYPAL = 'paypal',
        SEPA_DEBIT = 'sepa_debit',
        KLARNA = 'klarna',
    }
}

