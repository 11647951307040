import { goto } from '$app/navigation';
import { useOktaStore, type IAuthStore } from '$lib/utils/auth-store';
import { useCampaignStore } from '$lib/utils/campaign-store';
import type { CampaignStore } from '$lib/utils/campaign-store/store';
import { useHistoryStore } from '$lib/utils/history-store';
import { useScholarshipWizard } from '$lib/utils/scholarship-wizard';
import { type SessionStore, useSessionStore } from '$lib/utils/session-store';
import { get } from 'svelte/store';
import { buildBrochureConfirmationPath, buildShopifyLink, createCtaEvent, getUserAndWebsiteData } from './helpers';
import type { BookInitiativeCTAData, BuyCTAData, CTA, CTAFlowData, DownloadBrochureCTAData, RequestInfoCTAData, RequestIntititiveInfoCTAData, SendApplicationFormCTAData } from './types';
import { browser } from '$app/environment';
import type { BookEventRequest, PostDownloadBrochureRequest } from '$lib/api/generated/cta-services';
import { PUBLIC_PAYMENT_GATEWAY } from '$env/static/public';
import { useCtaServicesApiClient } from "$lib/api/CtaServicesApiClientFactory";
import { getCookie } from '$lib/utils/browser-utils';

export class CTAFlow {
  private static _instance: CTAFlow;

  private _campaignStore: CampaignStore;
  private _authStore: IAuthStore;
  private _sessionStore: SessionStore;

  private _errorCallback: () => void;

  private constructor() {
    this._campaignStore = useCampaignStore();
    this._authStore = useOktaStore();
    this._sessionStore = useSessionStore();
    this._errorCallback = () => { };
  }

  public static getInstance() {
    if (!CTAFlow._instance) {
      CTAFlow._instance = new CTAFlow();
    }
    return CTAFlow._instance;
  }

  public get originPath(): string | null {
    return this._originPath;
  }

  public get activeCTA(): string | null {
    return this._activeCTA;
  }

  public get actionInPlace(): boolean {
    return !!this._activeCTA && !!this._originPath && !!this._data;
  }

  public get bookingUpdateCompleted(): boolean {
    return this._boookingUpdateComplete;
  }

  public async completeBookingUpdate(uid?: string, request?: PostDownloadBrochureRequest) {
    if (uid && request) {
      const client = useCtaServicesApiClient();
      const result = await client.updateDownloadBrochure(uid, request);
      this._sessionStore.setDownloadBrochureResult(result);
    }

    this._boookingUpdateComplete = true;
  }

  public setErrorCallback(callback: () => void) {
    this._errorCallback = callback;
  }

  public resetCTAFlow() {
    this._activeCTA = null;
    this._originPath = '';
    this._data = null;
    this._boookingUpdateComplete = false;
  }

  public async startCTAFlow(originPath: string, cta: CTA, data: CTAFlowData | {}, customPath?: string, customConfirmationPath?: string) {
    this._originPath = originPath;
    this._activeCTA = cta;
    this._data = data;
    this._customPath = customPath ? customPath : null;
    this._customConfirmationPath = customConfirmationPath ? customConfirmationPath : '';

    if (this.shouldGoToLoginForm(customPath)) {
      return await goto(`/login?cta=${this._activeCTA}`);
    } else if (this.shouldGoToCompletionForm()) {
      return await goto(`/registrati/completion?cta=${this._activeCTA}`);
    } else if (this.shouldGoToRegistrationForm()) {
      return await goto(`/registrati?cta=${this._activeCTA}`);
    }

    // Else handle each cta
    return await this.handleCTA(false);
  }

  public async continueCTAFlow() {
    if (!this._activeCTA) return;
    return await this.handleCTA(true);
  }

  /*********************************************************************/
  /*                         PRIVATE METHODS                           */
  /*********************************************************************/

  /**
   * Handle the different CTAs.
   * Function reads the current active CTA and proceeds to handle it
   * @param continued True if the handling was continued after login/registration
   * @returns A promise that resolves when handling was succesfull
   */
  private async handleCTA(continued: boolean = false) {
    switch (this._activeCTA) {
      case 'downloadBrochure':
        return await this.downloadBrochure(continued);
      case 'buy':
        return await this.buy();
      case 'bookInitiative':
        return await this.bookInitiative(continued);
      case 'sendApplicationForm':
        const applicationFormData = this._data as SendApplicationFormCTAData;
        if (this._customPath) {
          return await goto(this._customPath, {
            replaceState: continued,
          });
        }
        return await goto(`${applicationFormData.coursePath}/${applicationFormData.sessionId}/application_form`, {
          replaceState: continued,
        });
      case 'requestInfo':
        const requestInfoCTAData = this._data as RequestInfoCTAData;
        let url = `${requestInfoCTAData.coursePath}/${requestInfoCTAData.sessionId}/request_information_form`;
        if (this._customPath) {
          url = this._customPath;
        }
        return await goto(url, {
          replaceState: continued,
        });
      case 'requestInitiativeInfo':
        const requestinitiativeInfoCTAData = this._data as RequestIntititiveInfoCTAData;
        return await goto(`${requestinitiativeInfoCTAData.initiativePath}/richiedi_informazioni`, {
          replaceState: continued,
        });
      case 'scholarship': {
        // Temp way to start the wizard. TODO: Find a better way
        const wizard = useScholarshipWizard();
        wizard.start();
        return await goto(this._originPath ? this._originPath : '/', { replaceState: continued });
      }
    }
  }

  /**
   * Determines if the user should proceed to registration.
   * If he is not logged in and start the bookInititiative, downloadBrochure
   * and buy  CTAs then he should first register/login
   * @returns True if user should go to the start of registration
   */
  private shouldGoToRegistrationForm() {
    const isLoggedIn = get(this._authStore.isLoggedIn);
    return !isLoggedIn && this._activeCTA && ['bookInitiative', 'downloadBrochure', 'buy', 'scholarship'].includes(this._activeCTA);
  }

  public shouldGoToLoginForm(customPath: string | null = null) {
    const isLoggedIn = get(this._authStore.isLoggedIn);
    const loginOptions = get(this._sessionStore.loginOptions);

    if (!isLoggedIn && this._activeCTA === 'requestInfo' && (customPath && customPath.indexOf('b2b-4-weeks-4-inclusion') > -1)) return true;

    return !isLoggedIn && this._activeCTA && ['bookInitiative', 'downloadBrochure', 'buy', 'scholarship'].includes(this._activeCTA) && loginOptions && loginOptions.email && loginOptions.channel;
  }

  /**
   * Determines if the user should proceed to complete
   * his registration. If he is is logged in and has not set
   * name, email or phone then he should first complete his profile or if
   * he has completed cta and he has not subscribed
   * @param ctaCompleted For when you want to check for privacy only after cta compleition
   * @returns True if user should go to register completion step
   */
  public shouldGoToCompletionForm(ctaCompleted: boolean = false) {
    const isLoggedIn = get(this._authStore.isLoggedIn);
    const userInfo = get(this._authStore.userInfo);
    const pendingUserInfo = get(this._sessionStore.pendingUserInfo);

    if (ctaCompleted) {
      // return isLoggedIn && !userInfo?.subscribe && !pendingUserInfo?.subscribe;
      return isLoggedIn && !userInfo?.phone && !pendingUserInfo?.phone;
    }
    return isLoggedIn && ((!userInfo?.name && !pendingUserInfo?.name) || (!userInfo?.phone && !pendingUserInfo?.phone) || (!userInfo?.email && !pendingUserInfo?.email));
  }

  private async downloadBrochure(continued: boolean = false): Promise<void> {
    const request = {
      ...getUserAndWebsiteData(),
      ...(this._data as DownloadBrochureCTAData),
      endDate: new Date((this._data as DownloadBrochureCTAData).endDate).toISOString(),
      startDate: new Date((this._data as DownloadBrochureCTAData).startDate).toISOString(),
    } as PostDownloadBrochureRequest;

    // Fill data object with user info and campaign data
    try {
      const client = useCtaServicesApiClient();
      const result = await client.submitDownloadBrochure(request, createCtaEvent('brochure_download'));

      if (!request.coursePath) {
        throw new Error('Course path is missing');
      }

      if (!request.sessionId) {
        throw new Error('Session id is missing');
      }

      let confirmationPath = '';
      if (request.brochureBooking) {
        confirmationPath = buildBrochureConfirmationPath(request.coursePath, 'booking', request.sessionId, request.productCode);
      } else if (request.brochureAvailable) {
        confirmationPath = buildBrochureConfirmationPath(request.coursePath, 'download', request.sessionId, request.productCode);
        this._sessionStore.setDownloadBrochureResult(result);
      } else {
        confirmationPath = `${request.courseUrl}`;
      }

      const historyStore = useHistoryStore();

      historyStore.addHistoryEntry(request.brochureBooking ? 'booking_brochure' : 'download_brochure', {
        rank: (this._data as DownloadBrochureCTAData).rank,
        category: (this._data as DownloadBrochureCTAData).category,
        endDate: (this._data as DownloadBrochureCTAData).endDate,
        productCode: (this._data as DownloadBrochureCTAData).productCode,
        startDate: (this._data as DownloadBrochureCTAData).startDate,
      });

      if (this.shouldGoToCompletionForm(true)) {
        goto(`/registrati/completion?callback=${confirmationPath}`, { replaceState: continued });
      } else {
        goto(confirmationPath, { replaceState: continued });
      }

    } catch (error) {
      goto(this._originPath ? this._originPath : '/', { replaceState: true, noScroll: true }).then(() => {
        if (this._errorCallback) this._errorCallback();
        this.resetCTAFlow();
      });
    }

  }

  private buy(continued: boolean = false): Promise<void> {
    const buyCTAData = this._data as BuyCTAData;
    const userData = getUserAndWebsiteData();

    return new Promise((resolve, reject) => {
      if (buyCTAData.upselling) {
        // Go to packages/options selection page
        if (browser) {
          localStorage.setItem(
            'upselling-checkout',
            JSON.stringify({
              session: null,
              package: null,
              options: [],
            })
          );
        }
        if (buyCTAData.upsellingHasPackages) {
          goto(`${buyCTAData.coursePath}/${buyCTAData.sessionId}/buy/packages`).then(() => resolve());
        } else {
          goto(`${buyCTAData.coursePath}/${buyCTAData.sessionId}/buy/options`).then(() => resolve());
        }
      } else {
        // Go to stripe checkout

        let payment_gateway = PUBLIC_PAYMENT_GATEWAY;
        if (typeof window != 'undefined') {
          payment_gateway = getCookie('payment_gateway') || payment_gateway;
        }
        if (payment_gateway === 'stripe') {
          goto(`${buyCTAData.coursePath}/${buyCTAData.sessionId}/checkout`).then(() => resolve());
        } 
        else if (payment_gateway === 'shopify') {
          // Go directly to shopify link
          buildShopifyLink(userData.id, userData.email, userData.name, userData.surname, userData.phone, buyCTAData.productCode)
            .then((shopifyLink) => {
              this.resetCTAFlow();
              if (continued) {
                window.location.replace(shopifyLink);
              } else {
                window.location.href = shopifyLink;
              }

              resolve();
            })
            .catch(() => {
              const msg = 'An error occurred while fetching the shopify multipass permalink';
              console.error(msg);
              reject(msg);
            });
        } else {
          console.log("Payment gateway not found");
          reject("Payment gateway not found");
        }
      }

      const historyStore = useHistoryStore();
      historyStore.addHistoryEntry('buy', {
        rank: buyCTAData.rank,
        category: buyCTAData.category,
        endDate: buyCTAData.endDate,
        productCode: buyCTAData.productCode,
        startDate: buyCTAData.startDate,
      });
    });
  }

  private async bookInitiative(continued: boolean = false): Promise<void> {
    const bookInitiativeCTAData = this._data as BookInitiativeCTAData;

    if (!bookInitiativeCTAData.bookingConfig.useCta || !bookInitiativeCTAData.bookingConfig.courseId || !bookInitiativeCTAData.bookingConfig.sessionId) {
      // open Docebo in a separate tab if we had only the booking URL
      return goto(this._originPath || '/', { replaceState: continued }).then(() => {
        this.resetCTAFlow();
        if (window !== null && bookInitiativeCTAData.bookingConfig.url) {
          const wp = window.open(bookInitiativeCTAData.bookingConfig.url, '_blank')
          if (wp) {
            wp.focus();
          }
        }
      });
    }

    // Construct book event request to call the service
    const request = {
      initiativePath: bookInitiativeCTAData.initiativePath,
      doceboCourseId: bookInitiativeCTAData.bookingConfig.courseId,
      doceboSessionId: bookInitiativeCTAData.bookingConfig.sessionId,
      title: bookInitiativeCTAData.bookingConfig.title,
      type: bookInitiativeCTAData.bookingConfig.type,
      date: new Date(bookInitiativeCTAData.bookingConfig.date).toISOString(),
      ...getUserAndWebsiteData(),
    } as BookEventRequest;

    const ctaService = useCtaServicesApiClient();

    try {
      const result = await ctaService.bookEvent(request, createCtaEvent('book_event'))
      const confirmationPath = `${bookInitiativeCTAData.initiativePath}/booking_confirm`;

      if (this.shouldGoToCompletionForm(true)) {
        goto(`/registrati/completion?callback=${confirmationPath}`, { replaceState: continued });
      } else {
        goto(confirmationPath, { replaceState: continued, });
      }
    } catch (error) {
      console.log('Error while booking initiative', error);
      goto(this._originPath || '/', { replaceState: true, noScroll: true }).then(() => {
        this.resetCTAFlow();
        if (this._errorCallback) this._errorCallback();
      });
    }

    /*
    try {
      // call the book event CTA service to register to the event
      const client = useCtaServicesApiClient();
      const result = await client.bookEvent(request, createCtaEvent('book_event'));

      const confirmationPath = `${bookInitiativeCTAData.initiativePath}/booking_confirm`;

      if (this.shouldGoToCompletionForm(true)) {
        goto(`/registrati/completion?callback=${confirmationPath}`, { replaceState: continued });
      } else {
        goto(confirmationPath, {
          replaceState: continued,
        });
      }
    } catch (error) {
      console.log('Error while booking initiative', error);
      goto(this._originPath || '/', { replaceState: true, noScroll: true }).then(() => {
        this.resetCTAFlow();
        if (this._errorCallback) this._errorCallback();
      });
    }
    */

  }

  /*********************** SETTERS AND GETTERS ****************************/
  /*        Used to store in the sessionStorage imediately                */
  /************************************************************************/

  private set _boookingUpdateComplete(show: boolean) {
    sessionStorage.setItem('ctaBookingUpdateModal', JSON.stringify(show));
  }

  private set _activeCTA(cta: CTA | null) {
    if (browser) {
      sessionStorage.setItem('cta', JSON.stringify(cta));
    }
  }

  private set _originPath(originPath: string) {
    if (browser) {
      sessionStorage.setItem('ctaOriginPath', originPath);
    }
  }

  private set _data(data: any) {
    if (browser) {
      sessionStorage.setItem('ctaData', JSON.stringify(data));
    }
  }

  private set _customPath(path: string | null) {
    if (browser) {
      sessionStorage.removeItem('ctaCustomPath');
      if (path) {
        sessionStorage.setItem('ctaCustomPath', path);
      }
    }
  }

  private set _customConfirmationPath(confirmationPath: string) {
    if (browser) {
      sessionStorage.setItem('ctaCustomConfirmationPath', confirmationPath);
    }
  }

  private get _boookingUpdateComplete(): boolean {
    if (browser) {
      const showModalStr = sessionStorage.getItem('ctaBookingUpdateModal');
      if (showModalStr) {
        return JSON.parse(showModalStr);
      }
    }
    return false;
  }

  private get _activeCTA(): CTA | null {
    if (browser) {
      const ctaStr = sessionStorage.getItem('cta');
      if (ctaStr) {
        return JSON.parse(ctaStr);
      }
    }
    return null;
  }

  private get _originPath(): string | null {
    if (browser) {
      return sessionStorage.getItem('ctaOriginPath');
    }
    return null;
  }

  private get _customPath(): string | null {
    if (browser) {
      return sessionStorage.getItem('ctaCustomPath');
    }
    return null;
  }

  private get _customConfirmationPath(): string | null {
    if (browser) {
      return sessionStorage.getItem('ctaCustomConfirmationPath');
    }
    return null;
  }

  private get _data(): CTAFlowData | null {
    if (browser) {
      const dataStr = sessionStorage.getItem('ctaData');
      if (dataStr) {
        return JSON.parse(dataStr);
      }
    }
    return null;
  }
}
